/* eslint-disable no-undef */
import { Button, Flex, Paragraph } from 'theme-ui';
import ReactSelect from 'react-select';
import axios from 'axios';
import Divider from '../divider';
import { toast, ToastContainer } from 'react-toastify';

const saveRelations = async (state, updateState) => {
    try {
        updateState({
            ...state,
            loading: false,
            error: null,
            confirmMessage: null,
        });

        await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView.id}/relations`,
            data: {
                relations: [
                    ...(state?.defaultPartners?.map((x) => ({ id: x.value, type: 'partner' })) || []),
                    ...(state?.defaultReviewers?.map((x) => ({ id: x.value, type: 'reviewer' })) || []),
                    ...(state?.defaultAssignees?.map((x) => ({ id: x.value, type: 'assigned_to' })) || []),
                ],
            },
        });

        updateState({
            ...state,
            loading: false,
        });
        toast('Successfully saved!', { type: 'success' });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
        });
        toast('There has been an error saving these defaults, please try again or contact support', {
            type: 'error',
        });
    }
};

const ClientRelations = ({ updateState, state }) => {
    return (
        <Flex sx={{ flexDirection: 'column', mt: 20, ml: 20, mb: 30 }}>
            <Paragraph sx={{ fontWeight: 600, fontSize: 20, color: 'text' }}>Task Defaults</Paragraph>

            <Divider width="1300px" />

            <Paragraph sx={{ mt: 0, fontSize: 14, color: 'text' }}>Default Partners</Paragraph>

            <Flex sx={{ width: 500, flexDirection: 'column' }}>
                <ReactSelect
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                            width: '500px',
                            minHeight: '40px',
                            marginTop: '10px',
                            borderRadius: 10,
                            marginBottom: '10px',
                            border: '1px solid #a3a69b',
                            fontSize: '14px',
                        }),
                    }}
                    onChange={(value) => updateState({ ...state, defaultPartners: value })}
                    placeholder={'Select the default partners to be assigned'}
                    value={state?.defaultPartners}
                    isMulti
                    options={
                        state.users?.map((x) => ({
                            value: x.raw_id,
                            label: x.email_address,
                        })) || []
                    }
                />
            </Flex>

            <Paragraph sx={{ mt: 20, fontSize: 14, color: 'text' }}>Default Preparers</Paragraph>

            <Flex sx={{ width: 500, flexDirection: 'column' }}>
                <ReactSelect
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                            width: '500px',
                            minHeight: '40px',
                            marginTop: '10px',
                            borderRadius: 10,
                            marginBottom: '10px',
                            border: '1px solid #a3a69b',
                            fontSize: '14px',
                        }),
                    }}
                    onChange={(value) => updateState({ ...state, defaultAssignees: value })}
                    placeholder={'Select the default preparers to be assigned'}
                    value={state?.defaultAssignees}
                    isMulti
                    options={
                        state.users?.map((x) => ({
                            value: x.raw_id,
                            label: x.email_address,
                        })) || []
                    }
                />
            </Flex>

            <Paragraph sx={{ mt: 20, fontSize: 14, color: 'text' }}>Default Reviewers</Paragraph>

            <Flex sx={{ width: 500, flexDirection: 'column' }}>
                <ReactSelect
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                            width: '500px',
                            minHeight: '40px',
                            marginTop: '10px',
                            borderRadius: 10,
                            marginBottom: '10px',
                            border: '1px solid #a3a69b',
                            fontSize: '14px',
                        }),
                    }}
                    onChange={(value) => updateState({ ...state, defaultReviewers: value })}
                    placeholder={'Select the default reviewers to be assigned'}
                    value={state?.defaultReviewers}
                    isMulti
                    options={
                        state.users?.map((x) => ({
                            value: x.raw_id,
                            label: x.email_address,
                        })) || []
                    }
                />
            </Flex>

            <Flex
                sx={{
                    position: 'fixed',
                    height: 80,
                    background: 'white',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    alignItems: 'center',
                    borderTop: '2px solid #EFEFEF',
                    width: '100%',
                    zIndex: 998,
                }}
            >
                <Button onClick={() => saveRelations(state, updateState)} sx={{ height: 40, ml: 20, width: 150 }}>
                    <i
                        style={{
                            marginRight: '7px',
                        }}
                        className={`fas fa-save`}
                    />
                    Save
                </Button>
            </Flex>
        </Flex>
    );
};

export default ClientRelations;
