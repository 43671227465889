/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { Auth } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import ReactSelect from 'react-select';
import { Button, Flex, Label, Paragraph, Image } from 'theme-ui';
import InboundEmailsComponent from '../clients/inbound-emails';
import Divider from '../divider';
import Spinner from '../spinner';
import { loadClientsAsync } from '../tasks/filters';
import { actions as menuActions } from '../../store/reducers/menu';
import { actions as userActions } from '../../store/reducers/users';
import { downloadFile } from '../../pages/inbox';
import { toast } from 'react-toastify';
import axios from 'axios';

const updateEmail = async (id, data, state, updateState) => {
    try {
        updateState({ ...state, loading: true });
        await axios({
            method: 'put',
            url: `${process.env.REACT_APP_AQRU_AI_API}/inbound-emails/${id}`,
            data,
        });
        updateState({ ...state, loading: false });
    } catch (error) {
        toast.error('Error updating email');
    }
};

const statusOptions = [
    { value: 'unassigned', label: 'Unassigned' },
    { value: 'assigned', label: 'Assigned to a client' },
    { value: 'archived', label: 'Archived' },
];

const Post = ({ organisation, client }) => {
    const [state, updateState] = useState({
        organisation,
        view: 'list',
        email: null,
        statuses: ['assigned', 'archived'],
    });

    const stateRef = useRef();
    stateRef.current = state;

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    return (
        <>
            {state.loading && <Spinner />}

            <Flex sx={{}}>
                <Flex
                    sx={{
                        flexDirection: 'column',
                    }}
                >
                    <Flex
                        sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            mb: '30px',
                        }}
                    >
                        <Flex
                            sx={{
                                flexDirection: 'column',
                                width: 1300,
                            }}
                        >
                            <Flex sx={{ mt: 20, alignItems: 'center' }}>
                                <Paragraph sx={{ fontSize: 18, fontWeight: 600 }}>
                                    {state.view === 'list' ? `Emails received` : 'Email details'}
                                </Paragraph>
                                {state.view === 'list' ? (
                                    <Button
                                        sx={{ ml: 20 }}
                                        onClick={() => {
                                            navigator.clipboard.writeText(organisation?.post_inbox_address || '');
                                            toast.success('Copied to clipboard');
                                        }}
                                    >
                                        <i style={{ marginRight: '7px' }} className="fa fa-copy" />
                                        Copy Inbox Address
                                    </Button>
                                ) : null}
                            </Flex>
                            <Divider width="600px" />
                            {state.view === 'list' && (
                                <Flex sx={{ flexDirection: 'column' }}>
                                    <InboundEmailsComponent client={client} state={state} updateState={updateState} />
                                </Flex>
                            )}
                            {state.view === 'view' && state.email && (
                                <Flex sx={{ flexDirection: 'column' }}>
                                    <Flex sx={{ flexDirection: 'column', mt: 0, mb: 10 }}>
                                        <Label sx={{ mt: 0 }} id="client-label">
                                            Assign to a client
                                        </Label>
                                        <Flex>
                                            <AsyncSelect
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                        width: '400px',
                                                        minHeight: '40px',
                                                        marginTop: '10px',
                                                        borderRadius: 10,
                                                        marginBottom: '10px',
                                                        border: '1px solid #a3a69b',
                                                        fontSize: '14px',
                                                    }),
                                                }}
                                                aria-labelledby="client-label"
                                                onChange={async (c) => {
                                                    updateState({
                                                        ...state,
                                                        newEmailClient: c,
                                                        email: {
                                                            ...state.email,
                                                            status: c ? 'assigned' : 'unassigned',
                                                        },
                                                    });
                                                }}
                                                placeholder="Select client"
                                                value={state.newEmailClient}
                                                loadOptions={loadClientsAsync(state, updateState)}
                                            />
                                            {state.newEmailClient ? (
                                                <a
                                                    href={`/clients?id=${state.newEmailClient.uuid}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Button sx={{ ml: 10, mt: 10 }}>
                                                        <i
                                                            style={{
                                                                marginRight: '7px',
                                                            }}
                                                            className={`fas fa-external-link-alt`}
                                                        />
                                                        Open
                                                    </Button>
                                                </a>
                                            ) : null}
                                        </Flex>
                                    </Flex>
                                    <Flex sx={{ flexDirection: 'column', mt: 0, mb: 20 }}>
                                        <Label sx={{ mt: 0 }} id="client-label">
                                            Status
                                        </Label>
                                        <Flex>
                                            <ReactSelect
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                        width: '400px',
                                                        minHeight: '40px',
                                                        marginTop: '10px',
                                                        borderRadius: 10,
                                                        marginBottom: '10px',
                                                        border: '1px solid #a3a69b',
                                                        fontSize: '14px',
                                                    }),
                                                }}
                                                aria-labelledby="client-label"
                                                onChange={async (c) => {
                                                    updateState({
                                                        ...state,
                                                        email: {
                                                            ...state.email,
                                                            status: c.value,
                                                        },
                                                        newEmailClient:
                                                            c.value === 'unassigned' ? null : state.newEmailClient,
                                                    });
                                                }}
                                                options={statusOptions.filter((x) =>
                                                    state.newEmailClient ? true : x.value !== 'assigned'
                                                )}
                                                placeholder="Set status"
                                                value={statusOptions?.find((x) => x.value === state.email.status)}
                                            />
                                        </Flex>
                                    </Flex>
                                    <Paragraph>From: {state.email.from}</Paragraph>
                                    <Paragraph>Date: {new Date(state.email.createdAt).toLocaleDateString()}</Paragraph>
                                    <Divider width="600px" />
                                    <Paragraph sx={{ mb: 10 }}>Subject: {state.email.subject}</Paragraph>
                                    <Paragraph sx={{ mb: 20 }}>Body: {state.email.body}</Paragraph>
                                    {state.email.attachments.map((attachment, index) => {
                                        const byteCharacters = atob(attachment.content);
                                        const byteNumbers = new Array(byteCharacters.length);
                                        for (let i = 0; i < byteCharacters.length; i++) {
                                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                                        }
                                        const byteArray = new Uint8Array(byteNumbers);

                                        // Create a Blob from the binary data
                                        const blob = new Blob([byteArray], { type: attachment.content_type });

                                        // Generate an Object URL from the Blob
                                        const url = URL.createObjectURL(blob);

                                        let iFrameHeight = '700px';

                                        if (['image/png', 'image/jpeg'].includes(attachment.content_type)) {
                                            iFrameHeight = '200px';
                                        }

                                        return (
                                            <Flex sx={{ mb: 20, flexDirection: 'column' }} key={index}>
                                                <Flex
                                                    sx={{
                                                        mb: 20,
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Paragraph sx={{ width: 200 }}>{attachment.file_name}</Paragraph>
                                                    <Button
                                                        sx={{ width: 120 }}
                                                        onClick={() =>
                                                            downloadFile(
                                                                attachment.content,
                                                                attachment.file_name,
                                                                attachment.content_type
                                                            )
                                                        }
                                                    >
                                                        <i
                                                            className="fa fa-download"
                                                            style={{
                                                                marginRight: '7px',
                                                            }}
                                                        />
                                                        Download {attachment.filename}
                                                    </Button>
                                                </Flex>
                                                {['image/png', 'image/jpeg'].includes(attachment.content_type) ? (
                                                    <Image src={url} sx={{ width: 500 }} />
                                                ) : (
                                                    <iframe
                                                        style={{
                                                            border: '1px solid #EFEFEF',
                                                            height: iFrameHeight,
                                                        }}
                                                        src={url}
                                                    />
                                                )}
                                            </Flex>
                                        );
                                    })}

                                    <Flex
                                        sx={{
                                            position: 'fixed',
                                            height: 80,
                                            background: 'white',
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            alignItems: 'center',
                                            borderTop: '2px solid #EFEFEF',
                                            width: '100%',
                                            zIndex: 998,
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                updateState({
                                                    ...state,
                                                    view: 'list',
                                                    email: null,
                                                    newEmailClient: null,
                                                });
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}
                                            variant="light"
                                            sx={{ ml: 20, width: 100 }}
                                        >
                                            <i
                                                className="fa fa-chevron-left"
                                                style={{
                                                    marginRight: '7px',
                                                }}
                                            />
                                            Back
                                        </Button>

                                        <Button
                                            onClick={async () => {
                                                await updateEmail(
                                                    state.email.id,
                                                    {
                                                        status: state.email.status,
                                                        client_id: state?.newEmailClient?.value,
                                                    },
                                                    state,
                                                    updateState
                                                );
                                            }}
                                            sx={{ ml: 10, width: 100 }}
                                        >
                                            <i
                                                className="fa fa-save"
                                                style={{
                                                    marginRight: '7px',
                                                }}
                                            />
                                            Save
                                        </Button>
                                    </Flex>
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

export default Post;
