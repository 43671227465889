/** @jsxImportSource theme-ui */
import { Flex } from 'theme-ui';

const BLSpinner = ({ customSx = {} }) => (
    <Flex
        className="bl_spinner"
        data-testid="bl_spinner"
        sx={{
            position: 'fixed', // Use fixed positioning to center relative to the viewport
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 100000000000000000,
            ...customSx,
        }}
        onClick={(e) => e.stopPropagation()}
    >
        <div style={{ width: 100, height: 100 }} className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </Flex>
);

export default BLSpinner;
