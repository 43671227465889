/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Flex, Image, Paragraph } from 'theme-ui';
import EditUser from '../components/settings/edit-user';
import InviteUser from '../components/settings/invite-user';
import Invites from '../components/settings/invites';
import ManageTeam from '../components/settings/manage-team';
import Teams from '../components/settings/teams';
import UsersTable from '../components/settings/users-table';
import WorkflowTasks from '../components/settings/workflow-tasks';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import ConfirmDialog from '../components/tasks/confirm-dialog';
import { actions as menuActions } from '../store/reducers/menu';
import { actions as userActions } from '../store/reducers/users';
import { defaultOverlayState, defaultSettings } from '../utils/default-states';
import ManageWorkflow from '../components/settings/manage-workflow';
import MyProfile from '../components/settings/my-profile';
import ClientConfig from '../components/settings/client-config';
import TaskConfig from '../components/settings/task-config';
import DocTemplates from '../components/doc-templates';
import InvoiceSettings from '../components/settings/invoice-options';
import Integrations from '../components/settings/integrations';
import OrgSettings from '../components/settings/org-settings';
import ExpenseSettings from '../components/settings/expenses';
import theme from '../theme';

const defaultState = {
    loading: true,
    partialLoading: false,
    error: null,
    tabInFocus: 'my_profile',
    totalUsers: 0,
    usersOffset: 0,
    usersLimit: 8,
    userView: 'list',
    teamsView: 'list',
    totalInvites: 0,
    invitesOffset: 0,
    invitesLimit: 8,
    teamsOffset: 0,
    totalTeams: 0,
    teamsLimit: 8,
    workflowTasksOffset: 0,
    workflowTasksTotal: 0,
    workflowTasksLimit: 8,
    workflowView: 'list',
};

const Settings = ({ refreshCoreData, logout, userData, accountSummary, dismissTopMenu, organisation }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const userIdParam = queryParams.get('user_id') || false;
    const raw_id = queryParams.get('raw_id') || '0';
    const tabInFocus = queryParams.get('tab') || 'my_profile';

    const [state, updateState] = useState({
        ...defaultState,
        organisation,
        tabInFocus,
    });
    const [user, updateUser] = useState(userData);
    const [overlay, updateOverlay] = useState(defaultOverlayState);

    const [settingsJourney, updateSettingsJourney] = useState(defaultSettings);
    const navigate = useNavigate();

    const headerRef = useRef(null);

    useEffect(() => {
        updateState({
            ...state,
            organisation,
            daily_reminders_from: organisation?.task_configuration?.daily_reminders_from,
            reminder_date_to_use: organisation?.task_configuration?.reminder_date_to_use,
            reminders_per_service: organisation?.task_configuration?.reminders_per_service,
        });
    }, [organisation]);

    useEffect(() => {
        (async () => {
            try {
                await Auth.currentAuthenticatedUser();
                await Auth.currentSession();
                await refreshCoreData();

                let additionalStateData = {};
                if (userIdParam) {
                    try {
                        const userRes = await axios.get(
                            `${process.env.REACT_APP_AQRU_AI_API}/organisations/users/${userIdParam}?raw_id=${raw_id}`
                        );

                        additionalStateData = {
                            users: [],
                            totalUsers: 0,
                            tabInFocus: 'users',
                            userView: 'edit',
                            user: userRes.data,
                        };
                    } catch (e) {
                        // swallow
                    }
                }

                updateState({
                    ...state,
                    loading: false,
                    ...additionalStateData,
                });
            } catch (e) {
                logout();
                navigate('/');
            }
        })();
    }, []);

    useEffect(() => {
        if (state.tabInFocus !== 'users') return;

        updateState({ ...state, loading: true });

        (async () => {
            try {
                const {
                    data: { users, total },
                } = await axios({
                    url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/users`,
                    params: {
                        limit: state.usersLimit || 10,
                        offset: state.usersOffset || 0,
                        email_address: state.userSearchClause || undefined,
                    },
                });

                return updateState({ ...state, loading: false, users, totalUsers: total });
            } catch (e) {
                console.log(e);
                updateState({ ...state, loading: false, users: [] });
            }
        })();
    }, [state.tabInFocus, state.usersOffset, state.userSearchClause]);

    useEffect(() => {
        if (state.tabInFocus !== 'invites') return;

        updateState({ ...state, loading: true });

        (async () => {
            try {
                const {
                    data: { invites, total },
                } = await axios({
                    url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/invites`,
                    params: { limit: 300 },
                });

                return updateState({ ...state, loading: false, invites, totalInvites: total });
            } catch (e) {
                console.log(e);
                updateState({ ...state, loading: false, invites: [], totalInvites: 0 });
            }
        })();
    }, [state.tabInFocus, state.invitesOffset]);

    useEffect(() => {
        if (state.tabInFocus !== 'teams') return;

        updateState({ ...state, loading: true });

        (async () => {
            try {
                const {
                    data: { teams, total },
                } = await axios({
                    url: `${process.env.REACT_APP_AQRU_AI_API}/teams`,
                    params: { limit: state.teamsLimit || 10, offset: state.teamsOffset || 0 },
                });

                return updateState({ ...state, loading: false, teams, totalTeams: total });
            } catch (e) {
                console.log(e);
                updateState({ ...state, loading: false, teams: [], totalTeams: 0 });
            }
        })();
    }, [state.tabInFocus, state.teamsOffset]);

    useEffect(() => {
        if (state.tabInFocus !== 'workflow') return;

        updateState({ ...state, loading: true });

        (async () => {
            try {
                const [
                    {
                        data: { tasks, total },
                    },
                    {
                        data: { templates },
                    },
                ] = await Promise.all([
                    axios({
                        url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/workflow-tasks`,
                        params: { limit: state.workflowTasksLimit || 10, offset: state.workflowTasksOffset || 0 },
                    }),
                    axios({
                        url: `${process.env.REACT_APP_AQRU_AI_API}/email-templates`,
                        params: { limit: 500 },
                    }),
                ]);

                return updateState({
                    ...state,
                    loading: false,
                    tasks,
                    workflowTasksTotal: total,
                    emailTemplates: templates,
                });
            } catch (e) {
                console.log(e);
                updateState({ ...state, loading: false, tasks: [], totalTeams: 0 });
            }
        })();
    }, [state.tabInFocus, state.workflowTasksOffset]);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (headerRef.current && !headerRef.current.contains(e.target)) {
                // setIsVisible(false);
                dismissTopMenu();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick, false);
        return () => {
            return () => {
                document.removeEventListener('mousedown', handleOutsideClick, false);
            };
        };
    }, []);

    return (
        <>
            <Flex
                sx={{
                    minHeight: '100vh',
                    paddingBottom: '100px',
                    width: '100vw',
                    flexDirection: 'column',
                    position: 'relative',
                    backgroundSize: 'cover',
                }}
                data-testid="home-screen"
            >
                <Flex
                    sx={{
                        minHeight: '100vh',
                        width: '100vw',
                        flexDirection: 'column',
                    }}
                >
                    <Sidebar
                        currentPage="Settings"
                        overlay={overlay}
                        accountSummary={accountSummary}
                        user={user}
                        copy={{}}
                        settingsJourney={settingsJourney}
                        updateSettingsJourney={updateSettingsJourney}
                    />

                    {state.loading && <Spinner />}

                    {state.showUnlinkUserConfirmation && (
                        <ConfirmDialog
                            updateState={updateState}
                            state={state}
                            stateKey="showUnlinkUserConfirmation"
                            text="Are you sure you wish to unlink this user from your organisation?"
                        />
                    )}
                    {state.showUnlinkUserFromTeamConfirmation && (
                        <ConfirmDialog
                            updateState={updateState}
                            state={state}
                            stateKey="showUnlinkUserFromTeamConfirmation"
                            text="Are you sure you wish to unlink this user from this team?"
                        />
                    )}

                    {state.showDeleteTeamConfirmation && (
                        <ConfirmDialog
                            updateState={updateState}
                            state={state}
                            stateKey="showDeleteTeamConfirmation"
                            text="Are you sure you wish to delete this team?"
                        />
                    )}
                    {state.showDeleteWorkflowTaskConfirmation && (
                        <ConfirmDialog
                            updateState={updateState}
                            state={state}
                            stateKey="showDeleteWorkflowTaskConfirmation"
                            text="Are you sure you wish to delete this workflow task?"
                        />
                    )}

                    {state.showDeleteInviteConfirmation && (
                        <ConfirmDialog
                            updateState={updateState}
                            state={state}
                            stateKey="showDeleteInviteConfirmation"
                            text="Are you sure you wish to delete this invite?"
                        />
                    )}

                    {state.showRemoveAttributeConfirmation && (
                        <ConfirmDialog
                            updateState={updateState}
                            state={state}
                            stateKey="showRemoveAttributeConfirmation"
                            text="Are you sure you wish to remove this attribute from all client records? This cannot be undone easily."
                        />
                    )}

                    <Flex
                        sx={{
                            minHeight: 'calc(100vh)',
                            borderTopLeftRadius: '25px',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            mt: '0px',
                            border: `1px solid ${theme.colors.whiteBlockBorder}`,
                            borderRadius: 20,
                            padding: '10px 30px',
                            minWidth: 370,
                            backgroundColor: 'white',
                            ml: 260,
                            mr: 20,
                        }}
                    >
                        <Flex
                            sx={{
                                flexDirection: 'column',
                            }}
                        >
                            <Flex
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    mb: '30px',
                                }}
                            >
                                <Flex
                                    sx={{
                                        flexDirection: 'column',
                                        width: 1450,
                                    }}
                                >
                                    {state.error && (
                                        <Paragraph
                                            sx={{
                                                color: 'red',
                                                minHeight: '20px',
                                                mt: '20px',
                                                ml: 0,
                                                width: '100%',
                                            }}
                                        >
                                            {state.error}
                                        </Paragraph>
                                    )}
                                    <Flex sx={{ mt: 0 }}>
                                        <Paragraph
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    tabInFocus: 'my_profile',
                                                    error: null,
                                                    confirmMessage: null,
                                                    inviteError: null,
                                                    editError: null,
                                                    userView: 'list',
                                                    teamsView: 'list',
                                                })
                                            }
                                            sx={{
                                                borderLeft: '1px solid #EFEFEF',
                                                borderRight: '1px solid #EFEFEF',
                                                borderTop: '1px solid #EFEFEF',
                                                borderBottom:
                                                    state.tabInFocus === 'my_profile'
                                                        ? '6px solid #e8b923'
                                                        : ' 1px solid #EFEFEF',
                                                mt: 20,
                                                color: 'text',
                                                padding: 20,
                                                fontWeight: '300',
                                                width: 150,
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                backgroundColor:
                                                    state.tabInFocus === 'my_profile' ? '#FFF' : 'lightGrey',
                                            }}
                                        >
                                            My Profile
                                        </Paragraph>
                                        <Paragraph
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    tabInFocus: 'organisation',
                                                    error: null,
                                                    confirmMessage: null,
                                                    inviteError: null,
                                                    editError: null,
                                                    userView: 'list',
                                                    teamsView: 'list',
                                                })
                                            }
                                            sx={{
                                                borderLeft: '1px solid #EFEFEF',
                                                borderRight: '1px solid #EFEFEF',
                                                borderTop: '1px solid #EFEFEF',
                                                borderBottom:
                                                    state.tabInFocus === 'organisation'
                                                        ? '6px solid #e8b923'
                                                        : ' 1px solid #EFEFEF',
                                                mt: 20,
                                                color: 'text',
                                                padding: 20,
                                                fontWeight: '300',
                                                width: 200,
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                backgroundColor:
                                                    state.tabInFocus === 'organisation' ? '#FFF' : 'lightGrey',
                                            }}
                                        >
                                            Organisation
                                        </Paragraph>
                                        <Paragraph
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    tabInFocus: 'integrations',
                                                    error: null,
                                                    confirmMessage: null,
                                                    inviteError: null,
                                                    editError: null,
                                                    userView: 'list',
                                                    teamsView: 'list',
                                                })
                                            }
                                            sx={{
                                                borderLeft: '1px solid #EFEFEF',
                                                borderRight: '1px solid #EFEFEF',
                                                borderTop: '1px solid #EFEFEF',
                                                borderBottom:
                                                    state.tabInFocus === 'integrations'
                                                        ? '6px solid #e8b923'
                                                        : ' 1px solid #EFEFEF',
                                                mt: 20,
                                                color: 'text',
                                                padding: 20,
                                                fontWeight: '300',
                                                width: 150,
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                backgroundColor:
                                                    state.tabInFocus === 'integrations' ? '#FFF' : 'lightGrey',
                                            }}
                                        >
                                            Integrations
                                        </Paragraph>
                                        <Paragraph
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    tabInFocus: 'workflow',
                                                    error: null,
                                                    confirmMessage: null,
                                                    inviteError: null,
                                                    editError: null,
                                                    userView: 'list',
                                                    teamsView: 'list',
                                                })
                                            }
                                            sx={{
                                                borderLeft: '1px solid #EFEFEF',
                                                borderRight: '1px solid #EFEFEF',
                                                borderTop: '1px solid #EFEFEF',
                                                borderBottom:
                                                    state.tabInFocus === 'workflow'
                                                        ? '6px solid #e8b923'
                                                        : ' 1px solid #EFEFEF',
                                                mt: 20,
                                                color: 'text',
                                                padding: 20,
                                                fontWeight: '300',
                                                width: 150,
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                backgroundColor: state.tabInFocus === 'workflow' ? '#FFF' : 'lightGrey',
                                            }}
                                        >
                                            Workflow
                                        </Paragraph>
                                        <Paragraph
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    tabInFocus: 'clients',
                                                    error: null,
                                                    confirmMessage: null,
                                                    inviteError: null,
                                                    editError: null,
                                                    userView: 'list',
                                                    teamsView: 'list',
                                                })
                                            }
                                            sx={{
                                                textAlign: 'center',
                                                borderLeft: '1px solid #EFEFEF',
                                                borderRight: '1px solid #EFEFEF',
                                                borderTop: '1px solid #EFEFEF',
                                                borderBottom:
                                                    state.tabInFocus === 'clients'
                                                        ? '6px solid #e8b923'
                                                        : ' 1px solid #EFEFEF',
                                                mt: 20,
                                                color: 'text',
                                                cursor: 'pointer',
                                                padding: 20,
                                                fontWeight: '300',
                                                width: 150,
                                                backgroundColor: state.tabInFocus === 'clients' ? '#FFF' : 'lightGrey',
                                            }}
                                        >
                                            Clients
                                        </Paragraph>
                                        <Paragraph
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    tabInFocus: 'tasks',
                                                    error: null,
                                                    confirmMessage: null,
                                                    inviteError: null,
                                                    editError: null,
                                                    userView: 'list',
                                                    teamsView: 'list',
                                                })
                                            }
                                            sx={{
                                                textAlign: 'center',
                                                borderLeft: '1px solid #EFEFEF',
                                                borderRight: '1px solid #EFEFEF',
                                                borderTop: '1px solid #EFEFEF',
                                                borderBottom:
                                                    state.tabInFocus === 'tasks'
                                                        ? '6px solid #e8b923'
                                                        : ' 1px solid #EFEFEF',
                                                mt: 20,
                                                color: 'text',
                                                cursor: 'pointer',
                                                padding: 20,
                                                fontWeight: '300',
                                                width: 150,
                                                backgroundColor: state.tabInFocus === 'tasks' ? '#FFF' : 'lightGrey',
                                            }}
                                        >
                                            Tasks
                                        </Paragraph>
                                        {/* <Paragraph
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    tabInFocus: 'teams',
                                                    error: null,
                                                    confirmMessage: null,
                                                    inviteError: null,
                                                    editError: null,
                                                    userView: 'list',
                                                    teamsView: 'list',
                                                })
                                            }
                                            sx={{
                                                textAlign: 'center',
                                                borderLeft: '1px solid #EFEFEF',
                                                borderRight: '1px solid #EFEFEF',
                                                borderTop: '1px solid #EFEFEF',
                                                borderBottom: `1px solid #${
                                                    state.tabInFocus === 'teams' ? 'FFF' : 'EFEFEF'
                                                }`,
                                                mt: 20,
                                                color: 'text',
                                                cursor: 'pointer',
                                                padding: 20,
                                                fontWeight: '300',
                                                width: 150,
                                                backgroundColor: state.tabInFocus === 'teams' ? '#FFF' : 'lightGrey',
                                            }}
                                        >
                                            Teams
                                        </Paragraph> */}
                                        <Paragraph
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    tabInFocus: 'users',
                                                    error: null,
                                                    confirmMessage: null,
                                                    inviteError: null,
                                                    editError: null,
                                                    userView: 'list',
                                                    teamsView: 'list',
                                                })
                                            }
                                            sx={{
                                                textAlign: 'center',
                                                borderLeft: '1px solid #EFEFEF',
                                                borderRight: '1px solid #EFEFEF',
                                                borderTop: '1px solid #EFEFEF',
                                                borderBottom:
                                                    state.tabInFocus === 'users'
                                                        ? '6px solid #e8b923'
                                                        : ' 1px solid #EFEFEF',
                                                mt: 20,
                                                color: 'text',
                                                cursor: 'pointer',
                                                padding: 20,
                                                fontWeight: '300',
                                                width: 150,
                                                backgroundColor: state.tabInFocus === 'users' ? '#FFF' : 'lightGrey',
                                            }}
                                        >
                                            Users
                                        </Paragraph>

                                        <Paragraph
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    tabInFocus: 'invites',
                                                    error: null,
                                                    confirmMessage: null,
                                                    inviteError: null,
                                                    editError: null,
                                                    userView: 'list',
                                                    teamsView: 'list',
                                                })
                                            }
                                            sx={{
                                                textAlign: 'center',
                                                color: 'text',
                                                borderLeft: '1px solid #EFEFEF',
                                                borderRight: '1px solid #EFEFEF',
                                                borderTop: '1px solid #EFEFEF',
                                                borderBottom:
                                                    state.tabInFocus === 'invites'
                                                        ? '6px solid #e8b923'
                                                        : ' 1px solid #EFEFEF',
                                                mt: 20,
                                                cursor: 'pointer',
                                                padding: 20,
                                                fontWeight: '300',
                                                width: 150,
                                                backgroundColor: state.tabInFocus === 'invites' ? '#FFF' : 'lightGrey',
                                            }}
                                        >
                                            Invites
                                        </Paragraph>

                                        <Paragraph
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    tabInFocus: 'templates',
                                                    error: null,
                                                    confirmMessage: null,
                                                    inviteError: null,
                                                    editError: null,
                                                    userView: 'list',
                                                    teamsView: 'list',
                                                })
                                            }
                                            sx={{
                                                textAlign: 'center',
                                                color: 'text',
                                                borderLeft: '1px solid #EFEFEF',
                                                borderRight: '1px solid #EFEFEF',
                                                borderTop: '1px solid #EFEFEF',
                                                borderBottom:
                                                    state.tabInFocus === 'templates'
                                                        ? '6px solid #e8b923'
                                                        : ' 1px solid #EFEFEF',
                                                mt: 20,
                                                cursor: 'pointer',
                                                padding: 20,
                                                fontWeight: '300',
                                                width: 150,
                                                backgroundColor:
                                                    state.tabInFocus === 'templates' ? '#FFF' : 'lightGrey',
                                            }}
                                        >
                                            Templates
                                        </Paragraph>
                                        <Paragraph
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    tabInFocus: 'invoices',
                                                    error: null,
                                                    confirmMessage: null,
                                                    inviteError: null,
                                                    editError: null,
                                                    userView: 'list',
                                                    teamsView: 'list',
                                                })
                                            }
                                            sx={{
                                                textAlign: 'center',
                                                color: 'text',
                                                borderLeft: '1px solid #EFEFEF',
                                                borderRight: '1px solid #EFEFEF',
                                                borderTop: '1px solid #EFEFEF',
                                                borderBottom:
                                                    state.tabInFocus === 'invoices'
                                                        ? '6px solid #e8b923'
                                                        : ' 1px solid #EFEFEF',
                                                mt: 20,
                                                cursor: 'pointer',
                                                padding: 20,
                                                fontWeight: '300',
                                                width: 150,
                                                backgroundColor: state.tabInFocus === 'invoices' ? '#FFF' : 'lightGrey',
                                            }}
                                        >
                                            Invoices
                                        </Paragraph>
                                        {/* <Paragraph
                                            onClick={() =>
                                                updateState({
                                                    ...state,
                                                    tabInFocus: 'expenses',
                                                    error: null,
                                                    confirmMessage: null,
                                                    inviteError: null,
                                                    editError: null,
                                                    userView: 'list',
                                                    teamsView: 'list',
                                                })
                                            }
                                            sx={{
                                                textAlign: 'center',
                                                color: 'text',
                                                borderLeft: '1px solid #EFEFEF',
                                                borderRight: '1px solid #EFEFEF',
                                                borderTop: '1px solid #EFEFEF',
                                                borderBottom:
                                                    state.tabInFocus === 'expenses'
                                                        ? '6px solid #e8b923'
                                                        : ' 1px solid #EFEFEF',
                                                mt: 20,
                                                cursor: 'pointer',
                                                padding: 20,
                                                fontWeight: '300',
                                                width: 150,
                                                backgroundColor: state.tabInFocus === 'expenses' ? '#FFF' : 'lightGrey',
                                            }}
                                        >
                                            Expenses
                                        </Paragraph> */}
                                        <Flex
                                            sx={{
                                                height: '1px',
                                                width: 80,
                                                mt: 92,
                                                borderBottom: '1px solid #EFEFEF',
                                            }}
                                        />
                                    </Flex>
                                    <Flex
                                        sx={{
                                            paddingLeft: 30,
                                            paddingBottom: 30,
                                        }}
                                    >
                                        {state.tabInFocus === 'users' && state.userView === 'list' && (
                                            <UsersTable
                                                organisation={organisation}
                                                state={state}
                                                updateState={updateState}
                                            />
                                        )}

                                        {state.tabInFocus === 'teams' && state.teamsView === 'list' && (
                                            <Teams
                                                organisation={organisation}
                                                state={state}
                                                updateState={updateState}
                                            />
                                        )}

                                        {state.tabInFocus === 'teams' && state.teamsView === 'manage' && (
                                            <ManageTeam
                                                organisation={organisation}
                                                state={state}
                                                updateState={updateState}
                                            />
                                        )}

                                        {state.tabInFocus === 'users' && state.userView === 'edit' && (
                                            <EditUser state={state} updateState={updateState} />
                                        )}

                                        {state.tabInFocus === 'users' && state.userView === 'invite' && (
                                            <InviteUser state={state} updateState={updateState} />
                                        )}

                                        {state.tabInFocus === 'invites' && (
                                            <Invites
                                                state={state}
                                                updateState={updateState}
                                                organisation={organisation}
                                            />
                                        )}

                                        {state.tabInFocus === 'integrations' && (
                                            <Integrations
                                                state={state}
                                                updateState={updateState}
                                                organisation={organisation}
                                            />
                                        )}

                                        {state.tabInFocus === 'templates' && (
                                            <DocTemplates
                                                state={state}
                                                updateState={updateState}
                                                organisation={organisation}
                                            />
                                        )}

                                        {state.tabInFocus === 'invoices' && (
                                            <InvoiceSettings
                                                state={state}
                                                updateState={updateState}
                                                organisation={state.organisation}
                                            />
                                        )}

                                        {/* {state.tabInFocus === 'expenses' && (
                                            <ExpenseSettings
                                                state={state}
                                                updateState={updateState}
                                                organisation={state.organisation}
                                            />
                                        )} */}

                                        {state.tabInFocus === 'my_profile' && (
                                            <MyProfile
                                                state={state}
                                                updateState={updateState}
                                                organisation={organisation}
                                                refreshCoreData={refreshCoreData}
                                            />
                                        )}

                                        {state.tabInFocus === 'organisation' && (
                                            <OrgSettings
                                                state={state}
                                                updateState={updateState}
                                                organisation={organisation}
                                                refreshCoreData={refreshCoreData}
                                            />
                                        )}

                                        {state.tabInFocus === 'workflow' && state.workflowView === 'list' && (
                                            <WorkflowTasks
                                                state={state}
                                                updateState={updateState}
                                                organisation={organisation}
                                            />
                                        )}

                                        {state.tabInFocus === 'workflow' && state.workflowView === 'manage' && (
                                            <ManageWorkflow
                                                state={state}
                                                updateState={updateState}
                                                organisation={organisation}
                                            />
                                        )}

                                        {state.tabInFocus === 'clients' && (
                                            <ClientConfig
                                                state={state}
                                                updateState={updateState}
                                                organisation={organisation}
                                                refreshCoreData={refreshCoreData}
                                            />
                                        )}

                                        {state.tabInFocus === 'tasks' && (
                                            <TaskConfig
                                                state={state}
                                                updateState={updateState}
                                                organisation={organisation}
                                                refreshCoreData={refreshCoreData}
                                            />
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
    logout: () => dispatch({ type: 'LOGOUT' }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
        isMobile: account.isMobile,
        accountSummary: account.accountSummary,
        organisation: account.organisation,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
