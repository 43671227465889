/** @jsxImportSource theme-ui */
import { Heading, Button, Paragraph, Image, Link, Flex, Box } from 'theme-ui';
import { useNavigate } from 'react-router-dom';
import Waves from '../waves';

const ResetPasswordSent = (props: any) => {
    const navigate = useNavigate();

    return (
        <Flex
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                minHeight: '100vh',
                background: 'primary',
                backgroundSize: 'cover',
            }}
        >
            <Flex
                sx={{
                    width: ['100%', '600px'],
                    height: '550px',
                    p: '50px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: '10px',
                }}
            >
                <Waves />
                <Box sx={{ margin: '0px auto 30px' }}>
                    <Image
                        src={`https://cdn.accountingflow.io/af/logo-dark.png`}
                        width="260px"
                        height="auto"
                        alt="refer a friend"
                        data-testid="login-logo"
                    />
                </Box>
                <Heading sx={{ mt: 20 }}>Check your email</Heading>
                <Paragraph
                    sx={{
                        mt: 20,
                        textAlign: 'center',
                        width: [300, 460],
                        lineHeight: '22px',
                    }}
                >
                    If your email address address matches our records, then you will receive an email in the next few
                    moments.
                </Paragraph>
                <Button
                    data-testid="resend"
                    sx={{ mt: 40, width: '250px' }}
                    onClick={() => navigate('/reset-password/request')}
                >
                    I didn't get a link
                </Button>
                <Link data-testid="resend" sx={{ mt: 20 }} onClick={() => navigate('/')}>
                    Login
                </Link>
            </Flex>
        </Flex>
    );
};

export default ResetPasswordSent;
