import { useEffect } from 'react';
import { Flex, Box } from 'theme-ui';
import { connect } from 'react-redux';
import JourneyContent from './journey';
import StaticContent from './static';
import { actions as userActions } from '../../store/reducers/users';
import theme from '../../theme';

const Overlay = (props: any) => {
    useEffect(() => {
        document.body.style.overflowY = 'hidden';
    }, []);

    let maxWidth = props.maxWidth || 700;
    let maxHeight = props.maxHeight || 720;

    if (props?.size === 'large') {
        maxWidth = 1200;
        maxHeight = 830;
    } else if (props?.size === 'small') {
        maxWidth = 400;
        maxHeight = 300;
    } else if (props?.size === 'tall') {
        maxWidth = 650;
        maxHeight = 800;
    }

    return (
        <Flex
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                backgroundColor: 'rgba(0,0,0,0.6)',
                width: '100%',
                height: '100%',
                zIndex: 10000005,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                id="aqru_modal"
                sx={{
                    width: '98%',
                    maxWidth,
                    height: '98%',
                    maxHeight,
                    backgroundColor: 'white',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
                    borderRadius: '10px',
                    position: 'relative',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    border: `2px solid ${theme.colors.primary}`,
                    alignItems: 'center',
                    overflow: 'hidden',
                    ...(props.customSx || {}),
                    '@media screen and (max-height: 820px)': {
                        maxHeight: '670px',
                    },
                    '@media screen and (max-width: 500px)': {
                        width: '100%',
                        maxWidth: '100%',
                        borderRadius: '0',
                        height: '100%',
                        maxHeight: '100%',
                        overflow: 'scroll',
                        mt: 210,
                    },
                }}
            >
                {!props?.overlay?.hideNavigation && (
                    <Flex
                        sx={{
                            position: 'absolute',
                            top: '23px',
                            right: '25px',
                            cursor: 'pointer',
                            fontWeight: '600',
                            zIndex: 100,
                        }}
                        onClick={async () => {
                            document.body.style.overflowY = 'auto';
                            const kycStatusParam = new URLSearchParams(window.location.search).get('kyc') || false;
                            if (kycStatusParam === 'poa') {
                                window.location.assign('/library');
                            }

                            if (props.overlay?.initialComponentToRender === 'StartKYC') {
                                await props?.refreshCoreData();
                            }
                            if (props.overlay?.journey) {
                                const resetState = props.resetState ? props.resetState : props.overlay;
                                props.updateOverlay({
                                    ...resetState,
                                    show: false,
                                    componentToRender: props.overlay?.initialComponentToRender,
                                });
                            } else {
                                props.updateOverlay({ ...props.overlay, show: false });
                            }
                        }}
                        data-testid="close-overlay"
                    >
                        <i
                            aria-label="close overlay"
                            // @ts-ignore
                            style={{
                                fontSize: 24,
                                color: 'inherit',
                            }}
                            className="fas fa-times-circle"
                        />
                    </Flex>
                )}
                {!props.overlay?.journey && !props.embeddedComponent && (
                    <StaticContent
                        copy={props.copy}
                        image={props.overlay?.image}
                        text={props.overlay?.text}
                        buttonText={props.overlay?.buttonText}
                        action={
                            props.overlay?.action === null
                                ? () => props.updateOverlay({ ...props.overlay, show: false })
                                : props.overlay?.action
                        }
                        title={props.overlay?.title}
                        type={props.overlay?.type}
                    />
                )}

                {props.embeddedComponent}

                {props.overlay?.journey && (
                    <JourneyContent copy={props.copy} overlay={props.overlay} updateOverlay={props.updateOverlay} />
                )}
            </Box>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
});

export default connect(null, mapDispatchToProps)(Overlay);
