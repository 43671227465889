/* eslint-disable no-undef */
import { Button, Flex, Paragraph } from 'theme-ui';
import Divider from '../divider';
import Spinner from '../spinner';
import { useEffect, useState } from 'react';
import axios from 'axios';
import theme from '../../theme';
import moment from 'moment';
import { downloadFile, lookupIconType } from '../../pages/inbox';
import { toast } from 'react-toastify';

export const loadEmails = async (state, updateState, localState, updateLocalState) => {
    try {
        if (!state?.clientInView?.id) return;

        updateState({ ...state, loading: true });

        const {
            data: { emails },
        } = await axios.get(
            `${process.env.REACT_APP_AQRU_AI_API}/outlook/clients/${state.clientInView?.raw_id}/emails`,
            {}
        );

        updateState({ ...state, loading: false });
        updateLocalState({ ...localState, emails });
    } catch (e) {
        updateState({ ...state, loading: false });
        toast.error('Please reconnect your inbox to Accounting Flow.');
    }
};

export const loadEmailContent = async (state, updateState, localState, updateLocalState, id) => {
    try {
        if (!state.clientInView?.id) return;

        updateState({ ...state, loading: true });

        const { data: email } = await axios.get(
            `${process.env.REACT_APP_AQRU_AI_API}/outlook/clients/${state.clientInView?.raw_id}/emails/${id}`,
            {}
        );

        for (let index = 0; index < email.messages_in_conversation?.length; index++) {
            const message = email.messages_in_conversation?.[index];
            if (!message) break;
            const {
                data: { html: htmlReturned, attachments },
            } = await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/outlook/process-html`,
                method: 'POST',
                data: {
                    message_id: message.id,
                    html: message.body.content,
                },
            });

            message.body.content = htmlReturned;
            message.attachments = attachments;
        }

        updateState({ ...state, loading: false });
        updateLocalState({ ...localState, email, view: 'view' });
    } catch (e) {
        updateState({ ...state, loading: false });
    }
};

export const loadAttachments = async (state, updateState, localState, updateLocalState, message_id, html) => {
    try {
        if (!state.clientInView.id) return;

        updateState({ ...state, loading: true });

        const {
            data: { html: htmlReturned, attachments },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/outlook/process-html`,
            method: 'POST',
            data: {
                message_id,
                html,
            },
        });

        updateState({ ...state, loading: false });
        updateLocalState({
            ...localState,
            email: {
                ...localState.email,
                messages_in_conversation: localState.email?.messages_in_conversation?.map((msg) => {
                    if (msg.id === message_id) {
                        msg.attachments = attachments;
                        msg.body.content = htmlReturned;
                    }
                    return msg;
                }),
            },
        });
    } catch (e) {
        updateState({ ...state, loading: false });
    }
};

const Emails = ({ state, updateState }) => {
    const [localState, updateLocalState] = useState({ view: 'list', emails: [] });
    useEffect(() => {
        loadEmails(state, updateState, localState, updateLocalState);
    }, []);

    return (
        <Flex sx={{ flexDirection: 'column', mt: 20, ml: 20 }}>
            <Paragraph sx={{ fontWeight: 600, fontSize: 20 }}>
                {localState.view === 'view' ? 'Email' : 'Emails'}
            </Paragraph>
            <Divider width={'95%'} />

            {state.loading ? <Spinner /> : null}

            {localState.view === 'view' ? (
                <Flex sx={{ flexDirection: 'column' }}>
                    {localState.email?.task?.title ? (
                        <a target="_blank" rel="noreferrer" href={`/tasks?id=${localState.email?.task?.uuid}`}>
                            <Button sx={{ ml: 0, mb: 20 }}>
                                <i
                                    style={{
                                        marginRight: '7px',
                                    }}
                                    className={`fas fa-external-link-alt`}
                                />
                                Open Linked Task
                            </Button>
                        </a>
                    ) : null}

                    {localState.email?.messages_in_conversation?.map((x, idx) => (
                        <Flex key={`${x.id}`} sx={{ flexDirection: 'column', mb: 30 }}>
                            <Flex sx={{ justifyContent: 'space-between', mt: 10 }}>
                                <Flex sx={{ flexDirection: 'column' }}>
                                    <Paragraph sx={{ fontWeight: 600 }}>From</Paragraph>
                                    <Paragraph>{x.from.emailAddress.address}</Paragraph>
                                </Flex>
                                <Flex sx={{ flexDirection: 'column' }}>
                                    <Paragraph sx={{ mt: 10, textAlign: 'right', fontWeight: 600 }}>To</Paragraph>
                                    <Paragraph>
                                        {x.toRecipients?.map((to) => to.emailAddress?.address)?.join(' ')}
                                    </Paragraph>
                                </Flex>
                            </Flex>

                            {x?.ccRecipients?.length ? (
                                <Paragraph sx={{ mt: 10, fontWeight: 600 }}>Cc</Paragraph>
                            ) : null}
                            {x?.ccRecipients?.length ? (
                                <Paragraph>
                                    {x.ccRecipients?.map((to) => to.emailAddress?.address)?.join(' ')}
                                </Paragraph>
                            ) : null}

                            {x?.bccRecipients?.length ? (
                                <Paragraph sx={{ mt: 10, fontWeight: 600 }}>Bcc</Paragraph>
                            ) : null}
                            {x?.bccRecipients?.length ? (
                                <Paragraph>
                                    {x.bccRecipients?.map((to) => to.emailAddress?.address)?.join(' ')}
                                </Paragraph>
                            ) : null}

                            <Flex sx={{ justifyContent: 'space-between', mt: 10 }}>
                                <Flex sx={{ flexDirection: 'column' }}>
                                    <Paragraph sx={{ mt: 10, fontWeight: 600 }}>Subject</Paragraph>
                                    <Paragraph>{x.subject}</Paragraph>
                                </Flex>
                                <Flex sx={{ flexDirection: 'column' }}>
                                    <Paragraph sx={{ mt: 10, textAlign: 'right', fontWeight: 600 }}>Sent At</Paragraph>
                                    <Paragraph>{moment(x.sentDateTime).format('HH:mm DD/MM/YYYY')}</Paragraph>
                                </Flex>
                            </Flex>

                            {x.attachments?.length ? (
                                <Flex
                                    sx={{
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        gap: 10,
                                        mt: 20,
                                    }}
                                >
                                    {x.attachments?.map((file, idx) => (
                                        <Flex
                                            sx={{
                                                padding: 20,
                                                border: '1px solid #EFEFEF',
                                                cursor: 'pointer',
                                                alignItems: 'center',
                                                maxWidth: 370,
                                            }}
                                            key={file.id}
                                            onClick={() => {
                                                downloadFile(file.contentBytes, file.name, file.contentType);
                                            }}
                                        >
                                            <i
                                                style={{
                                                    fontSize: '16px',
                                                    cursor: 'pointer',
                                                    marginRight: '7px',
                                                    color: theme.colors.primary,
                                                }}
                                                className={`fas ${lookupIconType(file.contentType)}`}
                                                aria-hidden="true"
                                            />
                                            <Paragraph>{file.name}</Paragraph>
                                        </Flex>
                                    ))}
                                </Flex>
                            ) : (
                                <Button
                                    onClick={async () => {
                                        await loadAttachments(
                                            state,
                                            updateState,
                                            localState,
                                            updateLocalState,
                                            x.id,
                                            x.body.content
                                        );
                                    }}
                                    variant="light"
                                    sx={{ mt: 20, width: 180 }}
                                >
                                    Load Attachments
                                </Button>
                            )}
                            <Flex
                                sx={{ flexDirection: 'column', mt: 20 }}
                                dangerouslySetInnerHTML={{
                                    __html: x?.body?.content,
                                }}
                            />
                            <Divider />
                        </Flex>
                    ))}

                    <Flex
                        sx={{
                            position: 'fixed',
                            height: 80,
                            background: 'white',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            alignItems: 'center',
                            borderTop: '2px solid #EFEFEF',
                            width: '100%',
                            zIndex: 998,
                        }}
                    >
                        <Button
                            onClick={() => updateLocalState({ ...localState, view: 'list', email: null })}
                            variant="light"
                            sx={{ ml: 20, mr: 10, width: 100 }}
                        >
                            <i
                                className="fa fa-chevron-left"
                                style={{
                                    marginRight: '5px',
                                }}
                            />
                            Back
                        </Button>
                    </Flex>
                </Flex>
            ) : null}

            {localState.view === 'list' ? (
                <table
                    style={{
                        borderCollapse: 'collapse',
                        width: '1000px',
                        marginTop: 20,
                        marginRight: 20,
                        fontSize: 14,
                    }}
                >
                    <thead
                        style={{
                            backgroundColor: theme.colors.primary,
                            opacity: 0.9,
                            height: 50,
                            color: 'white',
                        }}
                    >
                        <tr style={{ borderTop: '0px solid lightGrey' }}>
                            <th style={{ width: 150, textAlign: 'center', paddingLeft: 20 }}>From</th>
                            <th style={{ width: 150, textAlign: 'center', padding: 20 }}>To</th>
                            <th style={{ width: 150, textAlign: 'center', padding: 20 }}>Subject</th>
                            <th style={{ width: 150, textAlign: 'center', padding: 20 }}>Created At</th>
                            <th style={{ width: 125 }}></th>
                        </tr>
                    </thead>
                    <tbody style={{ borderCollapse: 'collapse' }}>
                        {localState.emails?.map((email, idx) => (
                            <tr key={`email_${idx}`}>
                                <td
                                    style={{
                                        padding: 25,
                                        borderLeft: 'none',
                                        borderRight: 'none',
                                        textAlign: 'center',
                                    }}
                                >
                                    {email.email_address}
                                </td>
                                <td
                                    style={{
                                        padding: 25,
                                        borderLeft: 'none',
                                        borderRight: 'none',
                                        textAlign: 'center',
                                    }}
                                >
                                    {email.recipients?.to?.map((to) => to.emailAddress?.address)?.join(' ')}
                                </td>
                                <td
                                    style={{
                                        padding: 25,
                                        borderLeft: 'none',
                                        borderRight: 'none',
                                        textAlign: 'center',
                                    }}
                                >
                                    {email.subject}
                                </td>
                                <td
                                    style={{
                                        padding: 25,
                                        borderLeft: 'none',
                                        borderRight: 'none',
                                        textAlign: 'center',
                                    }}
                                >
                                    {moment(email.created_at).format('HH:mm DD/MM/YYYY')}
                                </td>
                                <td>
                                    <Flex sx={{ justifyContent: 'center' }}>
                                        <Button
                                            onClick={async () => {
                                                await loadEmailContent(
                                                    state,
                                                    updateState,
                                                    localState,
                                                    updateLocalState,
                                                    email.id
                                                );
                                            }}
                                        >
                                            <i
                                                style={{
                                                    marginRight: '7px',
                                                }}
                                                className={`fas fa-external-link-alt`}
                                            />
                                            Open
                                        </Button>
                                    </Flex>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : null}
        </Flex>
    );
};

export default Emails;
