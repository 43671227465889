/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { Flex, Paragraph, Button, Image } from 'theme-ui';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import Spinner from '../components/spinner';
import Sidebar from '../components/sidebar';
import { actions as userActions } from '../store/reducers/users';
import { actions as menuActions } from '../store/reducers/menu';
import { loadUploads } from '../components/documents/load-uploads';
import { loadDocuments } from '../components/documents/load-docs';
import { DocumentList, DocumentsAndUploads } from '../components/documents/uploads';
import { AddNewFile } from '../components/documents/add-new-file';
import Divider from '../components/divider';

export const defaultState = {
    loading: true,
    forceUploadDocs: false,
    error: null,
    offset: 0,
    limit: 5,
    total: 0,
    files: [],
    uploads: [],
    documents: [],
    selectLabels: [],
    labels: [],
    showCustomlabelsInput: [],
    docTotal: 0,
    docOffset: 0,
    docLimit: 10,
    docSearchLabels: [],
    docSearchDescription: '',
    componentToShow: '', // docsOnly || uploads || addNew
    showDeleteDocOverlay: false,
};

const YourDocuments = ({ dataLoading, refreshCoreData, userData, accountSummary, isMobile, organisation }) => {
    const [state, updateState] = useState(defaultState);
    const [user, updateUser] = useState(userData);

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                await Auth.currentAuthenticatedUser();
                await refreshCoreData();

                const { uploads, total } = await loadUploads(state.offset, state.limit);
                const { documents, labels, total: docTotal } = await loadDocuments(state.docOffset, state.docLimit);
                if (!labels?.find((x) => x === 'other')) {
                    labels.push('other');
                }

                return updateState({
                    ...state,
                    loading: false,
                    forceUploadDocs: documents?.length < 1,
                    componentToShow: documents?.length < 1 ? 'addNew' : 'docsOnly',
                    uploads,
                    total,
                    documents,
                    selectLabels: labels,
                    docTotal,
                });
            } catch (e) {
                console.log(e);
            }
        })();
    }, [state.offset, state.docOffset]);

    const stateRef = useRef();
    stateRef.current = state;

    return (
        <>
            <Flex
                sx={{
                    minHeight: '100vh',
                    paddingBottom: '100px',
                    width: '100vw',
                    flexDirection: 'column',
                    backgroundColor: 'primary',
                    position: 'relative',
                }}
                data-testid="home-screen"
            >
                <Flex
                    sx={{
                        minHeight: '100vh',
                        width: '100vw',
                        flexDirection: 'column',
                    }}
                >
                    <Sidebar currentPage="Library" accountSummary={accountSummary} user={user} copy={{}} />
                    {dataLoading && (
                        <Flex
                            sx={{
                                width: '100%',
                                height: '100vh',
                                zIndex: '9999',
                                position: 'absolute',
                            }}
                        >
                            <Spinner />
                        </Flex>
                    )}

                    <>
                        <Flex
                            sx={{
                                width: 'calc(100% - 110px)',
                                border: '0px solid lightgrey',
                                minHeight: 'calc(100vh)',
                                backgroundColor: '#ffffff',
                                borderTopLeftRadius: '25px',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                mt: '15px',
                                ml: '120px',
                            }}
                        >
                            <Flex
                                sx={{
                                    flexDirection: 'column',
                                    ml: '30px',
                                    position: 'relative',
                                    width: '1300px',
                                }}
                            >
                                <Flex sx={{ alignItems: 'center' }}>
                                    <Image
                                        sx={{ mt: 40, width: 300, mb: 0, ml: 0 }}
                                        src={'https://cdn.accountingflow.io/af/logo-dark.png'}
                                        alt=""
                                    />
                                    <i
                                        style={{
                                            marginLeft: 10,
                                            marginRight: 20,
                                            marginTop: 40,
                                            fontSize: '23px',
                                            cursor: 'pointer',
                                        }}
                                        className="fal fa-handshake"
                                        aria-hidden="true"
                                    />
                                    <Image
                                        sx={{ mt: 50, height: 20, width: 300, mb: 10, ml: 0 }}
                                        src={organisation?.default_visualisation?.logo}
                                        alt=""
                                    />
                                </Flex>

                                <Flex
                                    sx={{
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        minHeight: '160px',
                                        mb: '30px',
                                        border: '0px red solid',
                                    }}
                                >
                                    <Flex sx={{ flexDirection: 'column' }}>
                                        {state?.componentToShow !== '' && (
                                            <>
                                                <Paragraph
                                                    sx={{
                                                        fontWeight: '400',
                                                        fontSize: '25px',
                                                        color: 'text',
                                                        textAlign: 'left',
                                                        mt: '30px',
                                                    }}
                                                >
                                                    {state?.componentToShow === 'addNew'
                                                        ? 'Add Files'
                                                        : 'Your Document Library'}
                                                </Paragraph>
                                                <Divider width="1300px" />
                                            </>
                                        )}

                                        {state.loading && (
                                            <Flex sx={{ width: '100%', height: '100%', justifyContent: 'center' }}>
                                                <Spinner />
                                            </Flex>
                                        )}
                                        {/* {!state.loading && state?.componentToShow !== 'addNew' && (
                                            <Flex
                                                sx={{
                                                    width: '200px',
                                                    height: '50px',
                                                    flexDirection: 'row',
                                                    border: '1px #eaecf0 solid',
                                                    borderRadius: '10px',
                                                    borderRightRadius: '0',

                                                    mt: '20px',
                                                }}
                                            >
                                                <Flex
                                                    sx={{
                                                        flex: 1,
                                                        borderRadius: '10px',
                                                        borderTopRightRadius: '0',
                                                        borderBottomRightRadius: '0',
                                                        cursor: 'pointer',
                                                        alignItems: 'center',
                                                        background:
                                                            state?.componentToShow === 'docsOnly'
                                                                ? '#F9FAFB'
                                                                : 'inherit',
                                                    }}
                                                    onClick={() => {
                                                        updateState({ ...state, componentToShow: 'docsOnly' });
                                                    }}
                                                >
                                                    <Paragraph
                                                        sx={{
                                                            width: '100%',
                                                            textAlign: 'center',
                                                            fontSize: '13px',
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Files
                                                    </Paragraph>
                                                </Flex>

                                                <Flex
                                                    sx={{
                                                        flex: 1,
                                                        borderRadius: '10px',
                                                        borderTopLeftRadius: '0',
                                                        borderBottomLeftRadius: '0',
                                                        cursor: 'pointer',
                                                        alignItems: 'center',
                                                        borderLeft: '1px solid #eaecf0 ',
                                                        background:
                                                            state?.componentToShow === 'uploads'
                                                                ? '#F9FAFB'
                                                                : 'inherit',
                                                    }}
                                                    onClick={() => {
                                                        updateState({ ...state, componentToShow: 'uploads' });
                                                    }}
                                                >
                                                    <Paragraph
                                                        sx={{
                                                            width: '100%',
                                                            textAlign: 'center',
                                                            fontSize: '13px',
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Templates
                                                    </Paragraph>{' '}
                                                </Flex>
                                            </Flex>
                                        )} */}
                                    </Flex>
                                </Flex>
                                {state?.componentToShow === 'addNew' && (
                                    <AddNewFile state={state} updateState={updateState} />
                                )}

                                {state?.componentToShow === 'uploads' && (
                                    <DocumentsAndUploads
                                        state={state}
                                        updateState={updateState}
                                        deleteFile={() => {}}
                                        scrollLatestIntoView={() => {}}
                                        navigate={navigate}
                                        isMobile={isMobile}
                                        userData={userData}
                                    />
                                )}
                                {state?.componentToShow === 'docsOnly' && (
                                    <DocumentList
                                        state={state}
                                        updateState={updateState}
                                        deleteFile={() => {}}
                                        scrollLatestIntoView={() => {}}
                                        navigate={navigate}
                                        isMobile={isMobile}
                                    />
                                )}
                                {state.error && (
                                    <Paragraph
                                        sx={{
                                            color: 'red',
                                            minHeight: '20px',
                                            mt: '0px',
                                            width: '100%',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {state.error}
                                    </Paragraph>
                                )}
                            </Flex>
                        </Flex>
                    </>
                </Flex>
            </Flex>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
    logout: () => dispatch({ type: 'LOGOUT' }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
        isMobile: account.isMobile,
        accountSummary: account.accountSummary,
        organisation: account.organisation,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(YourDocuments);
