import { Heading, Box, Paragraph, Image, Button, Flex } from 'theme-ui';

const StaticContent = ({ image, text, buttonText, action, title, type = '' }: any) => (
    <Flex sx={{ alignItems: 'center', flexDirection: 'column' }}>
        <Box
            sx={{
                width: type === 'freemoney' ? '300px' : '200px',
                height: 'auto',
                maxHeight: '160px',
                mt: '60px',
                overflow: 'hidden',
                '@media screen and (max-width: 400px)': {
                    width: type === 'freemoney' ? '200px' : '200px',
                    mt: '20px',
                },
            }}
        >
            <Image
                sx={{
                    overflow: 'hidden',
                    '@media screen and (max-width: 400px)': {
                        width: type === 'freemoney' ? '200px' : '200px',
                    },
                }}
                src={`https://cdn.accountingflow.io/${image}`}
                width={type === 'freemoney' ? '300px' : '200px'}
                height="auto"
                alt={title}
            />
        </Box>

        <Heading
            sx={{
                mt: 40,
                maxWidth: '70%',
                textAlign: 'center',
                '@media screen and (max-width: 550px)': {
                    fontSize: '18px',
                    mt: type === 'freemoney' ? '10px' : 40,
                },
            }}
        >
            {title}
        </Heading>

        <Paragraph sx={{ mt: '30px', textAlign: 'center', maxWidth: '70%' }}>{text}</Paragraph>

        <Button data-testid={buttonText} sx={{ mt: 30 }} variant="primary-wide" onClick={() => action()}>
            {buttonText}
        </Button>
    </Flex>
);
export default StaticContent;
