import * as moment from 'moment';

export default (model, pdfColumns) => {
    const html = `<html>
        <head>
            <link
                href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap"
                rel="stylesheet"
            />
            <style>
                * {
                    font-family: 'Raleway', 'Inter', 'Roboto', 'Helvetica Neue', sans-serif;
                }
                .container {
                }
                .org_address {
                    position: absolute;
                    right: 10px;
                    line-height: 12px;
                    width: 170px;
                }
                .contact_address {
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
                th {
                    font-weight: 400;
                    padding: 15px;
                    text-align: center;
                }
                .contact_address_container {
                    margin-top: 40px;
                    margin-bottom: 40px;
                    line-height: 12px;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    font-size: 12px;
                }

                p {
                    font-size: 13px;
                }
                td {
                    font-size: 12px;
                    padding: 15px;
                    text-align: center;
                }
                th {
                    background-color: ${model.tableHeaderColor}; /* Background color of the header */
                    color: white; /* Text color */
                    padding: 12px 15px; /* Padding for header cells */
                    font-weight: 600; /* Bold text */
                    text-transform: uppercase; /* Uppercase text for header */
                    letter-spacing: 0.05em; /* Slight letter spacing */
                }

                th:first-child, td:first-child {
                    text-align: left;
                }
                .logo {
                    width: 230px; 
                    position: absolute; 
                    left: 10px; 
                    top: 10px;
                }
                .dotted-line {
                    border-top: 1px dotted #ccc; 
                    width: 100%;                 
                    margin: 20px 0;              
                }
                .invoice_table {
                    width: 100%;
                }
                .totals {
                    float: right; /* Align the totals div to the right side of the page */
                    margin-right: 20px;
                }

                .totals > row {
                    display: inline-block; /* Display each section inline */
                    vertical-align: top; /* Align the sections to the top */
                    margin-left: 20px; /* Space between each total section */
                    text-align: right; /* Align text within each section to the right */
                }

                .totals > div p:first-child {
                    width: 100px; /* Set a fixed width for the labels */
                    display: inline-block;
                    text-align: left; /* Align the labels to the left within their space */
                    margin: 0;
                    font-weight: bold; /* Make the labels bold for emphasis */
                }

                .totals > div p:last-child {
                    margin: 5px 0 0 0;
                    font-size: 14px;
                    font-weight: normal;
                    display: inline-block;
                }

                thead { display: table-header-group; }
                tfoot { display: table-row-group; }
                tr, .bank_details, .custom-fields { page-break-inside: avoid; }
            </style>
        </head>
        <body>
            <img src="${model.logo}" class="logo" />
            <div class="container">
                <div class="org_address">
                    <p>${model.organisation_address.name}</p>
                    <p>${model.organisation_address.line_1}</p>
                    <p>${model.organisation_address.line_2}</p>
                    <p>${model.organisation_address.line_3}</p>
                    <p>${model.organisation_address.line_4}</p>
                    <p>${model.organisation_address.line_5}</p>
                </div>
                <div style="height: 180px;"></div>
                <p style="font-size: 17px; font-weight: 800;">VAT Invoice</p>
                <p>Invoice Number: ${model.invoice_number}</p>
                <p>Invoice Date: ${model.invoice_date}</p>
                ${model.bank_details ? `<p>Due Date: ${model.bank_details.payable_by}</p>` : ''}
                <div class="contact_address_container">
                ${model.contact_address
                    ?.map((x, idx) => `<p key={${`ca_${idx}`} className="contact_address">${x}</p>`)
                    .join('')}
                </div>
                <table class="invoice_table">
                    <thead>
                        ${pdfColumns?.find((x) => x.value === 'date') ? '<th style="width: 80px;">Date</th>' : ''}
                        ${pdfColumns?.find((x) => x.value === 'service') ? '<th style="width: 80px;">Service</th>' : ''}
                        ${
                            pdfColumns?.find((x) => x.value === 'description')
                                ? '<th style="width: 250px;">Description</th>'
                                : ''
                        }
                        ${
                            pdfColumns?.find((x) => x.value === 'time_spent')
                                ? '<th style="width: 80px;">Time Spent</th>'
                                : ''
                        }
                        ${pdfColumns?.find((x) => x.value === 'rate') ? '<th>Rate &pound;</th>' : ''}
                        ${pdfColumns?.find((x) => x.value === 'vat') ? '<th>VAT &pound;</th>' : ''}
                        ${pdfColumns?.find((x) => x.value === 'total') ? '<th>Total &pound;</th>' : ''}
                    </thead>
                    ${model.line_items
                        ?.map(
                            (item) => `<tr>
                        ${pdfColumns?.find((x) => x.value === 'date') ? `<td>${item.time_period}</td>` : ''}
                        ${pdfColumns?.find((x) => x.value === 'service') ? `<td>${item.service_formatted}</td>` : ''}
                        ${pdfColumns?.find((x) => x.value === 'description') ? `<td>${item.description}</td>` : ''}
                        ${pdfColumns?.find((x) => x.value === 'time_spent') ? `<td>${item.time_spent}</td>` : ''}
                        ${pdfColumns?.find((x) => x.value === 'rate') ? `<td>${item.rate_formatted}</td>` : ''}
                        ${pdfColumns?.find((x) => x.value === 'vat') ? `<td>${item.vat}</td>` : ''}
                        ${pdfColumns?.find((x) => x.value === 'total') ? `<td>${item.total}</td>` : ''}
                    </tr>`
                        )
                        .join('')}
                </table>
                <div class="dotted-line"></div>
                <div class="totals">
                    <div class="row subtotal">
                        <p>Subtotal</p>
                        <p>&pound;${model.totals.subtotal}</p>
                    </div>
                    <div class="row vat">
                        <p>VAT</p>
                        <p>&pound;${model.totals.vat}</p>
                    </div>
                    <div class="row total">
                        <p>Total</p>
                        <p>&pound;${model.totals.total}</p>
                    </div>
                </div>
                <div style="margin-top: 120px; margin-bottom: 40px" class="dotted-line"></div>
                <div class="custom-fields">
                ${model.customFields
                    ?.filter((field) => {
                        return (
                            (field.value !== '' && field.value !== null && field.value !== undefined) ||
                            (!field.editable && field.defaultValue)
                        );
                    })
                    ?.map((field) => {
                        if (field.type?.value === 'date' && field.value) {
                            return `<p>${field.label}: ${moment(field.value, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>`;
                        }
                        return `<p>${field.label}: ${field.value || field.defaultValue}</p>`;
                    })
                    .join('')}
                </div>
            </div>
        </body>
    </html>`;

    return html;
};
