/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';

const reloadToRoot = () => {
    if (window.location.href?.includes('magic-links')) return;
    if (window.location.href?.includes('clients/reset-pwd')) return;

    window.location.assign('/');
};

export const interceptor = (store: any) => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            mixpanel.track('http_error', {
                data: error?.response?.data || {},
                status: error?.response?.status || {},
                headers: error?.response?.headers || {},
            });
            return Promise.reject(error);
        }
    );

    axios.interceptors.request.use(
        async (config) =>
            new Promise((resolve) => {
                if (
                    // @ts-ignore
                    (!config.url?.includes(process.env.REACT_APP_TRANSACTIONS_API) &&
                        // @ts-ignore
                        !config.url?.includes(process.env.REACT_APP_AQRU_AI_API)) ||
                    config?.url?.includes('magic-links/claim') ||
                    config?.url?.includes('clients/reset-pwd')
                ) {
                    return resolve(config);
                }

                Auth.currentSession()
                    .then((session) => {
                        const idTokenExpire = session.getIdToken().getExpiration();
                        const refreshToken = session.getRefreshToken();
                        const currentTimeSeconds = Math.round(+new Date() / 1000);

                        if (idTokenExpire < currentTimeSeconds + 30) {
                            Auth.currentAuthenticatedUser()
                                .then((res) => {
                                    res.refreshSession(refreshToken, async (err: any) => {
                                        if (err) {
                                            store && store.dispatch({ type: 'LOGOUT' });
                                            try {
                                                await Auth.signOut();
                                            } catch (e) {
                                                console.log('Failed to call cognito');
                                            }
                                            reloadToRoot();
                                        } else {
                                            config.headers.authorization = session.getAccessToken().getJwtToken();

                                            resolve(config);
                                        }
                                    });
                                })
                                .catch(() => {
                                    reloadToRoot();
                                });
                        } else {
                            config.headers.authorization = session.getAccessToken().getJwtToken();

                            resolve(config);
                        }
                    })
                    .catch(() => {
                        reloadToRoot();
                    });
            })
    );
};
