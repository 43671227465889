/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Paragraph, Table } from 'theme-ui';
import theme from '../../theme';
import { toast } from 'react-toastify';
import { ucase } from '../../utils/string-utils';

const InboundEmailsComponent = ({ client, state, updateState }) => {
    const [emails, setEmails] = useState([]);

    useEffect(() => {
        if (state.view !== 'list') return;
        const fetchEmails = async () => {
            try {
                updateState({ ...state, loading: true });
                const response = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/inbound-emails`, {
                    params: {
                        status: state.statuses?.join(','),
                        ...(client ? { client_id: client?.raw_id } : {}),
                    },
                });
                updateState({ ...state, loading: false });
                setEmails(response.data.emails);
            } catch (error) {
                toast.error('Error fetching emails');
            }
        };
        fetchEmails();
    }, [state.view, state.statuses]);

    const fetchEmailDetails = async (id) => {
        try {
            updateState({ ...state, loading: true });
            const response = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/inbound-emails/${id}`);
            updateState({
                ...state,
                email: response.data,
                loading: false,
                view: 'view',
                newEmailClient: response.data.client
                    ? { value: response.data.client.id, label: response.data.client.name }
                    : null,
            });
        } catch (error) {
            updateState({ ...state, loading: false });
            toast.error('Error fetching email details');
        }
    };

    return (
        <div>
            <table style={{ borderCollapse: 'collapse' }}>
                <thead
                    style={{
                        backgroundColor: theme.colors.primary,
                        color: 'white',
                        cursor: 'pointer',
                        position: 'sticky',
                        top: 0,
                    }}
                >
                    <tr>
                        <th style={{ padding: 20 }}>
                            <Paragraph>Sender</Paragraph>
                        </th>
                        <th style={{ padding: 20 }}>
                            <Paragraph>Subject</Paragraph>
                        </th>
                        <th style={{ padding: 20 }}>
                            <Paragraph>Status</Paragraph>
                        </th>
                        <th style={{ padding: 20 }}>
                            <Paragraph>Date</Paragraph>
                        </th>
                        <th style={{ padding: 20 }}></th>
                    </tr>
                </thead>
                <tbody>
                    {emails.map((email) => (
                        <tr key={email.id} onClick={() => fetchEmailDetails(email.id)}>
                            <td style={{ padding: 20 }}>
                                <Paragraph>{email.from}</Paragraph>
                            </td>
                            <td style={{ padding: 20 }}>
                                <Paragraph>{email.subject}</Paragraph>
                            </td>
                            <td style={{ padding: 20 }}>
                                <Paragraph>{ucase(email.status)}</Paragraph>
                            </td>
                            <td style={{ padding: 20 }}>
                                <Paragraph>{new Date(email.createdAt).toLocaleDateString()}</Paragraph>
                            </td>
                            <td style={{ padding: 20 }}>
                                <Button
                                    onClick={async () => {
                                        await fetchEmailDetails(email.id);
                                    }}
                                    sx={{ width: 120 }}
                                >
                                    <i style={{ marginRight: '7px' }} className="fa fa-folder-open" />
                                    Open
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default InboundEmailsComponent;
