import { Box, Flex } from 'theme-ui';
import themeConfig from '../theme';

export const NotificationsIcon = ({
    avatarSet,
    count,
    onClick,
    customWrapperSx = {},
    countLeft = 83,
    isClientUser,
}) => (
    <Flex
        sx={customWrapperSx}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
        }}
    >
        {count > 0 && (
            <Box
                sx={{
                    padding: '3px 8px 5px 8px',
                    borderRadius: 30,
                    border: `2px solid ${themeConfig.colors.red}`,
                    color: themeConfig.colors.red,
                    top: '8px',
                    right: avatarSet ? 74 : '5px',
                    fontSize: count < 100 ? '14px' : '11px',
                    position: 'absolute',
                    ...(isClientUser
                        ? {
                              top: 12,
                              right: 205,
                          }
                        : {}),
                }}
            >
                {count}
            </Box>
        )}
        <i
            className="fa fa-envelope-open-text"
            style={{
                color: isClientUser ? themeConfig.colors.primaryDarker : 'grey',
                fontSize: 30,
                marginRight: 20,
            }}
        />
    </Flex>
);
