/* eslint-disable no-undef */
import { Button, Flex, Input, Label, Paragraph } from 'theme-ui';
import Divider from '../divider';
import axios from 'axios';
import { useDrag, useDrop } from 'react-dnd';
import * as uuid from 'uuid';
import { useState } from 'react';
import ReactSelect from 'react-select';
import theme from '../../theme';

export const ucase = (str) => (str ? `${str.substring(0, 1).toUpperCase()}${str.substring(1)}` : '');

const attributeTypes = [
    {
        value: 'date',
        label: 'Date',
    },
    {
        value: 'string',
        label: 'Text',
    },
    {
        value: 'number',
        label: 'Number',
    },
    {
        value: 'dropdown',
        label: 'Dropdown',
    },
];

const clientTypes = [
    {
        value: 'all',
        label: 'All',
    },
    {
        value: 'individual',
        label: 'Individuals',
    },
    {
        value: 'company',
        label: 'Companies',
    },
];

const accessRights = [
    {
        value: 'all',
        label: 'All',
    },
    {
        value: 'admin_only',
        label: 'Admin only',
    },
];

const removeAttribute = (id, schema, state, updateState, refreshCoreData) => async () => {
    try {
        updateState({ ...state, loading: true, editError: null, confirmMessage: null });

        const newSchema = { ...schema };
        delete newSchema[id];

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
            method: 'PUT',
            data: {
                schema: newSchema,
            },
        });

        updateState({ ...state, loading: false, editError: null, confirmMessage: 'Success' });

        await refreshCoreData();

        updateLocalState({ ...localState, mode: 'list' });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            editError: 'Uh oh, there has been an error processing this, please try again later or contact support',
            confirmMessage: null,
        });
    }
};

const moveAttribute = async (direction, entry, organisation, updateState, state, refreshCoreData) => {
    try {
        updateState({ ...state, loading: true, editError: null, confirmMessage: null });

        const dataArray = Object.entries(organisation.schema);

        const sortingOrder = organisation?.default_visualisation?.attribute_sorting_order || [];

        dataArray.forEach((x) => {
            if (!sortingOrder.includes(x[0])) {
                sortingOrder.push(x[0]);
            }
        });

        const index = sortingOrder.indexOf(entry[0]);

        // Check if the item is in the array and not already at the start
        if (direction === 'up' && index > 0) {
            [sortingOrder[index - 1], sortingOrder[index]] = [sortingOrder[index], sortingOrder[index - 1]];
        } else if (direction === 'down' && index < sortingOrder.length - 1) {
            [sortingOrder[index], sortingOrder[index + 1]] = [sortingOrder[index + 1], sortingOrder[index]];
        }

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
            method: 'PUT',
            data: {
                attribute_sorting_order: sortingOrder.filter((x) => !!x),
            },
        });

        await refreshCoreData();

        updateState({ ...state, loading: false, editError: null, confirmMessage: 'Success' });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            confirmMessage: null,
        });
    }
};

const updateAttribute = async (
    { key, type, label, client_types, dropdownOptions, access_rights },
    schema,
    state,
    updateState,
    localState,
    updateLocalState,
    refreshCoreData,
    validators
) => {
    try {
        if (!label) {
            return updateLocalState({ ...localState, error: 'Please set a label' });
        }

        if (!type) {
            return updateLocalState({ ...localState, error: 'Please set a type' });
        }

        updateState({ ...state, loading: true, editError: null, confirmMessage: null });
        updateLocalState({ ...localState, error: null });

        const newSchema = { ...schema };

        if (!key) {
            key = label.toLowerCase().replace(/ /g, '_');
            if (newSchema[key]) {
                updateState({
                    ...state,
                    loading: false,
                    confirmMessage: null,
                });
                return updateLocalState({ ...localState, error: 'Field already exists' });
            }
        }

        newSchema[key] = {
            type,
            label,
            client_types: client_types?.[0] === 'all' ? ['company', 'individual'] : client_types,
            validators,
            dropdownOptions,
            access_rights,
        };

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
            method: 'PUT',
            data: {
                schema: newSchema,
            },
        });

        updateState({ ...state, loading: false, editError: null, confirmMessage: 'Success' });

        await refreshCoreData();

        updateLocalState({ ...localState, mode: 'list', error: null });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            confirmMessage: null,
        });
        updateLocalState({ ...localState, error: 'There has been an error, please contact support' });
    }
};

const typeValidators = {
    string: [
        { value: 'min_length', label: 'Min length' },
        { value: 'max_length', label: 'Max length' },
    ],
    number: [
        { value: 'min_value', label: 'Min value' },
        { value: 'max_value', label: 'Max value' },
    ],
};

const EditAttribute = ({ organisation, updateState, state, refreshCoreData, localState, updateLocalState }) => {
    const [editState, updateEditState] = useState({ validators: localState.attributeBeingEdited?.validators || [] });

    return (
        <Flex sx={{ flexDirection: 'column', mt: 20 }}>
            <Button
                sx={{
                    border: '1px solid #ccc',
                    backgroundColor: 'white',
                    color: '#000',
                    fontSize: 14,
                    height: 40,
                    width: 120,
                    mb: 40,
                }}
                onClick={() => {
                    updateLocalState({
                        ...localState,
                        mode: 'list',
                    });
                }}
            >
                <i
                    style={{
                        fontSize: '12px',
                        cursor: 'pointer',
                        color: '#000',
                        marginRight: '7px',
                    }}
                    className="fas fa-chevron-left"
                    aria-hidden="true"
                />
                Back
            </Button>
            <Paragraph sx={{ fontWeight: 600 }}>Edit Attribute</Paragraph>

            <Divider />

            {localState.error && <Paragraph sx={{ mt: 20, color: 'red' }}>{localState.error}</Paragraph>}

            <Label id="attr-label" sx={{ mt: 20 }}>
                Attribute label
            </Label>

            <Input
                aria-labelledby="attr-label"
                sx={{ mt: 10, width: 500 }}
                placeholder="Set the label that will be visible on screen"
                value={localState?.attributeBeingEdited?.label}
                onChange={(e) =>
                    updateLocalState({
                        ...localState,
                        attributeBeingEdited: {
                            ...localState.attributeBeingEdited,
                            label: e.target.value,
                        },
                    })
                }
            />

            <Label id="attr-type" sx={{ mt: 30 }}>
                Attribute type
            </Label>

            {localState.mode === 'edit' && (
                <Paragraph sx={{ mt: 10, fontSize: 14, fontWeight: 600 }}>
                    {localState.attributeBeingEdited.type === 'string'
                        ? 'Text'
                        : ucase(localState.attributeBeingEdited.type)}
                </Paragraph>
            )}

            {localState.mode === 'create' && (
                <ReactSelect
                    aria-labelledby="attr-type"
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            width: '300px',
                            height: '40px',
                            marginTop: 10,
                            borderRadius: 10,
                            marginBottom: '10px',
                            border: '1px solid #a3a69b',
                            fontSize: '15px',
                        }),
                    }}
                    onChange={async (c) => {
                        updateLocalState({
                            ...localState,
                            attributeBeingEdited: {
                                ...localState.attributeBeingEdited,
                                type: c.value,
                            },
                        });
                    }}
                    placeholder={'Set the attribute type'}
                    value={
                        localState?.attributeBeingEdited?.type
                            ? attributeTypes?.find((x) => x.value === localState?.attributeBeingEdited?.type)
                            : null
                    }
                    options={attributeTypes}
                />
            )}

            {localState.attributeBeingEdited.type === 'dropdown' && (
                <Label id="attr-options" sx={{ mt: 20 }}>
                    Options
                </Label>
            )}

            {localState.attributeBeingEdited.type === 'dropdown' &&
                localState.attributeBeingEdited.dropdownOptions?.map((x, idx) => (
                    <Flex key={`attr_dd_${idx}`} sx={{ mt: 20 }}>
                        <Input
                            onChange={(e) => {
                                localState.attributeBeingEdited.dropdownOptions[idx].label = e.target.value;
                                updateLocalState({
                                    ...localState,
                                });
                            }}
                            value={localState.attributeBeingEdited.dropdownOptions[idx].label}
                            type="text"
                            sx={{ padding: '10px', width: 250, height: 40 }}
                        />
                        <Button
                            onClick={() => {
                                localState.attributeBeingEdited.dropdownOptions.splice(idx, 1);
                                updateLocalState({
                                    ...localState,
                                });
                            }}
                            sx={{ height: 40, width: 150, fontSize: 12, mb: 20, ml: 20 }}
                        >
                            Remove option
                        </Button>
                    </Flex>
                ))}

            {localState.attributeBeingEdited.type === 'dropdown' && (
                <Button
                    onClick={() => {
                        updateLocalState({
                            ...localState,
                            attributeBeingEdited: {
                                ...localState.attributeBeingEdited,
                                dropdownOptions: [
                                    ...(localState.attributeBeingEdited.dropdownOptions || []),
                                    {
                                        id: uuid.v4(),
                                        label: '',
                                    },
                                ],
                            },
                        });
                    }}
                    sx={{ height: 40, width: 110, fontSize: 12, mt: 20, mb: 20 }}
                >
                    Add option
                </Button>
            )}

            <Label id="attr-type-client-types" sx={{ mt: 20 }}>
                Applicable client types
            </Label>

            <ReactSelect
                aria-labelledby="attr-type-client-types"
                styles={{
                    control: (provided) => ({
                        ...provided,
                        width: '300px',
                        height: '40px',
                        marginTop: 10,
                        borderRadius: 10,
                        marginBottom: '10px',
                        border: '1px solid #a3a69b',
                        fontSize: '15px',
                    }),
                }}
                onChange={async (c) => {
                    updateLocalState({
                        ...localState,
                        attributeBeingEdited: {
                            ...localState.attributeBeingEdited,
                            client_types: c === 'all' ? ['company', 'individual'] : [c?.value],
                        },
                    });
                }}
                placeholder={'Set the client types'}
                value={
                    localState?.attributeBeingEdited?.client_types?.length > 1
                        ? {
                              value: 'all',
                              label: 'All',
                          }
                        : clientTypes?.find((x) => x.value === localState?.attributeBeingEdited?.client_types?.[0])
                }
                options={clientTypes}
            />

            <Label id="attr-type-access-rights" sx={{ mt: 20 }}>
                Edit Access Rights
            </Label>

            <ReactSelect
                aria-labelledby="attr-type-access-rights"
                styles={{
                    control: (provided) => ({
                        ...provided,
                        width: '300px',
                        height: '40px',
                        marginTop: 10,
                        borderRadius: 10,
                        marginBottom: '10px',
                        border: '1px solid #a3a69b',
                        fontSize: '15px',
                    }),
                }}
                onChange={async (c) => {
                    updateLocalState({
                        ...localState,
                        attributeBeingEdited: {
                            ...localState.attributeBeingEdited,
                            access_rights: c?.value,
                        },
                    });
                }}
                placeholder={'Set the access rights'}
                value={accessRights?.find((x) => x.value === localState?.attributeBeingEdited?.access_rights)}
                options={accessRights}
            />

            {localState.attributeBeingEdited?.type && typeValidators[localState.attributeBeingEdited?.type] && (
                <>
                    <Label id="validators-label" sx={{ mt: 20, mb: 0 }}>
                        Validators
                    </Label>

                    <table
                        style={{ marginTop: 10, width: 600, border: '1px solid lightGrey', borderCollapse: 'collapse' }}
                    >
                        <thead
                            style={{
                                boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
                                backgroundColor: '#F8F8F8',
                                height: 50,
                                fontSize: 14,
                            }}
                        >
                            <th style={{ width: 250, textAlign: 'left', paddingLeft: '10px' }}>Name</th>
                            <th style={{ width: 250, textAlign: 'left', paddingLeft: '10px' }}>Parameter</th>
                            <th></th>
                        </thead>
                        {[...(editState?.validators || [])]?.map((x, idx) => (
                            <tr key={`row_${idx}`}>
                                <td style={{ padding: '10px' }}>
                                    <ReactSelect
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: '200px',
                                                height: '40px',
                                                marginTop: 10,
                                                borderRadius: 10,
                                                marginBottom: '10px',
                                                border: '1px solid #a3a69b',
                                                fontSize: '15px',
                                            }),
                                        }}
                                        value={editState.validators?.[idx]?.name}
                                        onChange={(c) => {
                                            editState.validators[idx].name = c;
                                            updateEditState({ ...editState });
                                        }}
                                        options={typeValidators?.[localState?.attributeBeingEdited?.type]}
                                    />
                                </td>
                                <td style={{ padding: '10px' }}>
                                    <Input
                                        onChange={(e) => {
                                            editState.validators[idx].parameter = e.target.value;
                                            updateEditState({
                                                ...editState,
                                            });
                                        }}
                                        value={x.parameter || ''}
                                        type="number"
                                        sx={{ padding: '10px', width: 150 }}
                                    />
                                </td>
                                <td>
                                    <i
                                        style={{
                                            fontSize: '17px',
                                            cursor: 'pointer',
                                            color: 'red',
                                            marginLeft: 20,
                                        }}
                                        className="fas fa-trash"
                                        aria-hidden="true"
                                        onClick={() => {
                                            const validators = [...editState.validators];
                                            validators.splice(idx);
                                            updateEditState({ ...editState, validators });
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td style={{ padding: 10 }}>
                                <Button
                                    sx={{
                                        height: 40,
                                        color: 'black',
                                        backgroundColor: 'white',
                                        border: '1px solid lightGrey',
                                    }}
                                    onClick={() =>
                                        updateEditState({
                                            ...editState,
                                            validators: [...editState.validators, { name: '', parameter: '' }],
                                        })
                                    }
                                >
                                    Add validator
                                </Button>
                            </td>
                        </tr>
                    </table>
                </>
            )}

            <Button
                sx={{
                    border: '1px solid #ccc',
                    backgroundColor: 'white',
                    color: '#000',
                    fontSize: 14,
                    height: 40,
                    width: 120,
                    mt: 70,
                }}
                onClick={async () => {
                    await updateAttribute(
                        localState.attributeBeingEdited,
                        organisation.schema,
                        state,
                        updateState,
                        localState,
                        updateLocalState,
                        refreshCoreData,
                        editState?.validators?.filter((x) => x.name?.value && x.parameter !== '')
                    );
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
            >
                <i
                    style={{
                        fontSize: '12px',
                        cursor: 'pointer',
                        color: '#000',
                        marginRight: '7px',
                    }}
                    className="fas fa-save"
                    aria-hidden="true"
                />
                Save
            </Button>
        </Flex>
    );
};

export const sortAttributes = (schema, sortingOrder = []) => {
    const dataArray = Object.entries(schema || {});

    dataArray.forEach((x) => {
        if (!sortingOrder.includes(x[0])) {
            sortingOrder.push(x[0]);
        }
    });

    return dataArray.sort(([keyA], [keyB]) => sortingOrder.indexOf(keyA) - sortingOrder.indexOf(keyB));
};

const ClientConfig = ({ organisation, updateState, state, refreshCoreData }) => {
    const [localState, updateLocalState] = useState({ mode: 'list' });

    if (localState.mode === 'edit' || localState.mode === 'create') {
        return (
            <EditAttribute
                organisation={organisation}
                updateState={updateState}
                state={state}
                refreshCoreData={refreshCoreData}
                localState={localState}
                updateLocalState={updateLocalState}
            />
        );
    }

    return (
        <Flex sx={{ flexDirection: 'column' }}>
            <Paragraph sx={{ fontSize: 20, mt: 20, fontWeight: 600 }}>Client Settings</Paragraph>
            <Divider width="1200px" />

            <Paragraph sx={{ mt: 0 }}>Configure the data which can be edited in each client record</Paragraph>

            <Flex sx={{ mt: 40, justifyContent: 'space-between' }}>
                <Paragraph sx={{ color: 'text', fontWeight: 600, mt: 10 }}>Client attributes</Paragraph>
                <Button
                    sx={{
                        border: '1px solid #ccc',
                        backgroundColor: 'white',
                        color: '#000',
                        fontSize: 14,
                        height: 40,
                        width: 180,
                    }}
                    onClick={() => {
                        updateLocalState({
                            ...localState,
                            mode: 'create',
                            attributeBeingEdited: {
                                key: '',
                                type: '',
                                label: '',
                                client_types: ['company', 'individual'],
                            },
                        });
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                >
                    <i
                        style={{
                            fontSize: '12px',
                            cursor: 'pointer',
                            color: '#000',
                            marginRight: '7px',
                        }}
                        className="fas fa-plus-circle"
                        aria-hidden="true"
                    />
                    Add new attribute
                </Button>
            </Flex>

            <Divider />

            <table
                style={{
                    marginTop: 10,
                    width: 1250,
                    borderCollapse: 'collapse',
                    borderRadius: 10,
                    fontSize: 14,
                }}
            >
                <thead style={{ backgroundColor: theme.colors.primary, color: 'white', height: 50 }}>
                    <th style={{ width: 200, textAlign: 'left', paddingLeft: 20 }}>Name</th>
                    <th style={{ width: 100 }}>Type</th>
                    <th style={{ width: 200 }}>Client types</th>
                    <th style={{ width: 50 }}></th>
                    <th style={{ width: 50 }}></th>
                    <th style={{ width: 130 }}></th>
                </thead>
                {sortAttributes(
                    organisation?.schema,
                    organisation?.default_visualisation?.attribute_sorting_order || []
                ).map((entry, idx) => (
                    <AttributeRow
                        key={`row_${idx}`}
                        entry={entry}
                        clientTypes={clientTypes}
                        organisation={organisation}
                        updateState={updateState}
                        state={state}
                        localState={localState}
                        updateLocalState={updateLocalState}
                        refreshCoreData={refreshCoreData}
                    />
                ))}
            </table>
        </Flex>
    );
};

const AttributeRow = ({
    entry,
    clientTypes,
    localState,
    updateLocalState,
    organisation,
    updateState,
    state,
    refreshCoreData,
}) => {
    const [, drop] = useDrop(
        () => ({
            accept: 'client_attribute_row',
            hover(item) {
                updateState({
                    ...state,
                    attributeKeyMovingTo: entry[0],
                    attributeKeyMoving: item.entry[0],
                });
            },
        }),
        [entry, state.attributeKeyMoving, state.attributeKeyMovingTo]
    );

    const [collected, drag, dragPreview] = useDrag(
        () => ({
            type: 'client_attribute_row',
            item: { entry },
            end: async (item, monitor) => {
                const sortingOrder = organisation?.default_visualisation?.attribute_sorting_order || [];
                const currentIndex = sortingOrder.indexOf(state.attributeKeyMoving);
                const newIndex = sortingOrder.indexOf(state.attributeKeyMovingTo);

                if (currentIndex === -1 || newIndex === -1) {
                    return;
                }

                sortingOrder.splice(currentIndex, 1);
                sortingOrder.splice(newIndex, 0, state.attributeKeyMoving);

                try {
                    await axios({
                        url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
                        method: 'PUT',
                        data: {
                            attribute_sorting_order: sortingOrder.filter((x) => !!x && typeof x === 'string'),
                        },
                    });

                    await refreshCoreData();

                    updateState({ ...state, loading: false, editError: null, confirmMessage: 'Success' });
                } catch (e) {
                    console.log(e);
                    updateState({
                        ...state,
                        loading: false,
                        confirmMessage: null,
                    });
                }
            },
        }),
        [entry, state.attributeKeyMoving, state.attributeKeyMovingTo]
    );
    return collected.isDragging ? (
        <tr ref={dragPreview} />
    ) : (
        <tr
            ref={(node) => drag(drop(node))}
            {...collected}
            style={{ boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)' }}
            key={entry[0]}
        >
            <td style={{ textAlign: 'left', padding: 15 }}>{entry[1].label}</td>
            <td style={{ textAlign: 'center', padding: 15 }}>
                {entry[1].type === 'string' ? 'Text' : ucase(entry[1].type)}
            </td>
            <td style={{ textAlign: 'center', padding: 15 }}>
                {entry[1].client_types?.map(
                    (x, idx) => `${idx > 0 ? ', ' : ''}${clientTypes.find((t) => t.value === x)?.label}`
                ) || 'Companies, Individuals'}
            </td>

            <td style={{ textAlign: 'center', padding: 15 }}>
                {organisation?.user?.role === 'admin' && (
                    <i
                        style={{
                            fontSize: '16px',
                            cursor: 'pointer',
                            color: '#000',
                        }}
                        className="fas fa-arrow-up"
                        aria-hidden="true"
                        onClick={async () => {
                            await moveAttribute('up', entry, organisation, updateState, state, refreshCoreData);
                        }}
                    />
                )}
            </td>

            <td style={{ textAlign: 'center', padding: 15 }}>
                {organisation?.user?.role === 'admin' && (
                    <i
                        style={{
                            cursor: 'pointer',
                            color: '#000',
                            fontSize: '16px',
                        }}
                        className="fas fa-arrow-down"
                        aria-hidden="true"
                        onClick={async () => {
                            await moveAttribute('down', entry, organisation, updateState, state, refreshCoreData);
                        }}
                    />
                )}
            </td>

            <td style={{ textAlign: 'center', padding: 15 }}>
                {organisation?.user?.role === 'admin' && (
                    <>
                        <Button
                            sx={{ marginRight: 10 }}
                            onClick={() => {
                                updateLocalState({
                                    ...localState,
                                    mode: 'edit',
                                    attributeBeingEdited: {
                                        key: entry[0],
                                        type: entry[1].type,
                                        label: entry[1].label,
                                        client_types:
                                            entry[1].client_types || clientTypes?.filter((x) => x.value !== 'all'),
                                        validators: entry[1].validators,
                                        dropdownOptions: entry[1].dropdownOptions,
                                        access_rights: entry[1].access_rights,
                                    },
                                });
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            variant="light"
                        >
                            <i
                                style={{
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    color: '#000',
                                    marginRight: '7px',
                                }}
                                className="fas fa-pencil"
                                aria-hidden="true"
                            />
                            Edit
                        </Button>
                        <Button
                            onClick={() =>
                                updateState({
                                    ...state,
                                    showRemoveAttributeConfirmation: true,
                                    confirmCallback: removeAttribute(
                                        entry[0],
                                        organisation?.schema,
                                        state,
                                        updateState,
                                        refreshCoreData
                                    ),
                                })
                            }
                            variant="light"
                        >
                            <i
                                style={{
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    color: 'red',
                                    marginRight: '7px',
                                }}
                                className="fas fa-trash"
                                aria-hidden="true"
                            />
                            Delete
                        </Button>
                    </>
                )}
            </td>
        </tr>
    );
};

export default ClientConfig;
