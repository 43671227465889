/* eslint-disable no-undef */
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Flex, Image, Input, Label, Paragraph, Textarea } from 'theme-ui';
import theme from '../../theme';
import Spinner from '../spinner';
import Divider from '../divider';
import ConfirmDialog from '../tasks/confirm-dialog';
import { getBase64, system_prompt } from '../receipts/list-receipts';

const validateInputs = (inputs) => {
    const errors = {};

    if (!inputs.supplier || inputs.supplier.trim() === '') {
        errors.supplier = 'Supplier is required.';
    }

    if (!inputs.net || isNaN(inputs.net) || inputs.net <= 0) {
        errors.net = 'Net amount must be a positive number.';
    }

    if (inputs.vat && (isNaN(inputs.vat) || inputs.vat < 0)) {
        errors.vat = 'VAT must be a non-negative number.';
    }

    if (!inputs.currency || !['gbp', 'eur', 'usd'].includes(inputs.currency)) {
        errors.currency = 'Currency must be GBP, EUR, or USD.';
    }

    if (!inputs.date_on_receipt || isNaN(new Date(inputs.date_on_receipt).getTime())) {
        errors.date_on_receipt = 'A valid date is required.';
    }

    if (!inputs.category || !['travel', 'food', 'bill'].includes(inputs.category)) {
        errors.category = 'Category is required.';
    }

    return errors;
};

const categories = [
    { value: 'travel', label: 'Travel' },
    { value: 'food', label: 'Food' },
    { value: 'bill', label: 'Bill' },
];

const statuses = [
    { value: 'open', label: 'Open' },
    { value: 'accepted', label: 'Payment pending' },
    { value: 'paid', label: 'Paid' },
    { value: 'rejected', label: 'Rejected' },
];

const currencies = [
    { value: 'gbp', label: 'GBP' },
    { value: 'eur', label: 'Euros' },
    { value: 'usd', label: 'Dollars' },
];

export const saveExpense = async (state, updateState, localState, updateLocalState) => {
    try {
        updateState({ ...state, loading: true });

        if (localState.result.id) {
            await axios({
                method: 'PUT',
                url: `${process.env.REACT_APP_AQRU_AI_API}/expenses/admin/${localState.result.id}`,
                data: {
                    ...localState.result,
                },
            });
        } else {
            await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_AQRU_AI_API}/expenses/admin`,
                data: {
                    client_id: state.clientInView?.raw_id,
                    client_user_id: localState.result?.client_user_id,
                    category: localState.result?.category,
                    currency: localState.result?.currency,
                    net: parseFloat(localState.result?.net),
                    vat: parseFloat(localState.result?.vat || 0),
                    notes: localState.result?.notes || '',
                    invoice_number: localState.result?.invoice_number || '',
                    supplier: localState.result?.supplier || '',
                    date_on_receipt: localState.result?.date_on_receipt,
                    content_type: localState?.content_type,
                    expense_base_64: localState?.image,
                    file_name: localState?.file_name,
                },
            });
        }

        const {
            data: { expenses },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/expenses`,
            params: {
                client_id: state.clientInView.raw_id,
            },
        });

        updateState({ ...state, loading: false });

        updateLocalState({
            ...localState,
            expenses,
        });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
        });
        toast('There has been an error, please contact support', { type: 'error' });
    }
};

export const loadExpenses = async (state, updateState, localState, updateLocalState) => {
    if (!state.clientInView.id) return;

    updateState({ ...state, loading: true });

    try {
        const {
            data: { expenses },
        } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/expenses`, {
            params: {
                client_id: state.clientInView.raw_id,
            },
        });

        const {
            data: { users: clientUsers },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state?.clientInView?.raw_id}/users`,
        });

        updateState({ ...state, loading: false });

        updateLocalState({
            ...localState,
            expenses,
            clientUsers: clientUsers?.map((x) => ({ value: x.id, label: x.first_name + ' ' + x.last_name })),
        });
    } catch (e) {
        updateState({ ...state, loading: false });
        updateLocalState({ ...localState, expenses: [] });
    }
};

const Expenses = ({ state, updateState }) => {
    const [localState, updateLocalState] = useState({ view: 'list', expenses: [] });

    useEffect(() => {
        loadExpenses(state, updateState, localState, updateLocalState);
    }, []);

    useEffect(() => {
        if (localState.view === 'view' && localState.result?.document_uuid && !localState.image) {
            (async () => {
                const {
                    data: { url },
                } = await axios.get(
                    `${process.env.REACT_APP_AQRU_AI_API}/documents/${localState.result.document_uuid}`
                );

                const { data } = await axios.get(url, {
                    responseType: 'blob',
                });

                const blobUrl = URL.createObjectURL(data);

                updateLocalState({
                    ...localState,
                    image: blobUrl,
                });
            })();
        }
    }, [localState.result]);

    if (localState.view === 'result' || localState.view === 'view') {
        return (
            <Flex>
                <Flex sx={{ flexDirection: 'column', mt: 20 }}>
                    <Paragraph sx={{ fontWeight: 600, fontSize: 22 }}>Expense</Paragraph>
                    <Divider />

                    {state.loading ? <Spinner /> : null}

                    <Paragraph sx={{ mt: 10, mb: 10 }}>Client User</Paragraph>
                    <ReactSelect
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '400px',
                                minHeight: '40px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                border: '1px solid #a3a69b',
                                fontSize: '14px',
                            }),
                        }}
                        onChange={(value) => {
                            updateLocalState({
                                ...localState,
                                result: {
                                    ...localState.result,
                                    client_user_id: value.value,
                                },
                            });
                        }}
                        placeholder={'Select the Portal User'}
                        value={
                            localState.result?.client_user_id
                                ? localState.clientUsers?.find((cat) => cat.value === localState.result?.client_user_id)
                                : null
                        }
                        options={localState.clientUsers}
                    />

                    <Paragraph sx={{ mb: 10 }}>Supplier</Paragraph>
                    <Input
                        sx={{ width: 400 }}
                        value={localState.result?.supplier}
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                result: {
                                    ...localState.result,
                                    supplier: e.target.value,
                                },
                            })
                        }
                    />

                    <Paragraph sx={{ mt: 10, mb: 10 }}>Status</Paragraph>
                    <ReactSelect
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '400px',
                                minHeight: '40px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                border: '1px solid #a3a69b',
                                fontSize: '14px',
                            }),
                        }}
                        onChange={(value) => {
                            updateLocalState({
                                ...localState,
                                result: {
                                    ...localState.result,
                                    status: value.value,
                                },
                            });
                        }}
                        placeholder={'Select the status'}
                        value={
                            localState.result?.status
                                ? statuses?.find((cat) => cat.value === localState.result?.status)
                                : null
                        }
                        options={statuses}
                    />

                    <Paragraph sx={{ mt: 10, mb: 10 }}>Currency</Paragraph>
                    <ReactSelect
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '400px',
                                minHeight: '40px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                border: '1px solid #a3a69b',
                                fontSize: '14px',
                            }),
                        }}
                        onChange={(value) => {
                            updateLocalState({
                                ...localState,
                                result: {
                                    ...localState.result,
                                    currency: value.value,
                                },
                            });
                        }}
                        placeholder={'Select the currency'}
                        value={
                            localState.result?.currency
                                ? currencies?.find((cat) => cat.value === localState.result?.currency)
                                : null
                        }
                        options={currencies}
                    />

                    <Paragraph sx={{ mt: 10, mb: 10 }}>Net Price</Paragraph>
                    <Input
                        type="number"
                        sx={{ width: 400 }}
                        value={localState.result?.net}
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                result: {
                                    ...localState.result,
                                    net: e.target.value,
                                },
                            })
                        }
                    />

                    <Paragraph sx={{ mt: 10, mb: 10 }}>VAT</Paragraph>
                    <Input
                        sx={{ width: 400 }}
                        value={localState.result?.vat}
                        type="number"
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                result: {
                                    ...localState.result,
                                    vat: e.target.value,
                                },
                            })
                        }
                    />
                    <Paragraph sx={{ mt: 10, mb: 10 }}>Invoice number</Paragraph>
                    <Input
                        sx={{ width: 400 }}
                        value={localState.result?.invoice_number}
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                result: {
                                    ...localState.result,
                                    invoice_number: e.target.value,
                                },
                            })
                        }
                    />
                    <Paragraph sx={{ mt: 10, mb: 10 }}>Date</Paragraph>
                    <Input
                        sx={{ width: 400 }}
                        value={localState.result?.date_on_receipt}
                        type="date"
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                result: {
                                    ...localState.result,
                                    date_on_receipt: e.target.value,
                                },
                            })
                        }
                    />
                    <Paragraph sx={{ mt: 10, mb: 10 }}>Category</Paragraph>
                    <ReactSelect
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '400px',
                                minHeight: '40px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                border: '1px solid #a3a69b',
                                fontSize: '14px',
                            }),
                        }}
                        onChange={(value) => {
                            updateLocalState({
                                ...localState,
                                result: {
                                    ...localState.result,
                                    category: value.value,
                                },
                            });
                        }}
                        placeholder={'Select the category'}
                        value={
                            localState.result?.category
                                ? categories?.find((cat) => cat.value === localState.result?.category)
                                : null
                        }
                        options={categories}
                    />
                    <Paragraph sx={{ mb: 10 }}>Notes</Paragraph>
                    <Textarea
                        value={localState.result?.notes}
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                result: {
                                    ...localState.result,
                                    notes: e.target.value,
                                },
                            })
                        }
                        sx={{ mb: 10, width: 400, height: 200 }}
                    />
                    <Flex
                        sx={{
                            position: 'fixed',
                            height: 80,
                            background: 'white',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            alignItems: 'center',
                            borderTop: '2px solid #EFEFEF',
                            width: '100%',
                            zIndex: 998,
                        }}
                    >
                        <Button
                            sx={{ width: 100, mr: 10, ml: 20 }}
                            variant="light"
                            onClick={() => {
                                updateLocalState({
                                    ...localState,
                                    view: 'list',
                                    result: null,
                                    image: null,
                                });
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                        >
                            <i style={{ marginRight: '7px' }} className="fa fa-chevron-left" />
                            Back
                        </Button>
                        <Button
                            sx={{ width: 100 }}
                            onClick={async () => {
                                const errors = validateInputs(localState.result || {});

                                if (Object.keys(errors).length > 0) {
                                    Object.values(errors).forEach((error) => toast(error, { type: 'error' }));
                                    return;
                                }

                                await saveExpense(state, updateState, localState, updateLocalState);
                            }}
                        >
                            <i style={{ marginRight: '7px' }} className="fa fa-save" />
                            Save
                        </Button>
                    </Flex>
                </Flex>
                <Image sx={{ ml: 100, maxHeight: 500, mt: 100 }} src={localState.image} />
            </Flex>
        );
    }

    return (
        <Flex sx={{ flexDirection: 'column', mt: 20, ml: 20 }}>
            <Paragraph sx={{ fontWeight: 600, fontSize: 20 }}>Expenses</Paragraph>
            <Divider width={'95%'} />

            {state.loading ? <Spinner /> : null}

            <Label
                sx={{
                    mt: 0,
                    display: 'inline-block',
                    padding: '10px 20px',
                    color: '#000',
                    backgroundColor: '#fff',
                    borderRadius: '15px',
                    cursor: 'pointer',
                    fontSize: '16px',
                    transition: 'background-color 0.3s',
                    width: 300,
                    border: '1px solid #EFEFEF',
                    '&:hover': {
                        backgroundColor: '#EFEFEF',
                        color: '#000',
                    },
                }}
            >
                <i
                    style={{
                        fontSize: '18px',
                        cursor: 'pointer',
                        marginRight: '10px',
                    }}
                    className="fas fa-file-image"
                    aria-hidden="true"
                />
                Upload a receipt
                <Input
                    id="file_input"
                    sx={{
                        display: 'none',
                    }}
                    accept=".png,.jpeg,.pdf"
                    type="file"
                    onChange={async (e) => {
                        try {
                            const file = event.target.files[0];

                            const base64_data = await getBase64(file);

                            updateState({ ...state, loading: true });

                            const {
                                data: { result },
                            } = await axios({
                                url: `${process.env.REACT_APP_AQRU_AI_API}/ai/classify`,
                                method: 'POST',
                                data: {
                                    system_prompt,
                                    base64_data,
                                    content_type: file.type,
                                },
                            });

                            updateState({ ...state, loading: false });

                            updateLocalState({
                                ...localState,
                                view: 'result',
                                result,
                                image: base64_data,
                                file_name: file.name,
                                content_type: file.type,
                            });
                        } catch (e) {
                            updateState({ ...state, loading: false });
                            toast('There has been an error processing your file', { type: 'error' });
                        }
                        document.getElementById('file_input').value = null;
                    }}
                />
            </Label>

            {state.showDeleteExpenseConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showDeleteExpenseConfirmation"
                    text="Are you sure you wish to delete this expense?"
                />
            )}

            <table style={{ borderCollapse: 'collapse', width: 1200, marginTop: 20 }}>
                <thead style={{ backgroundColor: theme.colors.primary, color: 'white' }}>
                    <th style={{ padding: 15 }}>Supplier</th>
                    <th style={{ padding: 15 }}>Net amount</th>
                    <th style={{ padding: 15 }}>VAT</th>
                    <th style={{ padding: 15 }}>Status</th>
                    <th style={{ padding: 15 }}>Date on receipt</th>
                    <th style={{ padding: 15 }}>Created at</th>
                    <th style={{ padding: 15 }}></th>
                </thead>
                {localState.expenses?.map((expense, idx) => (
                    <tr key={`custom_field_${idx}`}>
                        <td style={{ padding: 15, width: 400 }}>
                            <Paragraph sx={{ textAlign: 'center' }}>{expense.supplier}</Paragraph>
                        </td>
                        <td style={{ padding: 15, width: 400 }}>
                            <Paragraph sx={{ textAlign: 'center' }}>
                                {new Intl.NumberFormat('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(expense.net)}
                            </Paragraph>
                        </td>
                        <td style={{ padding: 15, width: 400 }}>
                            <Paragraph sx={{ textAlign: 'center' }}>
                                {' '}
                                {new Intl.NumberFormat('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(expense.vat)}
                            </Paragraph>
                        </td>
                        <td style={{ padding: 15, width: 400 }}>
                            <Paragraph sx={{ textAlign: 'center' }}>
                                {statuses.find((x) => x.value === expense.status)?.label}
                            </Paragraph>
                        </td>
                        <td style={{ padding: 15, width: 400 }}>
                            <Paragraph sx={{ textAlign: 'center' }}>
                                {moment(expense.date_on_receipt).format('DD/MM/YYYY')}
                            </Paragraph>
                        </td>
                        <td style={{ padding: 15, width: 400 }}>
                            <Paragraph sx={{ textAlign: 'center' }}>
                                {moment(expense.created_at).format('HH:mm DD/MM/YYYY')}
                            </Paragraph>
                        </td>
                        <td style={{ padding: 15, width: 400 }}>
                            <Button
                                variant="light"
                                onClick={() => {
                                    updateLocalState({ ...localState, view: 'view', result: { ...expense } });
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }}
                            >
                                <i
                                    style={{
                                        fontSize: '17px',
                                        cursor: 'pointer',
                                        marginRight: '7px',
                                    }}
                                    className="fas fa-pencil"
                                    aria-hidden="true"
                                />
                                View
                            </Button>
                        </td>
                    </tr>
                ))}
            </table>
        </Flex>
    );
};

export default Expenses;
