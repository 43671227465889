import React, { useEffect, useRef, useState } from 'react';
import { Button, Flex } from 'theme-ui';

const SignatureCanvas = ({
    drawingComplete,
    width,
    height,
    containerSx,
    defaultValue,
    id,
    signatureSx,
    disableInteraction,
}) => {
    const canvasRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);

    const getTouchPos = (touchEvent) => {
        const rect = canvasRef.current.getBoundingClientRect();
        const touch = touchEvent.touches[0];
        return {
            offsetX: touch.clientX - rect.left,
            offsetY: touch.clientY - rect.top,
        };
    };

    const startDrawing = (event) => {
        let offsetX, offsetY;
        if (event.nativeEvent.type === 'mousedown') {
            ({ offsetX, offsetY } = event.nativeEvent);
        } else if (event.nativeEvent.type === 'touchstart') {
            ({ offsetX, offsetY } = getTouchPos(event.nativeEvent));
        }
        const ctx = canvasRef.current.getContext('2d');
        ctx.beginPath();
        ctx.moveTo(offsetX, offsetY);
        setIsDrawing(true);
    };

    const draw = (event) => {
        if (!isDrawing) {
            return;
        }
        let offsetX, offsetY;
        if (event.nativeEvent.type === 'mousemove') {
            ({ offsetX, offsetY } = event.nativeEvent);
        } else if (event.nativeEvent.type === 'touchmove') {
            ({ offsetX, offsetY } = getTouchPos(event.nativeEvent));
        }
        const ctx = canvasRef.current.getContext('2d');
        ctx.lineTo(offsetX, offsetY);
        ctx.stroke();
    };

    const finishDrawing = async () => {
        if (!isDrawing) return;
        const ctx = canvasRef.current.getContext('2d');
        ctx.closePath();
        setIsDrawing(false);
        if (drawingComplete) {
            await new Promise((resolve) => setTimeout(resolve, 400));
            drawingComplete({
                base64: canvasRef.current.toDataURL('image/png'),
            });
        }
    };

    useEffect(() => {
        const canvas = document.getElementById(id);

        if (!canvas) return;

        const context = canvas.getContext('2d');

        if (!defaultValue) {
            context.clearRect(0, 0, canvas.width, canvas.height);
            return;
        }

        const image = new Image();

        image.src = defaultValue;

        image.onload = () => {
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
        };
    }, [defaultValue]);

    return (
        <Flex sx={containerSx || {}}>
            <canvas
                id={id}
                defaultValue={defaultValue}
                style={{ border: '1px solid #CCC', backgroundColor: 'white', ...(signatureSx || {}) }}
                ref={canvasRef}
                width={width || '500'}
                height={height || '200'}
                onMouseDown={startDrawing}
                onMouseUp={finishDrawing}
                onMouseMove={(e) => !disableInteraction && draw(e)}
                onMouseLeave={finishDrawing}
                onTouchStart={startDrawing}
                onTouchEnd={finishDrawing}
                onTouchMove={(e) => !disableInteraction && draw(e)}
            />
            {!disableInteraction && (
                <Button
                    sx={{
                        border: '1px solid #EFEFEF',
                        height: 30,
                        width: 60,
                        ml: 10,
                        color: '#000',
                        fontSize: 12,
                        backgroundColor: '#FFF',
                    }}
                    onClick={() => {
                        const canvas = canvasRef.current;
                        const ctx = canvas.getContext('2d');
                        ctx.clearRect(0, 0, canvas.width, canvas.height);
                        if (drawingComplete) {
                            drawingComplete({
                                base64: '',
                            });
                        }
                    }}
                >
                    Clear
                </Button>
            )}
        </Flex>
    );
};

export default SignatureCanvas;
