/* eslint-disable no-undef */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Flex, Image, Paragraph, Spinner } from 'theme-ui';
import { SetNewPassword } from './log-in';
import { Auth } from 'aws-amplify';
import * as jwt_decode from 'jwt-decode';
import Waves from './waves';

export const setupAmplifyViaTheBackdoor = (data) => {
    const { sub: username, client_id } = jwt_decode.jwtDecode(data.accessToken);

    localStorage.setItem('CognitoIdentityServiceProvider.' + client_id + '.LastAuthUser', username);
    localStorage.setItem('CognitoIdentityServiceProvider.' + client_id + '.' + username + '.idToken', data.idToken);
    localStorage.setItem(
        'CognitoIdentityServiceProvider.' + client_id + '.' + username + '.accessToken',
        data.accessToken
    );
    localStorage.setItem(
        'CognitoIdentityServiceProvider.' + client_id + '.' + username + '.refreshToken',
        data.refreshToken
    );
    localStorage.setItem('CognitoIdentityServiceProvider.' + client_id + '.' + username + '.clockDrift', 0);
};

const tryAndSignIn = async (id, state, updateState) => {
    try {
        updateState({ ...state, loading: true, error: null });

        try {
            await Auth.signOut();
        } catch (ex) {
            // swallow
        }

        const { data } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/magic-links/claim`,
            method: 'POST',
            data: {
                uuid: id,
            },
        });

        setupAmplifyViaTheBackdoor(data);

        updateState({ ...state, loading: false, error: null, keys: data });
    } catch (e) {
        updateState({ ...state, loading: false, error: 'We are not currently able to log you in' });
    }
};

const MagicLinks = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [state, updateState] = useState({ useOurApiToSetPassword: true, loading: true });

    useEffect(() => {
        (async () => {
            await tryAndSignIn(id, state, updateState);
        })();
    }, []);

    return (
        <Flex
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                minHeight: '100vh',
                background: 'primary',
                backgroundSize: 'cover',
            }}
        >
            {state.loading && <Spinner />}

            <Waves />

            <Flex
                sx={{
                    width: ['100%', '600px'],
                    minHeight: '500px',
                    flexDirection: 'column',
                    background: 'white',
                    p: '50px',
                }}
            >
                <Box sx={{ margin: '0px auto 30px' }}>
                    <Image
                        src={`https://cdn.accountingflow.io/af/logo-dark.png`}
                        width="260px"
                        height="auto"
                        alt="aqru ai logo"
                        data-testid="login-logo"
                    />
                </Box>

                {state.error && (
                    <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
                        <Paragraph sx={{ textAlign: 'center', color: 'red' }}>{state.error}</Paragraph>
                        <Button onClick={() => navigate('/')} sx={{ mt: 30, width: 200 }}>
                            Back to Login
                        </Button>
                    </Flex>
                )}

                {state.keys && <SetNewPassword currentState={state} updateState={updateState} navigate={navigate} />}
            </Flex>
        </Flex>
    );
};

export default MagicLinks;
