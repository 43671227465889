/** @jsxImportSource theme-ui */
import { toast } from 'react-toastify';
import { Checkbox, Flex, Input, Paragraph, Select } from 'theme-ui';

export const validateOptions = (localState) => {
    const keys = [];

    if (localState.invoice_interval === 'select' || !localState.invoice_interval) {
        keys.push('invoice_interval');
    }

    if (localState.invoice_interval === 'weekly') {
        if (localState.invoice_weekly_on === 'select' || !localState.invoice_weekly_on) {
            keys.push('invoice_weekly_on');
        }
    }

    if (
        localState.invoice_interval === 'monthly' &&
        ['first', 'second', 'third', 'fourth', 'last'].includes(localState.invoice_day)
    ) {
        if (localState.invoice_monthly_on === 'select' || !localState.invoice_monthly_on) {
            keys.push('invoice_monthly_on');
        }
    }

    if (localState.invoice_interval === 'yearly') {
        if (localState.invoice_day_of_month === 'select' || !localState.invoice_day_of_month) {
            keys.push('invoice_day_of_month');
        }
        if (localState.invoice_month_of_year === 'select' || !localState.invoice_month_of_year) {
            keys.push('invoice_month_of_year');
        }
    }

    if (
        localState.invoice_interval === 'daily' ||
        localState.invoice_interval === 'weekly' ||
        localState.invoice_interval === 'monthly'
    ) {
        if (!localState.invoice_interval_every || parseInt(localState.invoice_interval_every, 10) < 1) {
            keys.push('invoice_interval_every');
        }
    }

    if (localState.invoice_interval === 'monthly') {
        if (localState.invoice_day === 'select' || !localState.invoice_day) {
            keys.push('invoice_day');
        }
        if (localState.invoice_day === 'day') {
            if (localState.invoice_day_of_month === 'select' || !localState.invoice_day_of_month) {
                keys.push('invoice_day_of_month');
            }
        }
    }

    if (!localState.invoice_recurring_start_date) {
        keys.push('invoice_recurring_start_date');
    }

    if (!localState.invoice_recurring_end || localState.invoice_recurring_end === 'select') {
        keys.push('invoice_recurring_end');
    }

    if (['by', 'after'].includes(localState.invoice_recurring_end) && !localState.invoice_recurring_end_date) {
        keys.push('invoice_recurring_end_date');
    }

    if (keys.length) {
        toast.error('Please complete the fields in red');
    }

    return { result: keys.length === 0, keys };
};

const InvoiceScheduler = ({ updateLocalState, localState }) => {
    let pluralLabel = '';
    if (localState.invoice_interval === 'daily') {
        pluralLabel = 'day(s)';
    }
    if (localState.invoice_interval === 'weekly') {
        pluralLabel = 'week(s)';
    }
    if (localState.invoice_interval === 'monthly') {
        pluralLabel = 'month(s)';
    }
    return (
        <Flex
            sx={{
                flexDirection: 'column',
                mt: 20,
            }}
        >
            <Flex sx={{ alignItems: 'center', mb: 0 }}>
                <Paragraph>Create</Paragraph>
                <Input
                    type="number"
                    value={localState.invoice_days_in_advance}
                    onChange={(e) =>
                        updateLocalState({
                            ...localState,
                            invoice_days_in_advance: e.target.value === '' ? '' : Number(e.target.value),
                        })
                    }
                    sx={{
                        width: '60px',
                        marginLeft: 2,
                        marginRight: 2,
                        textAlign: 'center',
                        height: 50,
                    }}
                />
                <Paragraph>days in advance</Paragraph>
            </Flex>

            <Flex sx={{ mt: 10, mb: 10, flexDirection: 'column' }}>
                <Paragraph>Interval: </Paragraph>
                <Select
                    defaultValue="select"
                    value={localState.invoice_interval}
                    onChange={(e) =>
                        updateLocalState({
                            ...localState,
                            invoice_interval: e.target.value,
                        })
                    }
                    sx={{
                        mt: '5px',
                        width: 200,
                        border: `1px solid ${localState.errorKeys?.includes('invoice_interval') ? 'red' : '#ccc'}`,
                    }}
                >
                    <option value="select">Select</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                </Select>
            </Flex>

            {/* Conditional Inputs for Interval */}
            {localState.invoice_interval === 'monthly' && (
                <Flex sx={{ marginBottom: 10, flexDirection: 'column' }}>
                    <Paragraph sx={{ mb: '5px' }}>On: </Paragraph>
                    <Select
                        defaultValue={'select'}
                        value={localState.invoice_day}
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                invoice_day: e.target.value,
                            })
                        }
                        sx={{
                            width: 200,
                            border: `1px solid ${localState.errorKeys?.includes('invoice_day') ? 'red' : '#ccc'}`,
                        }}
                    >
                        <option value="select">Select</option>
                        <option value="day">day</option>
                        <option value="first">first</option>
                        <option value="second">second</option>
                        <option value="third">third</option>
                        <option value="fourth">fourth</option>
                        <option value="last">last</option>
                        <option value="last_day">last day</option>
                        <option value="last_working_day">last working day</option>
                    </Select>

                    {localState.invoice_day === 'day' ? (
                        <>
                            <Paragraph sx={{ mb: '5px', mt: 10 }}>Day of month: </Paragraph>
                            <Select
                                defaultValue={'select'}
                                value={localState.invoice_day_of_month}
                                onChange={(e) =>
                                    updateLocalState({
                                        ...localState,
                                        invoice_day_of_month: e.target.value,
                                    })
                                }
                                sx={{
                                    width: 200,
                                    border: `1px solid ${
                                        localState.errorKeys?.includes('invoice_day_of_month') ? 'red' : '#ccc'
                                    }`,
                                }}
                            >
                                <option value="select">Select</option>
                                <option value="1">1st</option>
                                <option value="2">2nd</option>
                                <option value="3">3rd</option>
                                <option value="4">4th</option>
                                <option value="5">5th</option>
                                <option value="6">6th</option>
                                <option value="7">7th</option>
                                <option value="8">8th</option>
                                <option value="9">9th</option>
                                <option value="10">10th</option>
                                <option value="11">11th</option>
                                <option value="12">12th</option>
                                <option value="13">13th</option>
                                <option value="14">14th</option>
                                <option value="15">15th</option>
                                <option value="16">16th</option>
                                <option value="17">17th</option>
                                <option value="18">18th</option>
                                <option value="19">19th</option>
                                <option value="20">20th</option>
                                <option value="21">21st</option>
                                <option value="22">22nd</option>
                                <option value="23">23rd</option>
                                <option value="24">24th</option>
                                <option value="25">25th</option>
                                <option value="26">26th</option>
                                <option value="27">27th</option>
                                <option value="28">28th</option>
                            </Select>
                        </>
                    ) : null}
                </Flex>
            )}

            {localState.invoice_interval === 'monthly' &&
                ['first', 'second', 'third', 'fourth', 'last'].includes(localState.invoice_day) && (
                    <Flex sx={{ marginBottom: 10, flexDirection: 'column' }}>
                        <Paragraph sx={{ mb: '5px' }}>Day: </Paragraph>
                        <Select
                            defaultValue={'select'}
                            value={localState.invoice_monthly_on}
                            onChange={(e) =>
                                updateLocalState({
                                    ...localState,
                                    invoice_monthly_on: e.target.value,
                                })
                            }
                            sx={{
                                width: 200,
                                border: `1px solid ${
                                    localState.errorKeys?.includes('invoice_monthly_on') ? 'red' : '#ccc'
                                }`,
                            }}
                        >
                            <option value="select">Select</option>
                            <option value="7">Sunday</option>
                            <option value="1">Monday</option>
                            <option value="2">Tuesday</option>
                            <option value="3">Wednesday</option>
                            <option value="4">Thursday</option>
                            <option value="5">Friday</option>
                            <option value="6">Saturday</option>
                        </Select>
                    </Flex>
                )}

            {localState.invoice_interval === 'daily' ||
            localState.invoice_interval === 'weekly' ||
            localState.invoice_interval === 'monthly' ? (
                <Flex sx={{ alignItems: 'center', mb: 10 }}>
                    <Paragraph>Every</Paragraph>
                    <Input
                        type="number"
                        value={localState.invoice_interval_every}
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                invoice_interval_every: e.target.value === '' ? '' : Number(e.target.value),
                            })
                        }
                        sx={{
                            width: '60px',
                            marginLeft: 2,
                            marginRight: 2,
                            textAlign: 'center',
                            height: 50,
                            border: `1px solid ${
                                localState.errorKeys?.includes('invoice_interval_every') ? 'red' : '#ccc'
                            }`,
                        }}
                    />
                    <Paragraph>{pluralLabel}</Paragraph>
                </Flex>
            ) : null}

            {localState.invoice_interval === 'weekly' && (
                <Flex sx={{ marginBottom: 10, flexDirection: 'column' }}>
                    <Paragraph sx={{ mb: '5px' }}>On: </Paragraph>
                    <Select
                        defaultValue={'select'}
                        value={localState.invoice_weekly_on}
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                invoice_weekly_on: e.target.value,
                            })
                        }
                        sx={{
                            width: 200,
                            border: `1px solid ${localState.errorKeys?.includes('invoice_weekly_on') ? 'red' : '#ccc'}`,
                        }}
                    >
                        <option value="select">Select</option>
                        <option value="7">Sunday</option>
                        <option value="1">Monday</option>
                        <option value="2">Tuesday</option>
                        <option value="3">Wednesday</option>
                        <option value="4">Thursday</option>
                        <option value="5">Friday</option>
                        <option value="6">Saturday</option>
                    </Select>
                </Flex>
            )}

            {localState.invoice_interval === 'yearly' ? (
                <Flex sx={{ marginBottom: 10, flexDirection: 'row' }}>
                    <Flex sx={{ flexDirection: 'column' }}>
                        <Paragraph sx={{ mb: '5px', mt: 10 }}>Day of month: </Paragraph>
                        <Select
                            defaultValue={'select'}
                            value={localState.invoice_day_of_month}
                            onChange={(e) =>
                                updateLocalState({
                                    ...localState,
                                    invoice_day_of_month: e.target.value,
                                })
                            }
                            sx={{
                                width: 200,
                                border: `1px solid ${
                                    localState.errorKeys?.includes('invoice_day_of_month') ? 'red' : '#ccc'
                                }`,
                            }}
                        >
                            <option value="select">Select</option>
                            <option value="1">1st</option>
                            <option value="2">2nd</option>
                            <option value="3">3rd</option>
                            <option value="4">4th</option>
                            <option value="5">5th</option>
                            <option value="6">6th</option>
                            <option value="7">7th</option>
                            <option value="8">8th</option>
                            <option value="9">9th</option>
                            <option value="10">10th</option>
                            <option value="11">11th</option>
                            <option value="12">12th</option>
                            <option value="13">13th</option>
                            <option value="14">14th</option>
                            <option value="15">15th</option>
                            <option value="16">16th</option>
                            <option value="17">17th</option>
                            <option value="18">18th</option>
                            <option value="19">19th</option>
                            <option value="20">20th</option>
                            <option value="21">21st</option>
                            <option value="22">22nd</option>
                            <option value="23">23rd</option>
                            <option value="24">24th</option>
                            <option value="25">25th</option>
                            <option value="26">26th</option>
                            <option value="27">27th</option>
                            <option value="28">28th</option>
                        </Select>
                    </Flex>
                    <Flex sx={{ flexDirection: 'column', ml: 20 }}>
                        <Paragraph sx={{ mb: '5px', mt: 10 }}>Month: </Paragraph>
                        <Select
                            value={localState.invoice_month_of_year}
                            defaultValue={'select'}
                            onChange={(e) =>
                                updateLocalState({
                                    ...localState,
                                    invoice_month_of_year: e.target.value,
                                })
                            }
                            sx={{
                                width: 200,
                                border: `1px solid ${
                                    localState.errorKeys?.includes('invoice_month_of_year') ? 'red' : '#ccc'
                                }`,
                            }}
                        >
                            <option value="select">Select</option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </Select>
                    </Flex>
                </Flex>
            ) : null}

            <Flex sx={{ flexDirection: 'column' }}>
                <Paragraph sx={{ mb: '5px', mt: 10 }}>Start date</Paragraph>
                <Input
                    type="date"
                    value={localState.invoice_recurring_start_date}
                    onChange={(e) =>
                        updateLocalState({
                            ...localState,
                            invoice_recurring_start_date: e.target.value,
                        })
                    }
                    sx={{
                        width: 200,
                        height: 50,
                        border: `1px solid ${
                            localState.errorKeys?.includes('invoice_recurring_start_date') ? 'red' : '#ccc'
                        }`,
                    }}
                />
            </Flex>

            <Flex sx={{ flexDirection: 'column', mb: 10 }}>
                <Paragraph sx={{ mb: '5px', mt: 10 }}>End</Paragraph>
                <Select
                    value={localState.invoice_recurring_end}
                    onChange={(e) =>
                        updateLocalState({
                            ...localState,
                            invoice_recurring_end: e.target.value,
                        })
                    }
                    sx={{
                        width: 200,
                        border: `1px solid ${localState.errorKeys?.includes('invoice_recurring_end') ? 'red' : '#ccc'}`,
                    }}
                    defaultValue={'select'}
                >
                    <option value="select">Select</option>
                    <option value="none">none</option>
                    <option value="by">by</option>
                    <option value="after">after</option>
                </Select>

                {['by', 'after'].includes(localState.invoice_recurring_end) ? (
                    <Input
                        type="date"
                        value={localState.invoice_recurring_end_date}
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                invoice_recurring_end_date: e.target.value,
                            })
                        }
                        sx={{
                            width: 200,
                            height: 50,
                            mt: 10,
                            border: `1px solid ${
                                localState.errorKeys?.includes('invoice_recurring_end_date') ? 'red' : '#ccc'
                            }`,
                        }}
                    />
                ) : null}
            </Flex>

            <Flex
                onClick={(e) =>
                    updateLocalState({
                        ...localState,
                        auto_send_invoice_emails: !localState.auto_send_invoice_emails,
                    })
                }
                sx={{ mt: 20, alignItems: 'center', cursor: 'pointer' }}
            >
                <Paragraph sx={{ width: 300 }}>Automatically send emails</Paragraph>
                <Checkbox checked={localState.auto_send_invoice_emails} sx={{ ml: -100 }} />
            </Flex>
        </Flex>
    );
};

export default InvoiceScheduler;
