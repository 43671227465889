/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-undef */
import axios from 'axios';
import ReactSelect from 'react-select';
import { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Flex, Heading, Input, Label, Paragraph, Select, Switch } from 'theme-ui';
import Overlay from '../overlay/overlay';
import Spinner from '../spinner';
import { ucase } from '../settings/client-config';
import Divider from '../divider';
import ViewAnswers from '../portal/view-answers';
import { ConfigureQuestionnaire, attributeTypes, lookupAttributeOptions } from '../../pages/questionnaires';
import moment from 'moment';
import { getContactsFilteredByThoseUsingClientPortal } from './contact-utils';
import ConfirmDialog from '../tasks/confirm-dialog';
import { toast } from 'react-toastify';
import theme from '../../theme';

const lookupOfficers = async (state, updateState) => {
    try {
        updateState({ ...state, loading: true });

        const { data } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientId}/officers`,
        });

        const officersFormatted = data.officers.map((x) => {
            const data = { ...x };
            const [surname, ...rest] = x.name.split(',');

            data.firstName = rest.join(' ')?.trim();
            data.lastName = ucase(surname.toLowerCase())?.trim();

            return data;
        });

        updateState({
            ...state,
            loading: false,
            officersRaw: officersFormatted,
            officers: officersFormatted?.map((item, idx) => ({
                value: idx,
                label: `${item.firstName} ${item.lastName} - ${item.officerRole}`,
            })),
        });
    } catch (e) {
        updateState({
            ...state,
            officersRaw: [],
            officers: [],
            loading: false,
        });
    }
};

const LookupOfficersModal = ({ state, updateState, copyIntoForm }) => {
    useEffect(() => {
        lookupOfficers(state, updateState);
    }, []);

    return (
        <Flex sx={{ ml: 20, mt: 25, flexDirection: 'column' }}>
            {state.loading && <Spinner />}
            <Paragraph sx={{ fontWeight: 600 }}>Officers stored in Companies House</Paragraph>
            <ReactSelect
                styles={{
                    control: (provided) => ({
                        ...provided,
                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                        width: '400px',
                        minHeight: '40px',
                        marginTop: '10px',
                        borderRadius: 10,
                        marginBottom: '10px',
                        border: '1px solid #a3a69b',
                        fontSize: '14px',
                    }),
                }}
                onChange={(c) => {
                    updateState({
                        ...state,
                        officerSelected: c,
                        officerRecord: {
                            ...state.officersRaw?.[c?.value],
                            address: {
                                line1: state.officersRaw?.[c?.value]?.address?.addressLine1,
                                city: state.officersRaw?.[c?.value]?.address?.locality,
                                postcode: state.officersRaw?.[c?.value]?.address?.postalCode,
                            },
                        },
                    });
                }}
                placeholder="Select officer"
                value={state?.officerSelected}
                options={state?.officers || []}
            />

            <Divider sx={{ mr: 20 }} width="97%" />

            {state.officerSelected && (
                <Flex sx={{ flexDirection: 'column', mt: 30 }}>
                    <Flex sx={{ alignItems: 'flex-start', mb: 20, flexDirection: 'column' }}>
                        <Label id="first-name-label" sx={{ width: 150, mb: 10 }}>
                            First Name
                        </Label>
                        <Input
                            sx={{ width: 400 }}
                            aria-labelledby="first-name-label"
                            placeholder="Contact first name"
                            value={state.officerRecord?.firstName}
                            onChange={(e) =>
                                updateState({
                                    ...state,
                                    officerRecord: { ...state.officerRecord, firstName: e.target.value },
                                })
                            }
                        />
                    </Flex>

                    <Flex sx={{ alignItems: 'flex-start', mb: 20, flexDirection: 'column' }}>
                        <Label id="last-name-label" sx={{ width: 150, mb: 10 }}>
                            Last Name
                        </Label>
                        <Input
                            sx={{ width: 400 }}
                            aria-labelledby="last-name-label"
                            placeholder="Contact last name"
                            value={state.officerRecord?.lastName}
                            onChange={(e) =>
                                updateState({
                                    ...state,
                                    officerRecord: { ...state.officerRecord, lastName: e.target.value },
                                })
                            }
                        />
                    </Flex>

                    <Flex sx={{ alignItems: 'flex-start', flexDirection: 'column', mb: 20 }}>
                        <Label id="addr-label" sx={{ width: 150, mt: 10, mb: 10 }}>
                            Address
                        </Label>
                        <Flex sx={{ flexDirection: 'column' }}>
                            <Input
                                sx={{ width: 400 }}
                                aria-labelledby="addr-label"
                                placeholder="First line of the address"
                                value={state.officerRecord?.address?.line1}
                                onChange={(e) =>
                                    updateState({
                                        ...state,
                                        officerRecord: {
                                            ...state.officerRecord,
                                            address: {
                                                ...(state.officerRecord?.address || {}),
                                                line1: e.target.value,
                                            },
                                        },
                                    })
                                }
                            />
                            <Input
                                sx={{ width: 400, mt: 10 }}
                                aria-labelledby="addr-label"
                                placeholder="City"
                                value={state.officerRecord?.address?.city}
                                onChange={(e) =>
                                    updateState({
                                        ...state,
                                        officerRecord: {
                                            ...state.officerRecord,
                                            address: {
                                                ...(state.officerRecord?.address || {}),
                                                city: e.target.value,
                                            },
                                        },
                                    })
                                }
                            />
                            <Input
                                sx={{ width: 400, mt: 10 }}
                                aria-labelledby="addr-label"
                                placeholder="Postal code"
                                value={state.officerRecord?.address?.postcode}
                                onChange={(e) =>
                                    updateState({
                                        ...state,
                                        officerRecord: {
                                            ...state.officerRecord,
                                            address: {
                                                ...(state.officerRecord?.address || {}),
                                                postcode: e.target.value,
                                            },
                                        },
                                    })
                                }
                            />
                        </Flex>
                    </Flex>

                    <Button
                        onClick={() => {
                            copyIntoForm({
                                first_name: state.officerRecord?.firstName,
                                last_name: state.officerRecord?.lastName,
                                address: state.officerRecord?.address,
                            });
                        }}
                        sx={{ mt: 50, height: 40, width: 200 }}
                    >
                        Copy Details
                    </Button>
                </Flex>
            )}
        </Flex>
    );
};

const deleteContact = async (idx, state, updateState, localState, updateLocalState) => {
    try {
        updateState({ ...state, loading: true });
        updateLocalState({ ...localState, error: null });

        const newContactsList = state?.clientInView?.client_data?.key_contacts || [];

        const emailOfContactToEnsureDeleted = newContactsList[idx]?.email_address?.toLowerCase()?.trim();

        newContactsList.splice(idx, 1);

        await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView?.id}`,
            data: {
                client_data: {
                    ...(state.clientInView?.client_data || {}),
                    key_contacts: newContactsList,
                },
                email_to_unlink: emailOfContactToEnsureDeleted,
            },
        });

        updateState({
            ...state,
            loading: false,
            clientInView: {
                ...state.clientInView,
                client_data: {
                    ...(state.clientInView?.client_data || {}),
                    key_contacts: newContactsList,
                },
            },
        });

        updateLocalState({
            ...localState,
            keyContactsView: 'list',
        });
    } catch (e) {
        updateState({
            ...state,
            loading: false,
        });

        updateLocalState({
            ...localState,
            error: 'There has been an error saving your data',
        });
    }
};

const validateEmail = (email) =>
    email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

const sendRegistrationInvite = async (state, updateState, localState, updateLocalState) => {
    try {
        if (
            !localState.loadedFromServer &&
            state.clientUsers?.find((clientUser) => clientUser.email_address === localState.email_address)
        ) {
            return updateLocalState({
                ...localState,
                error: 'Email is already assigned to another contact for this client',
            });
        }

        updateState({
            ...state,
            loading: true,
        });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state?.clientInView?.raw_id}/users`,
            method: 'POST',
            data: {
                first_name: localState.first_name,
                last_name: localState.last_name,
                email_address: localState.email_address?.toLowerCase()?.trim(),
            },
        });

        const {
            data: { users: clientUsers },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state?.clientInView?.raw_id}/users`,
        });

        updateLocalState({
            ...localState,
            successMessage: 'Invite sent',
            error: null,
        });

        setTimeout(() => {
            updateLocalState({
                ...localState,
                successMessage: null,
                error: null,
            });
        }, 2000);

        updateState({
            ...state,
            clientUsers: clientUsers.map((x) => ({ ...x, loadedFromServer: true })),
            loading: false,
        });
    } catch (e) {
        updateState({
            ...state,
            loading: false,
        });

        updateLocalState({
            ...localState,
            successMessage: null,
            error: 'There has been an error sending the invite',
        });
    }
};

const disablePortalUsage = async (state, updateState, localState, updateLocalState) => {
    try {
        updateState({
            ...state,
            loading: true,
        });

        const userId = state.clientUsers?.find((x) => x.email_address === localState.email_address)?.user_id;

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state?.clientInView?.raw_id}/users/${userId}`,
            method: 'DELETE',
        });

        const {
            data: { users: clientUsers },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state?.clientInView?.raw_id}/users`,
        });

        updateLocalState({
            ...localState,
            successMessage: 'User account disabled',
            error: null,
        });

        setTimeout(() => {
            updateLocalState({
                ...localState,
                successMessage: null,
                error: null,
            });
        }, 2000);

        updateState({
            ...state,
            clientUsers: clientUsers.map((x) => ({ ...x, loadedFromServer: true })),
            loading: false,
        });
    } catch (e) {
        updateState({
            ...state,
            loading: false,
        });

        updateLocalState({
            ...localState,
            successMessage: null,
            error: 'There has been an error disabling the account',
        });
    }
};

const resetUserPassword = async (state, updateState, localState, updateLocalState) => {
    try {
        updateState({
            ...state,
            loading: true,
        });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/reset-user-pwd`,
            method: 'POST',
            data: {
                email_address: localState.email_address,
            },
        });

        updateLocalState({
            ...localState,
            successMessage: 'Password reset email sent to user',
            error: null,
        });

        setTimeout(() => {
            updateLocalState({
                ...localState,
                successMessage: null,
                error: null,
            });
        }, 2000);

        updateState({
            ...state,
            loading: false,
        });
    } catch (e) {
        updateState({
            ...state,
            loading: false,
        });

        updateLocalState({
            ...localState,
            successMessage: null,
            error: "There has been an error resetting the user's password",
        });
    }
};

const saveContact = async (state, updateState, localState, updateLocalState) => {
    try {
        if (!localState.first_name || !localState.last_name) {
            return updateLocalState({ ...localState, error: 'Please enter a first and last name for the contact' });
        }

        if (localState.email_address && !validateEmail(localState.email_address)) {
            return updateLocalState({ ...localState, error: 'Please enter a valid email address' });
        }

        if (localState.secondary_email_address && !validateEmail(localState.secondary_email_address)) {
            return updateLocalState({ ...localState, error: 'Please enter a valid secondary email address' });
        }

        if (
            !localState.loadedFromServer &&
            state.clientUsers?.find((clientUser) => clientUser.email_address === localState.email_address)
        ) {
            return updateLocalState({
                ...localState,
                error: 'Email is already assigned to another contact for this client',
            });
        }

        updateState({ ...state, loading: true });
        updateLocalState({ ...localState, error: null });

        let newContactsList = state?.clientInView?.client_data?.key_contacts || [];

        if (typeof localState.index === 'number') {
            newContactsList[localState.index] = {
                title: localState.title,
                first_name: localState.first_name,
                middle_name: localState.middle_name,
                last_name: localState.last_name,
                preferred_name: localState.preferred_name,
                email_address: localState.email_address?.trim()?.toLowerCase(),
                secondary_email_address: localState.secondary_email_address?.trim()?.toLowerCase(),
                telephone_number: localState.telephone_number,
                address: localState.address,
                job_title: localState.job_title,
                is_primary_contact: localState.is_primary_contact,
            };
        } else {
            newContactsList.push({
                title: localState.title,
                first_name: localState.first_name,
                middle_name: localState.middle_name,
                last_name: localState.last_name,
                preferred_name: localState.preferred_name,
                email_address: localState.email_address?.trim()?.toLowerCase(),
                secondary_email_address: localState.secondary_email_address?.trim()?.toLowerCase(),
                telephone_number: localState.telephone_number,
                job_title: localState.job_title,
                is_primary_contact: localState.is_primary_contact,
                address: localState.address,
            });
        }

        await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView?.id}`,
            data: {
                client_data: {
                    ...(state.clientInView?.client_data || {}),
                    key_contacts: newContactsList,
                },
            },
        });

        updateState({
            ...state,
            loading: false,
            clientInView: {
                ...state.clientInView,
                client_data: {
                    ...(state.clientInView?.client_data || {}),
                    key_contacts: newContactsList,
                },
            },
        });

        updateLocalState({
            keyContactsView: 'list',
            dataToShow: 'client_data',
            questionnaires_view: 'list',
            clientId: state.clientInView?.id,
        });

        window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (e) {
        updateState({
            ...state,
            loading: false,
        });

        updateLocalState({
            ...localState,
            error: 'There has been an error saving your data',
        });
    }
};
const updateUserEmailAddress = async (localState, updateLocalState, state, updateState) => {
    try {
        if (localState.email_address?.trim()?.toLowerCase() === localState.newEmailAddress?.trim()?.toLowerCase()) {
            return toast.error('Please make a change first to the email address');
        }

        if (
            state.clientInView?.client_data?.key_contacts?.find(
                (c) => c.email_address?.trim()?.toLowerCase() === localState.newEmailAddress?.trim()?.toLowerCase()
            )
        ) {
            return toast.error('Email is already assigned to another contact for this client');
        }

        updateState({
            ...state,
            loading: true,
        });

        updateLocalState({
            ...localState,
            emailUpdateError: null,
        });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView?.raw_id}/users/change-email`,
            method: 'POST',
            data: {
                existing_email_address: localState.email_address,
                new_email_address: localState.newEmailAddress,
                is_user_transfer: localState.is_user_transfer,
            },
        });

        updateState({
            ...state,
            error: null,
            loading: false,
            clientUsers: state.clientUsers?.map((x) => {
                if (x.email_address?.trim()?.toLowerCase() === localState.email_address?.trim()?.toLowerCase()) {
                    x.email_address = localState.newEmailAddress;
                }
                return x;
            }),
            clientInView: {
                ...state.clientInView,
                client_data: {
                    ...state.clientInView?.client_data,
                    key_contacts: state.clientInView?.client_data?.key_contacts?.map((x) => {
                        if (
                            x.email_address?.trim()?.toLowerCase() === localState.email_address?.trim()?.toLowerCase()
                        ) {
                            x.email_address = localState.newEmailAddress;
                        }
                        return x;
                    }),
                },
            },
        });

        updateLocalState({
            ...localState,
            emailUpdateError: null,
            showChangeEmailOverlay: false,
            email_address: localState.newEmailAddress,
        });
    } catch (e) {
        updateState({
            ...state,
            loading: false,
        });

        toast.error(e?.response?.data?.message || 'There has been an error updating the email address');
    }
};

const deleteQuestionnaire = async (id, qId, localState, updateLocalState, state, updateState) => {
    try {
        const newState = {
            ...state,
            error: null,
            qError: null,
            loading: true,
        };

        updateState(newState);

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView?.raw_id}/admin/users/${id}/qs/${qId}`,
            method: 'DELETE',
        });

        await loadClientQuestionnaires('*', localState, updateLocalState, newState, updateState);
    } catch (e) {
        updateState({
            ...state,
            loading: false,
        });

        updateLocalState({
            ...localState,
            qError: 'There has been an error deleting the questionnaire',
        });
    }
};

const sendQuestionnaireToUser = async (id, localState, updateLocalState, state, updateState, fullClientTabMode) => {
    if (!localState.templateQuestionnaire?.value) return;

    try {
        if (
            localState.clientQuestionnaires?.find((x) => {
                return (
                    x.status !== 'completed' &&
                    x.name === localState.templateQuestionnaire?.label &&
                    x.client_user_id === id
                );
            })
        ) {
            setTimeout(() => {
                return updateLocalState({
                    ...localState,
                    qError: null,
                });
            }, 2500);

            return updateLocalState({
                ...localState,
                qError: 'The user already has this questionnaire assigned to them',
            });
        }

        updateState({
            ...state,
            loading: true,
        });

        updateLocalState({
            ...localState,
            qError: null,
        });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView?.raw_id}/users/${id}/qs`,
            method: 'POST',
            data: {
                template_id: localState.templateQuestionnaire?.value,
                bypass_email: true,
            },
        });

        await loadClientQuestionnaires(fullClientTabMode ? '*' : id, localState, updateLocalState, state, updateState);
    } catch (e) {
        updateState({
            ...state,
            loading: false,
        });

        updateLocalState({
            ...localState,
            qError: 'There has been an error sending the questionnaire',
        });
    }
};

const updateClientQuestionnaire = async (
    state,
    updateState,
    localState,
    updateLocalState,
    questionnaire,
    updatePayload,
    fireAndForgetSave
) => {
    try {
        updateLocalState({
            ...localState,
            qError: null,
        });

        if (!fireAndForgetSave) {
            updateState({
                ...state,
                loading: true,
            });
        }

        if (questionnaire.id) {
            await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView?.raw_id}/admin/users/${questionnaire.client_user_id}/qs/${questionnaire.id}`,
                method: 'PUT',
                data: {
                    ...updatePayload,
                },
            });
            questionnaire.updated_at = moment();
        } else {
            const { data } = await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView?.raw_id}/users/${localState.clientUserToSendTo?.value}/qs`,
                method: 'POST',
                data: {
                    ...updatePayload,
                    bypass_email: true,
                },
            });
            questionnaire.id = data.id;
            questionnaire.updated_at = moment();
        }

        await loadClientQuestionnaires(
            '*',
            {
                ...localState,
                qError: null,
                questionnaire,
            },
            updateLocalState,
            {
                ...state,
                loading: false,
            },
            updateState,
            fireAndForgetSave
        );

        if (!fireAndForgetSave) {
            toast('Questionnaire saved', { type: 'success' });
        }
    } catch (e) {
        updateState({
            ...state,
            loading: false,
        });

        if (!fireAndForgetSave) {
            toast('There has been an error', { type: 'error' });
        }
    }
};

const loadClientQuestionnaires = async (id, localState, updateLocalState, state, updateState, fireAndForgetSave) => {
    try {
        updateState({
            ...state,
            qError: null,
            loading: fireAndForgetSave ? false : true,
            clientQuestionnaires: [],
        });

        const [
            {
                data: { questionnaires },
            },
            {
                data: { questionnaires: templateQuestionnaires },
            },
        ] = await Promise.all([
            axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView?.raw_id}/admin/users/${id}/qs`,
            }),
            axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/questionnaires`,
            }),
        ]);

        updateLocalState({
            ...localState,
            loading: false,
            clientQuestionnaires: questionnaires,
            templateQuestionnaires: templateQuestionnaires?.map((x) => ({
                value: x.id,
                label: x.name,
            })),
        });

        updateState({
            ...state,
            loading: false,
        });
    } catch (e) {
        updateState({
            ...state,
            loading: false,
        });

        updateLocalState({
            ...localState,
            qError: "There has been an error loading the client's questionnaires",
        });
    }
};

const KeyContacts = ({ state, updateState, fullClientTabMode }) => {
    const [localState, updateLocalState] = useState({
        keyContactsView: fullClientTabMode ? 'edit' : 'list',
        dataToShow: fullClientTabMode ? 'client_qs' : 'client_data',
        questionnaires_view: 'list',
        clientId: state.clientInView?.id,
    });

    const localStateRef = useRef();
    localStateRef.current = localState;

    useEffect(() => {
        localStateRef.current = localState;
    }, [localState]);

    const userInvitedToUsePortal = fullClientTabMode
        ? true
        : state.clientUsers?.find(
              (x) =>
                  x.email_address?.trim()?.toLowerCase() === localState.email_address?.trim()?.toLowerCase() &&
                  localState.loadedFromServer
          );

    useEffect(() => {
        if (fullClientTabMode || (localState.dataToShow === 'client_qs' && userInvitedToUsePortal)) {
            loadClientQuestionnaires(
                fullClientTabMode ? '*' : userInvitedToUsePortal.user_id,
                localState,
                updateLocalState,
                state,
                updateState
            );
        }
    }, [localState.dataToShow]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            (async () => {
                if (localStateRef.current.questionnaires_view !== 'configure') return;

                await updateClientQuestionnaire(
                    state,
                    updateState,
                    localStateRef.current,
                    updateLocalState,
                    localStateRef.current.questionnaire,
                    {
                        name: localStateRef.current.questionnaire?.name,
                        definition: localStateRef.current.questionnaire?.definition,
                    },
                    true
                );
            })();
        }, 12000);

        return () => clearInterval(intervalId);
    }, [localState.questionnaire, localState.questionnaire?.id]);

    useEffect(() => {
        window.addEventListener('scroll', function () {
            const tableHeader = document.getElementById('q_table_head');
            if (!tableHeader) return;

            const stickyPosition = tableHeader.getBoundingClientRect().top;

            if (stickyPosition <= 101) {
                tableHeader.style.top = '101px';
            } else {
                tableHeader.style.top = 'unset';
            }
        });
    }, []);

    return (
        <Flex sx={{ flexDirection: 'column', mb: 40 }}>
            {state.showDeleteContactConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showDeleteContactConfirmation"
                    text="Are you sure you wish to delete this contact?"
                />
            )}

            {state.showPwdDialog && (
                <Overlay
                    copy={{}}
                    overlay={{ hideNavigation: true }}
                    maxWidth={'550px'}
                    maxHeight={'200px'}
                    embeddedComponent={
                        <Flex sx={{ mt: 20, ml: 20, flexDirection: 'column' }}>
                            <Paragraph>New Password</Paragraph>
                            <Divider width="90%" />
                            <Button
                                sx={{ mt: 10, width: 250, alignSelf: 'center' }}
                                onClick={() => {
                                    navigator.clipboard.writeText(state.newPassword);
                                    updateState({
                                        ...state,
                                        showPwdDialog: false,
                                    });
                                }}
                            >
                                Copy to clipboard
                            </Button>
                        </Flex>
                    }
                    updateOverlay={() =>
                        updateState({
                            ...state,
                            showPwdDialog: false,
                        })
                    }
                />
            )}

            {state.showDeleteQuestionnaireConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showDeleteQuestionnaireConfirmation"
                    text="Are you sure you wish to delete this questionnaire?"
                />
            )}

            {localState.showLookupModal && (
                <Overlay
                    copy={{}}
                    maxWidth={'550px'}
                    embeddedComponent={
                        <LookupOfficersModal
                            copyIntoForm={(data) => {
                                updateLocalState({
                                    ...localState,
                                    officerSelected: null,
                                    officerRecord: null,
                                    showLookupModal: false,
                                    ...data,
                                });
                            }}
                            state={localState}
                            updateState={updateLocalState}
                        />
                    }
                    updateOverlay={() =>
                        updateLocalState({
                            ...localState,
                            officerSelected: null,
                            officerRecord: null,
                            showLookupModal: false,
                        })
                    }
                />
            )}

            {localState.showChangeEmailOverlay && (
                <Overlay
                    copy={{}}
                    maxWidth={'550px'}
                    maxHeight={'290px'}
                    embeddedComponent={
                        <Flex sx={{ ml: 20, mt: 25, flexDirection: 'column' }}>
                            <Paragraph sx={{ fontWeight: 600, fontSize: 18 }}>Change Email Address</Paragraph>
                            <Input
                                onChange={(e) =>
                                    updateLocalState({
                                        ...localState,
                                        newEmailAddress: e.target.value,
                                    })
                                }
                                value={localState.newEmailAddress}
                                sx={{ mt: 20, width: 400 }}
                            />
                            <Flex
                                onClick={(e) =>
                                    updateLocalState({
                                        ...localState,
                                        is_user_transfer: !localState.is_user_transfer,
                                    })
                                }
                                sx={{ mt: 20, alignItems: 'center' }}
                            >
                                <Paragraph sx={{ width: 300 }}>Change only this contact</Paragraph>
                                <Checkbox checked={localState.is_user_transfer} sx={{ ml: -100 }} />
                            </Flex>
                            <Button
                                onClick={async () => {
                                    await updateUserEmailAddress(localState, updateLocalState, state, updateState);
                                }}
                                sx={{ mt: 20, width: 200, height: 40 }}
                            >
                                Update
                            </Button>
                        </Flex>
                    }
                    updateOverlay={() =>
                        updateLocalState({
                            ...localState,
                            showChangeEmailOverlay: false,
                        })
                    }
                />
            )}

            <Flex>
                {localState.keyContactsView === 'edit' && (
                    <Flex sx={{ flexDirection: 'column' }}>
                        {localState.dataToShow === 'client_qs' && (
                            <Flex sx={{ flexDirection: 'column', marginLeft: 20, marginTop: 10 }}>
                                {localState.qError && (
                                    <Paragraph sx={{ color: 'red', mb: 20, mt: 20 }}>{localState.qError}</Paragraph>
                                )}

                                {localState.questionnaires_view === 'list' && (
                                    <Flex sx={{ flexDirection: 'column', mt: 10 }}>
                                        {fullClientTabMode && (
                                            <Flex sx={{ flexDirection: 'column', mb: 10 }}>
                                                <Heading
                                                    sx={{
                                                        fontWeight: '600',
                                                        fontSize: '20px',
                                                        color: 'text',
                                                        textAlign: 'left',
                                                        mt: '5px',
                                                        ml: 0,
                                                    }}
                                                >
                                                    Questionnaires
                                                </Heading>
                                                <Divider width="1300px" />
                                                <Paragraph sx={{ mb: 40 }}>
                                                    Once you have invited your contacts to use the client portal you can
                                                    send them questionnaires
                                                </Paragraph>

                                                <Paragraph sx={{ mb: 0 }}>
                                                    Select the contact you wish to assign the questionnaire to
                                                </Paragraph>
                                                <ReactSelect
                                                    styles={{
                                                        container: (provided) => ({
                                                            ...provided,
                                                            zIndex: 100000000000000,
                                                        }),
                                                        control: (provided) => ({
                                                            ...provided,
                                                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                            width: '400px',
                                                            minHeight: '40px',
                                                            marginTop: '10px',
                                                            borderRadius: 10,
                                                            marginBottom: '10px',
                                                            border: '1px solid #a3a69b',
                                                            fontSize: '14px',
                                                            zIndex: 100000000000000,
                                                        }),
                                                    }}
                                                    onChange={(c) => {
                                                        updateLocalState({
                                                            ...localState,
                                                            clientUserToSendTo: c,
                                                        });
                                                    }}
                                                    placeholder="Select contact"
                                                    value={localState?.clientUserToSendTo}
                                                    options={[
                                                        { value: -1, label: 'Please select a contact' },
                                                        ...getContactsFilteredByThoseUsingClientPortal(
                                                            state.clientInView,
                                                            state.clientUsers
                                                        )?.map((x) => ({
                                                            value: x.user_id,
                                                            label: `${x.first_name} ${x.last_name}`,
                                                        })),
                                                    ]}
                                                />
                                            </Flex>
                                        )}

                                        <Paragraph sx={{ mb: 0 }}>Select questionnaire to send to user</Paragraph>
                                        <Flex sx={{ alignItems: 'center' }}>
                                            <ReactSelect
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                        width: '400px',
                                                        minHeight: '40px',
                                                        marginTop: '10px',
                                                        borderRadius: 10,
                                                        marginBottom: '10px',
                                                        border: '1px solid #a3a69b',
                                                        fontSize: '14px',
                                                    }),
                                                    container: (provided) => ({
                                                        ...provided,
                                                        zIndex: 100000,
                                                    }),
                                                }}
                                                onChange={(c) => {
                                                    updateLocalState({
                                                        ...localState,
                                                        templateQuestionnaire: c,
                                                    });
                                                }}
                                                placeholder="Select questionnaire"
                                                value={localState?.templateQuestionnaire}
                                                options={[
                                                    { value: -1, label: 'Please select a template' },
                                                    ...(localState.templateQuestionnaires || []),
                                                ]}
                                            />
                                            <Button
                                                onClick={async () => {
                                                    userInvitedToUsePortal &&
                                                        (await sendQuestionnaireToUser(
                                                            fullClientTabMode
                                                                ? localState.clientUserToSendTo?.value
                                                                : userInvitedToUsePortal.user_id,
                                                            localState,
                                                            updateLocalState,
                                                            state,
                                                            updateState,
                                                            fullClientTabMode
                                                        ));
                                                }}
                                                sx={{ ml: 10, width: 100, height: 40, fontSize: 13 }}
                                            >
                                                <i
                                                    style={{
                                                        marginRight: '7px',
                                                    }}
                                                    className={`fas fa-save`}
                                                />
                                                Save
                                            </Button>
                                            <Paragraph sx={{ ml: 10, mr: 10 }}>or</Paragraph>
                                            <Button
                                                onClick={() => {
                                                    updateLocalState({
                                                        ...localState,
                                                        questionnaires_view: 'configure',
                                                        questionnaire: { name: '', definition: {} },
                                                    });
                                                }}
                                                sx={{ width: 130, height: 40, fontSize: 13 }}
                                            >
                                                <i
                                                    style={{
                                                        marginRight: '7px',
                                                    }}
                                                    className={`fas fa-plus-circle`}
                                                />
                                                Create New
                                            </Button>
                                        </Flex>
                                    </Flex>
                                )}

                                {localState.questionnaires_view === 'view' && (
                                    <ViewAnswers localState={localState} updateLocalState={updateLocalState} />
                                )}

                                {localState.questionnaires_view === 'configure' && (
                                    <ConfigureQuestionnaire
                                        state={localState}
                                        cancelHandler={() => {
                                            updateLocalState({ ...localState, questionnaires_view: 'list' });
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                        }}
                                        showContactSelector
                                        contacts={[
                                            { value: -1, label: 'Please select a contact' },
                                            ...getContactsFilteredByThoseUsingClientPortal(
                                                state.clientInView,
                                                state.clientUsers
                                            )?.map((x) => ({
                                                value: x.user_id,
                                                label: `${x.first_name} ${x.last_name}`,
                                            })),
                                        ]}
                                        saveHandler={async () => {
                                            await updateClientQuestionnaire(
                                                state,
                                                updateState,
                                                localState,
                                                updateLocalState,
                                                localState.questionnaire,
                                                {
                                                    name: localState.questionnaire?.name,
                                                    definition: localState.questionnaire?.definition,
                                                }
                                            );
                                        }}
                                        updateState={updateLocalState}
                                        organisation={state.clientInView?.organisation}
                                        attributeOptions={lookupAttributeOptions(state.clientInView?.organisation)}
                                        attributeTypes={attributeTypes}
                                    />
                                )}

                                {localState.questionnaires_view === 'list' && (
                                    <Flex sx={{ flexDirection: 'column' }}>
                                        {localState.clientQuestionnaires?.find((item) => item.checked) && (
                                            <Flex sx={{ flexDirection: 'row', alignItems: 'center' }}>
                                                <Button
                                                    onClick={async () => {
                                                        updateLocalState({
                                                            ...localState,
                                                            loading: true,
                                                            reminderSuccess: null,
                                                        });

                                                        await axios({
                                                            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView?.raw_id}/qs/send-reminders`,
                                                            method: 'POST',
                                                            data: {
                                                                ids: localState.clientQuestionnaires
                                                                    ?.filter((item) => item.checked)
                                                                    .map((x) => x.id),
                                                            },
                                                        });

                                                        const [
                                                            {
                                                                data: { questionnaires },
                                                            },
                                                        ] = await Promise.all([
                                                            axios({
                                                                url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView?.raw_id}/admin/users/*/qs`,
                                                            }),
                                                        ]);

                                                        const newLocalState = {
                                                            ...localState,
                                                            loading: false,
                                                            reminderSuccess: 'Reminder Sent',
                                                            clientQuestionnaires: questionnaires,
                                                        };

                                                        updateLocalState(newLocalState);

                                                        setTimeout(() => {
                                                            updateLocalState({
                                                                ...newLocalState,
                                                                reminderSuccess: null,
                                                            });
                                                        }, 3000);
                                                    }}
                                                    sx={{ mt: 10, mb: 0, width: 150, height: 40, fontSize: 14 }}
                                                >
                                                    Send Reminder
                                                </Button>
                                                {localState.reminderSuccess && (
                                                    <Paragraph sx={{ ml: 20, mt: 10, color: 'green' }}>
                                                        {localState.reminderSuccess}
                                                    </Paragraph>
                                                )}
                                            </Flex>
                                        )}

                                        <table style={{ marginTop: 20, borderCollapse: 'collapse', fontSize: 14 }}>
                                            <thead
                                                id="q_table_head"
                                                style={{
                                                    backgroundColor: theme.colors.primary,
                                                    cursor: 'pointer',
                                                    position: 'sticky',
                                                    color: 'white',
                                                    zIndex: 10000,
                                                }}
                                            >
                                                <tr>
                                                    <th style={{ width: 40, textAlign: 'left', padding: 20 }}>
                                                        <Flex sx={{ alignItems: 'center' }}>
                                                            <Flex
                                                                onClick={() => {
                                                                    updateLocalState({
                                                                        ...localState,
                                                                        allQsSelected: localState.allQsSelected
                                                                            ? false
                                                                            : true,
                                                                        clientQuestionnaires:
                                                                            localState.clientQuestionnaires.map(
                                                                                (item) => ({
                                                                                    ...item,
                                                                                    checked: localState.allQsSelected
                                                                                        ? false
                                                                                        : true,
                                                                                })
                                                                            ),
                                                                    });
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    sx={{ backgroundColor: 'white' }}
                                                                    checked={localState.allQsSelected}
                                                                />
                                                            </Flex>
                                                        </Flex>
                                                    </th>
                                                    {fullClientTabMode && (
                                                        <th style={{ width: 250, textAlign: 'left', padding: 20 }}>
                                                            Contact Name
                                                        </th>
                                                    )}
                                                    <th style={{ width: 350, textAlign: 'left', padding: 20 }}>Name</th>
                                                    <th style={{ width: 150, textAlign: 'left', padding: 20 }}>
                                                        Status
                                                    </th>
                                                    <th style={{ width: 200, textAlign: 'left', padding: 20 }}>
                                                        Last Sent
                                                    </th>
                                                    <th style={{ width: 50, textAlign: 'left', padding: 20 }}></th>
                                                    <th style={{ width: 50, textAlign: 'left', padding: 20 }}></th>
                                                    <th style={{ width: 50, textAlign: 'left', padding: 20 }}></th>
                                                    <th style={{ width: 50, textAlign: 'left', padding: 20 }}></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {localState.clientQuestionnaires?.map((questionnaire, idx) => (
                                                    <tr
                                                        style={{
                                                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)',
                                                            cursor: 'pointer',
                                                            borderLeft: 'drop-shadow(0 0 0.18rem #015dc5)',
                                                        }}
                                                        key={questionnaire.id}
                                                    >
                                                        <td style={{ width: 40, padding: 20 }}>
                                                            <Flex
                                                                onClick={() => {
                                                                    localState.clientQuestionnaires[idx].checked =
                                                                        !!questionnaire.checked ? false : true;
                                                                    updateLocalState({
                                                                        ...localState,
                                                                        clientQuestionnaires:
                                                                            localState.clientQuestionnaires,
                                                                    });
                                                                }}
                                                            >
                                                                <Checkbox checked={questionnaire.checked} />
                                                            </Flex>
                                                        </td>
                                                        {fullClientTabMode && (
                                                            <td style={{ width: 200, padding: 20 }}>
                                                                <ReactSelect
                                                                    styles={{
                                                                        control: (provided) => ({
                                                                            ...provided,
                                                                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                            width: '200px',
                                                                            minHeight: '40px',
                                                                            marginTop: '10px',
                                                                            borderRadius: 10,
                                                                            marginBottom: '10px',
                                                                            border: '1px solid #a3a69b',
                                                                            fontSize: '14px',
                                                                        }),
                                                                    }}
                                                                    onChange={async (c) => {
                                                                        await updateClientQuestionnaire(
                                                                            state,
                                                                            updateState,
                                                                            localState,
                                                                            updateLocalState,
                                                                            {
                                                                                ...questionnaire,
                                                                                client_user_id: c.value,
                                                                            },
                                                                            {}
                                                                        );
                                                                    }}
                                                                    placeholder="Select contact"
                                                                    value={
                                                                        state.clientUsers?.find(
                                                                            (x) =>
                                                                                x.user_id ===
                                                                                questionnaire.client_user_id
                                                                        )
                                                                            ? {
                                                                                  value: questionnaire.client_user_id,
                                                                                  label: questionnaire.client_user_name,
                                                                              }
                                                                            : null
                                                                    }
                                                                    options={[
                                                                        { value: -1, label: 'Please select a contact' },
                                                                        ...getContactsFilteredByThoseUsingClientPortal(
                                                                            state.clientInView,
                                                                            state.clientUsers
                                                                        )?.map((x) => ({
                                                                            value: x.user_id,
                                                                            label: `${x.first_name} ${x.last_name}`,
                                                                        })),
                                                                    ]}
                                                                />
                                                            </td>
                                                        )}
                                                        <td style={{ width: 200, padding: 20 }}>
                                                            <Input
                                                                onChange={(e) => {
                                                                    updateLocalState({
                                                                        ...localState,
                                                                        clientQuestionnaires:
                                                                            localState.clientQuestionnaires.map((q) => {
                                                                                if (q.id === questionnaire.id) {
                                                                                    q.name = e.target.value;
                                                                                }
                                                                                return q;
                                                                            }),
                                                                    });
                                                                }}
                                                                onBlur={async (e) => {
                                                                    if (!e.target.value) return;

                                                                    await updateClientQuestionnaire(
                                                                        state,
                                                                        updateState,
                                                                        localState,
                                                                        updateLocalState,
                                                                        questionnaire,
                                                                        {
                                                                            name: e.target.value,
                                                                        }
                                                                    );
                                                                }}
                                                                value={questionnaire.name}
                                                            />
                                                        </td>
                                                        <td style={{ width: 200, padding: 20 }}>
                                                            <ReactSelect
                                                                styles={{
                                                                    control: (provided) => ({
                                                                        ...provided,
                                                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                        width: '220px',
                                                                        minHeight: '40px',
                                                                        marginTop: '10px',
                                                                        borderRadius: 10,
                                                                        marginBottom: '10px',
                                                                        border: '1px solid #a3a69b',
                                                                        fontSize: '14px',
                                                                    }),
                                                                }}
                                                                onChange={async (c) => {
                                                                    await updateClientQuestionnaire(
                                                                        state,
                                                                        updateState,
                                                                        localState,
                                                                        updateLocalState,
                                                                        questionnaire,
                                                                        {
                                                                            status: c.value,
                                                                        }
                                                                    );

                                                                    localState.clientQuestionnaires[idx].status =
                                                                        c.value;

                                                                    updateLocalState({
                                                                        ...localState,
                                                                        clientQuestionnaires:
                                                                            localState.clientQuestionnaires,
                                                                    });
                                                                }}
                                                                placeholder="Select questionnaire"
                                                                value={
                                                                    [
                                                                        { value: 'todo', label: 'Todo' },
                                                                        { value: 'completed', label: 'Completed' },
                                                                    ]?.find(
                                                                        (item) => item.value === questionnaire.status
                                                                    ) || { value: 'todo', label: 'Todo' }
                                                                }
                                                                options={[
                                                                    { value: 'todo', label: 'Todo' },
                                                                    { value: 'completed', label: 'Completed' },
                                                                ]}
                                                            />
                                                        </td>

                                                        <td style={{ width: 100, padding: 10 }}>
                                                            <Paragraph sx={{ ml: 0, fontSize: 13 }}>
                                                                {questionnaire.sent_at
                                                                    ? moment
                                                                          .utc(questionnaire.sent_at)
                                                                          .local()
                                                                          .format('HH:mm DD-MM-YY')
                                                                    : ''}
                                                            </Paragraph>
                                                        </td>

                                                        <td style={{ width: 50, padding: 20 }}>
                                                            <Paragraph
                                                                onClick={() => {
                                                                    updateLocalState({
                                                                        ...localState,
                                                                        questionnaires_view: 'view',
                                                                        questionnaire,
                                                                    });
                                                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                                                }}
                                                                sx={{ color: 'blue', cursor: 'pointer', ml: 15 }}
                                                            >
                                                                <i
                                                                    style={{
                                                                        fontSize: '12px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    className="fas fa-eye"
                                                                    aria-hidden="true"
                                                                />
                                                            </Paragraph>
                                                        </td>
                                                        <td style={{ width: 50, padding: 20 }}>
                                                            <Paragraph
                                                                onClick={() => {
                                                                    questionnaire.status === 'todo' &&
                                                                        updateLocalState({
                                                                            ...localState,
                                                                            questionnaires_view: 'configure',
                                                                            questionnaire,
                                                                        });

                                                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                                                }}
                                                                sx={{
                                                                    color:
                                                                        questionnaire.status === 'todo'
                                                                            ? 'blue'
                                                                            : 'grey',
                                                                    cursor: 'pointer',
                                                                    ml: 15,
                                                                }}
                                                            >
                                                                <i
                                                                    style={{
                                                                        fontSize: '12px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    className="fas fa-pencil"
                                                                    aria-hidden="true"
                                                                />
                                                            </Paragraph>
                                                        </td>
                                                        <td style={{ width: 50, padding: 20 }}>
                                                            <Paragraph
                                                                onClick={async () => {
                                                                    if (questionnaire?.status === 'completed') {
                                                                        setTimeout(() => {
                                                                            return updateLocalState({
                                                                                ...localState,
                                                                                qError: null,
                                                                            });
                                                                        }, 2000);
                                                                        return updateLocalState({
                                                                            ...localState,
                                                                            qError: 'A questionnaire cannot be deleted once it is complete',
                                                                        });
                                                                    }
                                                                    if (
                                                                        questionnaire.definition?.questions?.find(
                                                                            (x) => x.answer
                                                                        )
                                                                    ) {
                                                                        setTimeout(() => {
                                                                            return updateLocalState({
                                                                                ...localState,
                                                                                qError: null,
                                                                            });
                                                                        }, 2000);
                                                                        return updateLocalState({
                                                                            ...localState,
                                                                            qError: 'A questionnaire cannot be deleted once the user has begun',
                                                                        });
                                                                    }

                                                                    updateState({
                                                                        ...state,
                                                                        showDeleteQuestionnaireConfirmation: true,
                                                                        confirmCallback: async () => {
                                                                            await deleteQuestionnaire(
                                                                                userInvitedToUsePortal.user_id,
                                                                                questionnaire.id,
                                                                                localState,
                                                                                updateLocalState,
                                                                                state,
                                                                                updateState
                                                                            );
                                                                        },
                                                                    });
                                                                }}
                                                                sx={{ color: 'red', cursor: 'pointer', ml: 15 }}
                                                            >
                                                                <i
                                                                    style={{
                                                                        fontSize: '12px',
                                                                        cursor: 'pointer',
                                                                        color: 'red',
                                                                    }}
                                                                    className="fas fa-trash"
                                                                    aria-hidden="true"
                                                                />
                                                            </Paragraph>
                                                        </td>
                                                        <td style={{ padding: 20, height: 30 }}>
                                                            <Button
                                                                sx={{ width: 120, fontSize: 13 }}
                                                                onClick={async () => {
                                                                    updateState({
                                                                        ...state,
                                                                        loading: true,
                                                                    });

                                                                    await axios({
                                                                        url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView?.raw_id}/qs/send-reminders`,
                                                                        method: 'POST',
                                                                        data: {
                                                                            ids: [questionnaire.id],
                                                                        },
                                                                    });

                                                                    const [
                                                                        {
                                                                            data: { questionnaires },
                                                                        },
                                                                    ] = await Promise.all([
                                                                        axios({
                                                                            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView?.raw_id}/admin/users/*/qs`,
                                                                        }),
                                                                    ]);

                                                                    updateLocalState({
                                                                        ...localState,
                                                                        clientQuestionnaires: questionnaires,
                                                                    });

                                                                    updateState({
                                                                        ...state,
                                                                        loading: false,
                                                                    });
                                                                }}
                                                            >
                                                                Send Email
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Flex>
                                )}
                            </Flex>
                        )}

                        {localState.dataToShow === 'client_data' && (
                            <Flex>
                                <Button
                                    sx={{
                                        mt: 20,
                                        mb: 20,
                                        ml: 20,
                                        width: 150,
                                        fontSize: 12,
                                    }}
                                    variant="light"
                                    onClick={() => {
                                        updateLocalState({
                                            clientId: state.clientInView?.id,
                                            keyContactsView: 'list',
                                            dataToShow: fullClientTabMode ? 'client_qs' : 'client_data',
                                        });
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}
                                >
                                    <i
                                        style={{
                                            fontSize: '12px',
                                            cursor: 'pointer',
                                            marginRight: '5px',
                                        }}
                                        className="fas fa-chevron-left"
                                        aria-hidden="true"
                                    />
                                    Back
                                </Button>
                                <Button
                                    variant="light"
                                    sx={{
                                        mt: 20,
                                        mb: 20,
                                        ml: 20,
                                        width: 150,
                                        fontSize: 12,
                                    }}
                                    onClick={() =>
                                        updateLocalState({
                                            ...localState,
                                            showLookupModal: true,
                                        })
                                    }
                                >
                                    <i
                                        style={{
                                            marginRight: '7px',
                                        }}
                                        className={`fas fa-search`}
                                    />
                                    Lookup Officers
                                </Button>
                                {localState.email_address &&
                                    localState.first_name &&
                                    localState.last_name &&
                                    localState.loadedFromServer && (
                                        <Button
                                            sx={{
                                                mt: 20,
                                                ml: 20,
                                                mb: 20,
                                                width: 170,
                                                fontSize: 12,
                                            }}
                                            variant="light"
                                            onClick={async () =>
                                                await sendRegistrationInvite(
                                                    state,
                                                    updateState,
                                                    localState,
                                                    updateLocalState
                                                )
                                            }
                                        >
                                            Send Portal Invite
                                        </Button>
                                    )}

                                {/* {userInvitedToUsePortal && (
                                    <Button
                                        sx={{
                                            mt: 20,
                                            ml: 20,
                                            mb: 20,
                                            width: 160,
                                            fontSize: 12,
                                        }}
                                        variant="light"
                                        onClick={async () =>
                                            await resetUserPassword(state, updateState, localState, updateLocalState)
                                        }
                                    >
                                        Reset user password
                                    </Button>
                                )} */}

                                {userInvitedToUsePortal && (
                                    <Button
                                        sx={{
                                            mt: 20,
                                            ml: 20,
                                            mb: 20,
                                            width: 160,
                                            fontSize: 12,
                                        }}
                                        variant="light"
                                        onClick={async () =>
                                            await disablePortalUsage(state, updateState, localState, updateLocalState)
                                        }
                                    >
                                        Disable Portal Usage
                                    </Button>
                                )}
                                {localState.email_address &&
                                    localState.first_name &&
                                    localState.last_name &&
                                    localState.loadedFromServer && (
                                        <Button
                                            sx={{
                                                mt: 20,
                                                ml: 20,
                                                mb: 20,
                                                width: 170,
                                                fontSize: 12,
                                            }}
                                            variant="light"
                                            onClick={async () => {
                                                try {
                                                    updateState({
                                                        ...state,
                                                        loading: true,
                                                    });
                                                    const {
                                                        data: { pwd },
                                                    } = await axios({
                                                        url: `${process.env.REACT_APP_AQRU_AI_API}/clients/gen-pwd`,
                                                        method: 'POST',
                                                        data: {
                                                            email_address: localState.email_address
                                                                ?.toLowerCase()
                                                                ?.trim(),
                                                        },
                                                    });

                                                    updateState({
                                                        ...state,
                                                        loading: false,
                                                        showPwdDialog: true,
                                                        newPassword: pwd,
                                                    });
                                                } catch (e) {
                                                    updateState({
                                                        ...state,
                                                        loading: false,
                                                    });
                                                    toast('Something has gone wrong, please contact support', {
                                                        type: 'error',
                                                        autoClose: 5000,
                                                        position: 'top-right',
                                                    });
                                                }
                                            }}
                                        >
                                            Generate password
                                        </Button>
                                    )}

                                <Button
                                    sx={{
                                        mt: 20,
                                        ml: 20,
                                        mb: 20,
                                        width: 150,
                                        height: 40,
                                        color: '#FFF',
                                        fontSize: 14,
                                        backgroundColor: 'primary',
                                    }}
                                    onClick={async () =>
                                        await saveContact(state, updateState, localState, updateLocalState)
                                    }
                                >
                                    <i
                                        style={{
                                            marginRight: '7px',
                                        }}
                                        className={`fas fa-save`}
                                    />
                                    Save
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                )}
                {localState.keyContactsView === 'list' && (
                    <Flex sx={{ flexDirection: 'column' }}>
                        <Heading
                            sx={{
                                fontWeight: '600',
                                fontSize: '20px',
                                color: 'text',
                                textAlign: 'left',
                                mt: '25px',
                                ml: 20,
                            }}
                        >
                            Contacts
                        </Heading>
                        <Divider width="1300px" />
                        <Button
                            sx={{
                                mt: 0,
                                mb: 20,
                                ml: 10,
                                width: 150,
                                height: 40,
                                color: 'text',
                                fontSize: 14,
                                backgroundColor: 'white',
                                border: '1px solid lightGrey',
                            }}
                            onClick={() => {
                                updateLocalState({
                                    ...localState,
                                    keyContactsView: 'edit',
                                    keyContact: null,
                                    error: null,
                                });
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                        >
                            <i
                                style={{
                                    marginRight: '7px',
                                }}
                                className={`fas fa-plus-circle`}
                            />
                            Add Contact
                        </Button>
                    </Flex>
                )}
            </Flex>
            {localState.keyContactsView === 'edit' && localState.dataToShow === 'client_data' && (
                <Flex sx={{ flexDirection: 'column', ml: 20, mt: 10 }}>
                    {localState.error && <Paragraph sx={{ color: 'red', mb: 20 }}>{localState.error}</Paragraph>}
                    {localState.successMessage && (
                        <Paragraph sx={{ color: 'green', mb: 20 }}>{localState.successMessage}</Paragraph>
                    )}

                    <Flex sx={{ alignItems: 'center', mb: 20 }}>
                        <Label id="title-label" sx={{ width: 200 }}>
                            Title
                        </Label>
                        <Select
                            sx={{
                                width: 400,
                                height: 40,
                                backgroundColor: 'white',
                                borderRadius: 10,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.3)',
                            }}
                            value={localState.title}
                            onChange={(e) => updateLocalState({ ...localState, title: e.target.value })}
                        >
                            <option value="">Select a title</option>
                            <option value="Capt">Capt</option>
                            <option value="Col">Col</option>
                            <option value="Dame">Dame</option>
                            <option value="Dr">Dr</option>
                            <option value="Judge">Judge</option>
                            <option value="Lady">Lady</option>
                            <option value="Lord">Lord</option>
                            <option value="Lt Col">Lt Col</option>
                            <option value="Maj">Maj</option>
                            <option value="Mr">Mr</option>
                            <option value="Miss">Miss</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Ms">Ms</option>
                            <option value="Revd">Revd</option>
                            <option value="Mx">Mx</option>
                            <option value="Prof">Prof</option>
                            <option value="Sgt">Sgt</option>
                            <option value="Sir">Sir</option>
                        </Select>
                    </Flex>
                    <Flex sx={{ alignItems: 'center', mb: 20 }}>
                        <Label id="first-name-label" sx={{ width: 200 }}>
                            First Name
                        </Label>
                        <Input
                            sx={{ width: 400 }}
                            aria-labelledby="first-name-label"
                            placeholder="Contact first name"
                            value={localState.first_name}
                            onChange={(e) => updateLocalState({ ...localState, first_name: e.target.value })}
                        />
                    </Flex>
                    <Flex sx={{ alignItems: 'center', mb: 20 }}>
                        <Label id="middle-name-label" sx={{ width: 200 }}>
                            Middle Name
                        </Label>
                        <Input
                            sx={{ width: 400 }}
                            aria-labelledby="middle-name-label"
                            placeholder="Contact middle name"
                            value={localState.middle_name}
                            onChange={(e) => updateLocalState({ ...localState, middle_name: e.target.value })}
                        />
                    </Flex>
                    <Flex sx={{ alignItems: 'center', mb: 20 }}>
                        <Label id="last-name-label" sx={{ width: 200 }}>
                            Last Name
                        </Label>
                        <Input
                            sx={{ width: 400 }}
                            aria-labelledby="last-name-label"
                            placeholder="Contact last name"
                            value={localState.last_name}
                            onChange={(e) => updateLocalState({ ...localState, last_name: e.target.value })}
                        />
                    </Flex>

                    <Flex sx={{ alignItems: 'center', mb: 20 }}>
                        <Label id="title-label" sx={{ width: 200 }}>
                            Invited to use portal
                        </Label>
                        <Paragraph>{userInvitedToUsePortal ? 'Yes' : 'No'}</Paragraph>
                    </Flex>

                    {userInvitedToUsePortal &&
                    state.clientUsers?.find((x) => x.email_address === localState.email_address)
                        ?.expense_inbox_address ? (
                        <Flex sx={{ alignItems: 'center', mb: 20 }}>
                            <Label id="title-label" sx={{ width: 200 }}>
                                Unique Expenses Email Address
                            </Label>
                            <Flex sx={{ alignItems: 'center' }}>
                                <Paragraph>
                                    {
                                        state.clientUsers?.find((x) => x.email_address === localState.email_address)
                                            ?.expense_inbox_address
                                    }
                                </Paragraph>
                                <Button
                                    sx={{ ml: 10 }}
                                    variant="light"
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            state.clientUsers?.find((x) => x.email_address === localState.email_address)
                                                ?.expense_inbox_address
                                        );
                                        toast.success('Copied to clipboard');
                                    }}
                                >
                                    <i style={{ marginRight: '7px' }} className="fa fa-copy" />
                                    Copy
                                </Button>
                            </Flex>
                        </Flex>
                    ) : null}

                    <Flex sx={{ alignItems: 'center', mb: 20 }}>
                        <Label id="preferred-name-label" sx={{ width: 200 }}>
                            Preferred Name
                        </Label>
                        <Input
                            sx={{ width: 400 }}
                            aria-labelledby="preferred-name-label"
                            placeholder="Contact preferred name"
                            value={localState.preferred_name}
                            onChange={(e) => updateLocalState({ ...localState, preferred_name: e.target.value })}
                        />
                    </Flex>
                    <Flex sx={{ alignItems: 'center', mb: 20 }}>
                        <Label id="email-address-label" sx={{ width: 200 }}>
                            Email Address
                        </Label>
                        <Input
                            sx={{ width: 400, backgroundColor: userInvitedToUsePortal ? '#EFEFEF' : 'auto' }}
                            disabled={!!userInvitedToUsePortal}
                            aria-labelledby="email-address-label"
                            placeholder="Contact email address"
                            value={localState.email_address}
                            onChange={(e) =>
                                updateLocalState({ ...localState, email_address: e.target.value?.toLowerCase() })
                            }
                        />
                        {userInvitedToUsePortal && (
                            <Button
                                sx={{ ml: 10 }}
                                variant="light"
                                onClick={() =>
                                    updateLocalState({
                                        ...localState,
                                        showChangeEmailOverlay: true,
                                        newEmailAddress: localState.email_address,
                                    })
                                }
                            >
                                Change
                            </Button>
                        )}
                    </Flex>
                    <Flex sx={{ alignItems: 'center', mb: 20 }}>
                        <Label id="secondary-email-address-label" sx={{ width: 200 }}>
                            Secondary Email Address
                        </Label>
                        <Input
                            sx={{ width: 400 }}
                            aria-labelledby="secondary-email-address-label"
                            placeholder="Contact secondary email address"
                            value={localState.secondary_email_address}
                            onChange={(e) =>
                                updateLocalState({ ...localState, secondary_email_address: e.target.value })
                            }
                        />
                    </Flex>

                    <Flex sx={{ alignItems: 'center', mb: 20 }}>
                        <Label id="tel-label" sx={{ width: 200 }}>
                            Telephone number
                        </Label>
                        <Input
                            sx={{ width: 400 }}
                            aria-labelledby="tel-label"
                            placeholder="Contact telephone number"
                            value={localState.telephone_number}
                            onChange={(e) => updateLocalState({ ...localState, telephone_number: e.target.value })}
                        />
                    </Flex>

                    <Flex sx={{ alignItems: 'center', mb: 20 }}>
                        <Label id="jt-label" sx={{ width: 200 }}>
                            Job title
                        </Label>
                        <Input
                            sx={{ width: 400 }}
                            aria-labelledby="jt-label"
                            placeholder="Contact job title e.g. CEO"
                            value={localState.job_title}
                            onChange={(e) => updateLocalState({ ...localState, job_title: e.target.value })}
                        />
                    </Flex>

                    <Flex sx={{ alignItems: 'flex-start', mb: 20 }}>
                        <Label id="addr-label" sx={{ width: 200, mt: 10 }}>
                            Address
                        </Label>
                        <Flex sx={{ flexDirection: 'column' }}>
                            <Input
                                sx={{ width: 400 }}
                                aria-labelledby="addr-label"
                                placeholder="First line of the address"
                                value={localState.address?.line1}
                                onChange={(e) =>
                                    updateLocalState({
                                        ...localState,
                                        address: {
                                            ...(localState.address || {}),
                                            line1: e.target.value,
                                        },
                                    })
                                }
                            />
                            <Input
                                sx={{ width: 400, mt: 10 }}
                                aria-labelledby="addr-label"
                                placeholder="City"
                                value={localState.address?.city}
                                onChange={(e) =>
                                    updateLocalState({
                                        ...localState,
                                        address: {
                                            ...(localState.address || {}),
                                            city: e.target.value,
                                        },
                                    })
                                }
                            />
                            <Input
                                sx={{ width: 400, mt: 10 }}
                                aria-labelledby="addr-label"
                                placeholder="Postal code"
                                value={localState.address?.postcode}
                                onChange={(e) =>
                                    updateLocalState({
                                        ...localState,
                                        address: {
                                            ...(localState.address || {}),
                                            postcode: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Flex>
                    </Flex>

                    <Flex sx={{ alignItems: 'center', mb: 20 }}>
                        <Label id="pc-label" sx={{ width: 260 }}>
                            Is Primary Contact
                        </Label>
                        <Switch
                            sx={{}}
                            aria-labelledby="pc-label"
                            defaultChecked={localState.is_primary_contact || false}
                            onClick={() => {
                                updateLocalState({
                                    ...localState,
                                    is_primary_contact: !localState.is_primary_contact,
                                });
                            }}
                        />
                    </Flex>
                </Flex>
            )}

            {localState.keyContactsView === 'list' && (
                <table style={{ fontSize: 14, borderCollapse: 'collapse', width: 1500 }}>
                    <thead style={{ backgroundColor: theme.colors.primary, color: 'white' }}>
                        <th style={{ padding: 20, wordWrap: 'break-word' }}>
                            <Paragraph sx={{ textAlign: 'left' }}>Title</Paragraph>
                        </th>
                        <th style={{ padding: 20, wordWrap: 'break-word' }}>
                            <Paragraph sx={{ textAlign: 'left' }}>First Name</Paragraph>
                        </th>
                        <th style={{ padding: 20 }}>
                            <Paragraph sx={{ textAlign: 'left' }}>Last Name</Paragraph>
                        </th>
                        <th style={{ padding: 20 }}>
                            <Paragraph sx={{ textAlign: 'left' }}>Email</Paragraph>
                        </th>
                        <th style={{ padding: 20 }}>
                            <Paragraph sx={{ textAlign: 'left' }}>Tel no</Paragraph>
                        </th>
                        <th style={{ padding: 20 }}>
                            <Paragraph sx={{ textAlign: 'left' }}>Address</Paragraph>
                        </th>
                        <th style={{ padding: 20 }}>
                            <Paragraph sx={{ textAlign: 'left' }}>Primary contact</Paragraph>
                        </th>
                        <th style={{ padding: 20 }}>
                            <Paragraph sx={{ textAlign: 'left' }}>Portal User</Paragraph>
                        </th>
                        <th style={{ padding: 20 }}>
                            <Paragraph sx={{ textAlign: 'left' }}>Last Portal Activity</Paragraph>
                        </th>
                        <th style={{ width: 160 }}></th>
                    </thead>
                    {state.clientInView?.client_data?.key_contacts?.map((x, idx) => (
                        <tr
                            key={x.email_address}
                            style={{
                                ml: 20,
                            }}
                        >
                            <td
                                style={{
                                    padding: 20,
                                    wordWrap: 'break-word',
                                }}
                            >
                                <Paragraph sx={{ textAlign: 'left', maxWidth: 100 }}>{x.title}</Paragraph>
                            </td>
                            <td
                                style={{
                                    padding: 20,
                                    wordWrap: 'break-word',
                                }}
                            >
                                <Paragraph sx={{ textAlign: 'left', maxWidth: 100 }}>{x.first_name}</Paragraph>
                            </td>
                            <td
                                style={{
                                    padding: 20,
                                    wordWrap: 'break-word',
                                }}
                            >
                                <Paragraph sx={{ textAlign: 'left', maxWidth: 140 }}>{x.last_name}</Paragraph>
                            </td>
                            <td
                                style={{
                                    padding: 20,
                                    wordWrap: 'break-word',
                                }}
                            >
                                <Paragraph sx={{ textAlign: 'left', maxWidth: 140 }}>{x.email_address}</Paragraph>
                            </td>
                            <td
                                style={{
                                    padding: 20,
                                    wordWrap: 'break-word',
                                }}
                            >
                                <Paragraph sx={{ textAlign: 'left', maxWidth: 140 }}>{x.telephone_number}</Paragraph>
                            </td>
                            <td
                                style={{
                                    padding: 20,
                                    wordWrap: 'break-word',
                                }}
                            >
                                <Paragraph sx={{ textAlign: 'left', maxWidth: 140 }}>
                                    {x.address?.line1}
                                    {x.address?.city ? ` ${x.address?.city}` : ''}
                                    {x.address?.postcode ? ` ${x.address?.postcode}` : ''}
                                </Paragraph>
                            </td>
                            <td
                                style={{
                                    padding: 20,
                                    wordWrap: 'break-word',
                                }}
                            >
                                <Paragraph sx={{ textAlign: 'left', maxWidth: 140 }}>
                                    {x.is_primary_contact ? 'Yes' : 'No'}
                                </Paragraph>
                            </td>
                            <td
                                style={{
                                    padding: 20,
                                    wordWrap: 'break-word',
                                }}
                            >
                                <Paragraph sx={{ textAlign: 'left', maxWidth: 140 }}>
                                    {state.clientUsers?.find((y) => x.email_address === y.email_address) ? 'Yes' : 'No'}
                                </Paragraph>
                            </td>
                            <td
                                style={{
                                    padding: 20,
                                    wordWrap: 'break-word',
                                }}
                            >
                                <Paragraph sx={{ textAlign: 'left', maxWidth: 140 }}>
                                    {state.clientUsers?.find((y) => x.email_address === y.email_address)
                                        ?.last_activity_timestamp
                                        ? moment(
                                              state.clientUsers?.find((y) => x.email_address === y.email_address)
                                                  ?.last_activity_timestamp
                                          ).format('HH:mm DD-MM-YY')
                                        : ''}
                                </Paragraph>
                            </td>
                            <td style={{ alignItems: 'center' }}>
                                <Flex sx={{ flexDirection: 'column', justifyContent: 'center', padding: 10 }}>
                                    <Button
                                        onClick={() => {
                                            updateLocalState({
                                                ...localState,
                                                ...x,
                                                keyContactsView: 'edit',
                                                index: idx,
                                                loadedFromServer: true,
                                            });
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                        }}
                                        variant="light"
                                        sx={{ fontSize: 12 }}
                                    >
                                        <i
                                            style={{
                                                cursor: 'pointer',
                                                marginRight: '5px',
                                            }}
                                            className="fas fa-pencil"
                                            aria-hidden="true"
                                        />
                                        Edit
                                    </Button>
                                    {state.clientUsers?.find(
                                        (clientUser) =>
                                            x.email_address?.trim()?.toLowerCase() ===
                                            clientUser.email_address?.trim()?.toLowerCase()
                                    ) ? null : (
                                        <Button
                                            onClick={async () => {
                                                try {
                                                    updateState({
                                                        ...state,
                                                        loading: true,
                                                    });

                                                    await axios({
                                                        url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state?.clientInView?.raw_id}/users`,
                                                        method: 'POST',
                                                        data: {
                                                            first_name: x.first_name,
                                                            last_name: x.last_name,
                                                            email_address: x.email_address?.toLowerCase()?.trim(),
                                                        },
                                                    });

                                                    const {
                                                        data: { users: clientUsers },
                                                    } = await axios({
                                                        url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state?.clientInView?.raw_id}/users`,
                                                    });

                                                    toast('Invite sent', { type: 'success' });

                                                    updateState({
                                                        ...state,
                                                        clientUsers: clientUsers.map((x) => ({
                                                            ...x,
                                                            loadedFromServer: true,
                                                        })),
                                                        loading: false,
                                                    });
                                                } catch (e) {
                                                    console.log(e);

                                                    updateState({
                                                        ...state,
                                                        loading: false,
                                                    });
                                                    toast(
                                                        e?.response?.data?.message ||
                                                            'Something has gone wrong sending invite, please contact support',
                                                        { type: 'error' }
                                                    );
                                                }
                                            }}
                                            variant="light"
                                            sx={{ fontSize: 12, mt: 10 }}
                                        >
                                            <i
                                                style={{
                                                    cursor: 'pointer',
                                                    marginRight: '5px',
                                                }}
                                                className="fas fa-paper-plane"
                                                aria-hidden="true"
                                            />
                                            Invite to Portal
                                        </Button>
                                    )}
                                </Flex>
                            </td>
                        </tr>
                    ))}
                </table>
            )}
        </Flex>
    );
};

export default KeyContacts;
