/* eslint-disable no-undef */
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import { Button, Flex, Paragraph, Switch } from 'theme-ui';
import Spinner from '../spinner';
import SignatureCanvas from '../signature';

const downloadFile = async (fileUuid, state, updateState) => {
    try {
        updateState({ ...state, loading: true });

        const {
            data: { url, file_extension, file_name },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/documents/portal-admin/${fileUuid}`,
        });

        const a = document.createElement('a');

        a.href = url;
        a.target = '_blank';
        a.download = `${file_name}.${file_extension}`;

        a.click();

        updateState({
            ...state,
            loading: false,
        });
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false, error: 'We are currently unable to download your file' });
    }
};

const downloadAnswers = (localState) => {
    const doc = new jsPDF();

    let yOffset = 20;

    doc.setFontSize(15);

    doc.text(`Questionnaire name: ${localState.questionnaire?.name}`, 10, yOffset);
    yOffset += 20;

    doc.setFontSize(13);

    doc.text(`Client name: ${localState.questionnaire?.client_name}`, 10, yOffset);
    yOffset += 10;

    doc.text(`User: ${localState.questionnaire?.client_user_name}`, 10, yOffset);
    yOffset += 10;

    doc.text(
        `Completed At: ${moment.utc(localState.questionnaire?.updated_at).format('HH:mm DD/MM/YYYY')}`,
        10,
        yOffset
    );
    yOffset += 20;

    const lineOffset = 10;

    console.log(localState);

    localState.questionnaire?.definition?.questions?.map((question, idx) => {
        const lines = doc.splitTextToSize(`${idx + 1}. ${question.text}`, 190);
        lines.forEach((x) => {
            doc.text(x, 10, yOffset);
            yOffset += lineOffset;
        });

        if (['file', 'multiple_files'].includes(question.type)) {
            if (question.answer) {
                doc.text(
                    `${
                        (Array.isArray(question.answer) ? question.answer : [question.answer])?.length
                    } file(s) uploaded`,
                    10,
                    yOffset
                );
                yOffset += lineOffset;
            }
        }

        if (['number', 'string', 'textarea'].includes(question.type)) {
            const lines = doc.splitTextToSize(`${question.answer || 'Not completed yet'}`, 190);
            lines.forEach((x) => {
                doc.text(x, 10, yOffset);
                yOffset += lineOffset;
            });
        }

        if (['date'].includes(question.type)) {
            doc.text(
                `${
                    question.answer ? moment(question.answer, 'YYYY-MM-DD').format('Do MMMM YYYY') : 'Not completed yet'
                }`,
                10,
                yOffset
            );
            yOffset += lineOffset;
        }

        if (['signature'].includes(question.type)) {
            doc.addImage(question.answer, 'PNG', 10, yOffset - 10, 75, 30);
            yOffset += lineOffset;
        }

        if (['dropdown'].includes(question.type)) {
            doc.text(
                `${
                    question.answer
                        ? question.options?.find((x) => x.value === question.answer)?.label
                        : 'Not completed yet'
                }`,
                10,
                yOffset
            );
            yOffset += lineOffset;
        }

        if (question.type !== 'signature') {
            doc.text(`Not Applicable: ${question.notApplicable ? 'yes' : 'no'}`, 10, yOffset);
            yOffset += lineOffset;
        }

        yOffset += 20;

        if (question.comment) {
            const lines = doc.splitTextToSize(`${question.comment}`, 190);
            lines.forEach((x) => {
                doc.text(x, 10, yOffset);
                yOffset += lineOffset;
            });
        }

        if (yOffset > 230) {
            doc.addPage();
            yOffset = 20;
        }
    });

    doc.save('questions_and_answers.pdf');
};

const downloadAllFiles = async (localState, updateLocalState, files) => {
    try {
        updateLocalState({ ...localState, loading: true });
        const response = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/documents/download-multiple`,
            method: 'POST',
            data: { files },
            responseType: 'blob',
        });

        const fileBlob = new Blob([response.data], {
            type: `application/${files.length > 1 ? 'zip' : 'pdf'}`,
        });

        const fileURL = URL.createObjectURL(fileBlob);

        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `${localState.questionnaire?.name}-Finalised.${files.length > 1 ? 'zip' : 'pdf'}`;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(fileURL);

        updateLocalState({ ...localState, loading: false });

        return;
    } catch (e) {
        updateLocalState({ ...localState, loading: false });
        toast('There has been an issue downloading you files', { type: 'error' });
    }
};

const ViewAnswers = ({ updateLocalState, localState }) => {
    let files = [];
    localState.questionnaire?.definition?.questions?.map((question) => {
        if (question.answer && ['file', 'multiple_files'].includes(question.type)) {
            files = [...files, ...(Array.isArray(question.answer) ? question.answer : [question.answer])];
        }
    });

    return (
        <Flex sx={{ flexDirection: 'column', mt: 20 }}>
            {localState.loading ? <Spinner /> : null}
            <Flex>
                <Button
                    sx={{ width: 100, mb: 20 }}
                    onClick={() => updateLocalState({ ...localState, questionnaires_view: 'list' })}
                    variant="light"
                >
                    <i
                        style={{
                            fontSize: '12px',
                            cursor: 'pointer',
                            marginRight: '5px',
                        }}
                        className="fas fa-chevron-left"
                        aria-hidden="true"
                    />
                    Back
                </Button>
                <Button onClick={() => downloadAnswers(localState)} sx={{ ml: 10, height: 35, fontSize: 14 }}>
                    <i
                        style={{
                            fontSize: '12px',
                            cursor: 'pointer',
                            marginRight: '7px',
                        }}
                        className="fas fa-download"
                        aria-hidden="true"
                    />
                    Download Answers
                </Button>
                {files?.length ? (
                    <Button
                        onClick={() => downloadAllFiles(localState, updateLocalState, files)}
                        sx={{ ml: 10, height: 35, fontSize: 14 }}
                    >
                        <i
                            style={{
                                fontSize: '12px',
                                cursor: 'pointer',
                                marginRight: '7px',
                            }}
                            className="fas fa-download"
                            aria-hidden="true"
                        />
                        Download All Files
                    </Button>
                ) : null}
            </Flex>
            <Paragraph sx={{ fontWeight: 600, mb: 20, mt: 20, fontSize: 18 }}>
                {localState.questionnaire?.name}
            </Paragraph>
            <Paragraph sx={{ mb: 30 }}>{localState.questionnaire?.definition?.description}</Paragraph>
            {localState.questionnaire?.definition?.questions?.map((question, idx) => (
                <Flex sx={{ flexDirection: 'column', mb: 30, mt: 20 }} key={`question_${idx}`}>
                    <Paragraph sx={{ mb: 20, fontWeight: 400, fontSize: 18 }}>
                        {idx + 1}. {question.text}
                    </Paragraph>

                    <Flex>
                        {['file', 'multiple_files'].includes(question.type) &&
                            question.answer &&
                            (Array.isArray(question.answer) ? question.answer : [question.answer]).map((item, idx) => (
                                <Button
                                    key={`view_file_upload_${idx}`}
                                    sx={{
                                        height: 40,
                                        width: 150,
                                        fontSize: 14,
                                        mr: 10,
                                    }}
                                    onClick={async () => {
                                        await downloadFile(item, localState, updateLocalState);
                                    }}
                                >
                                    Download File {idx + 1}
                                </Button>
                            ))}
                    </Flex>

                    {['number', 'string', 'textarea']?.includes(question.type) && (
                        <Paragraph>
                            {question.answer?.line1
                                ? Object.values(question.answer)?.join(', ')
                                : question.answer || 'Not completed yet'}
                        </Paragraph>
                    )}

                    {['date']?.includes(question.type) && (
                        <Paragraph>
                            {question.answer
                                ? moment(question.answer, 'YYYY-MM-DD').format('Do MMMM YYYY')
                                : 'Not completed yet'}
                        </Paragraph>
                    )}

                    {['signature']?.includes(question.type) && question.signatureType === 'manual' ? (
                        <SignatureCanvas
                            defaultValue={question.answer}
                            id={`signature_canvas_123`}
                            containerSx={{ mt: 10 }}
                            signatureSx={{ borderRadius: 12 }}
                            width={300}
                            height={120}
                            disableInteraction
                            drawingComplete={(res) => {}}
                        />
                    ) : null}

                    {['signature']?.includes(question.type) && question.signatureType === 'selected' ? (
                        <Paragraph sx={{ fontSize: 25, mt: 10, fontFamily: question.signatureChosen }}>
                            {question.nameForSignature}
                        </Paragraph>
                    ) : null}

                    {['dropdown']?.includes(question.type) && (
                        <Paragraph>
                            {question.answer
                                ? question.options?.find((x) => x.value === question.answer)?.label
                                : 'Not completed yet'}
                        </Paragraph>
                    )}

                    {!['signature']?.includes(question.type) && (
                        <Flex sx={{ flexDirection: 'row', mt: 20, alignItems: 'center' }}>
                            <Paragraph>Not Applicable</Paragraph>
                            <Switch disabled checked={question.notApplicable} sx={{ ml: 30 }} />
                        </Flex>
                    )}

                    {question.comment && (
                        <Flex sx={{ mt: 20, flexDirection: 'row' }}>
                            <Paragraph>Comment:</Paragraph>
                            <Paragraph sx={{ ml: 30 }}>{question.comment}</Paragraph>
                        </Flex>
                    )}
                </Flex>
            ))}
        </Flex>
    );
};

export default ViewAnswers;
