/** @jsxImportSource theme-ui */
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { Flex, Heading, Box, Paragraph, Image } from 'theme-ui';
import Button from '../components/button';
import Spinner from '../components/spinner';

async function verify(currentState: any, updateState: any) {
    try {
        const { username, code } = Object.fromEntries(new URLSearchParams(window.location.search).entries());

        try {
            await Auth.confirmSignUp(username, code);
        } catch (e: any) {
            console.log(e.code);
            await Auth.confirmSignUp(username, code);
        }

        updateState({ ...currentState, status: 'success' });

        return true;
    } catch (error: any) {
        if (error?.message?.includes('Current status is CONFIRMED')) {
            return updateState({ ...currentState, status: 'success' });
        }

        return updateState({ ...currentState, status: 'error' });
    }
}

const Verify = () => {
    const [currentState, updateState] = useState({
        isLoading: true,
        status: 'pending',
    });
    const navigate = useNavigate();
    useEffect(() => {
        verify(currentState, updateState);
    }, []);

    if (currentState.status === 'pending') {
        return (
            <Flex sx={{ width: '100%', height: '100%', flexDirection: 'column' }}>
                {currentState.isLoading && <Spinner />}
            </Flex>
        );
    }

    if (currentState.status === 'success') {
        return (
            <Flex
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    minHeight: '100vh',
                    backgroundColor: 'white',
                }}
            >
                <Box sx={{ width: 326, height: 50 }}>
                    <Image
                        src={`https://cdn.accountingflow.io/af/logo-dark.png`}
                        width="300px"
                        height="auto"
                        alt="refer a friend"
                        data-testid="login-logo"
                    />
                </Box>

                <Heading sx={{ mt: 20 }}>Congratulations</Heading>

                <Paragraph data-testid="success-description" sx={{ mt: 10, textAlign: 'center' }}>
                    You have successfully verified your account!
                </Paragraph>

                <Button sx={{ mt: 60 }} variant="primary-wide" onClick={() => navigate('/')}>
                    Let's go!
                </Button>
            </Flex>
        );
    }

    if (currentState.status === 'error') {
        return (
            <Flex
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    minHeight: '100vh',
                }}
            >
                <Box sx={{ width: 326, height: 58 }}>
                    <Image
                        src={`https://cdn.accountingflow.io/af/logo-dark.png`}
                        width="300px"
                        height="auto"
                        alt="refer a friend"
                        data-testid="login-logo"
                    />{' '}
                </Box>

                <Heading sx={{ mt: 10 }}>Uh oh</Heading>

                <Paragraph data-testid="error-description" sx={{ mt: 20, width: 300, textAlign: 'center' }}>
                    There has been an error verifying your account, please contact support.
                </Paragraph>

                <Button
                    data-testid="redirect-to-login"
                    sx={{ mt: 40 }}
                    variant="primary-wide"
                    onClick={() => navigate('/')}
                >
                    Lets Go
                </Button>
            </Flex>
        );
    }

    return <div />;
};

export default Verify;
