/* eslint-disable no-undef */
import { Button, Flex, Input, Label, Paragraph, Switch, Textarea } from 'theme-ui';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';
import { activityTypes, buildIncrementOptions } from '../../pages/timesheets';
import { loadClientsAsync, loadTasksAsync } from '../tasks/filters';
import Divider from '../divider';
import Spinner from '../spinner';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';

const quickBook = async (localState, updateLocalState, state, updateState) => {
    try {
        const errors = {};

        if (!localState.description) {
            errors.description = true;
        }
        if (localState.billable && localState.billable_amount === '') {
            errors.billable_amount = true;
        }
        if (!localState.service) {
            errors.service = true;
        }
        if (!localState.activity_type) {
            errors.activity_type = true;
        }
        if (!localState.amountOfTime) {
            errors.amountOfTime = true;
        }
        if (!localState.client) {
            errors.client = true;
        }
        if (!localState.date) {
            errors.date = true;
        }

        if (Object.keys(errors).length > 0) {
            return updateLocalState({ ...localState, loading: false, errors });
        }

        updateLocalState({ ...localState, loading: true });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries/quickbook`,
            method: 'POST',
            data: {
                description: localState.description,
                additional_data: {
                    billable: localState.billable,
                },
                billable_amount: localState.billable ? localState.billable_amount : 0,
                activity_type: localState.activity_type?.value,
                service: localState.service,
                time_spent: localState.amountOfTime?.value,
                task_id: localState.task?.value,
                client_id: localState.client?.value,
                date: localState.date,
            },
        });

        window.dispatchEvent(new CustomEvent('timebooked', {}));

        updateLocalState({ ...localState, loading: false });
        updateState({ ...state, showBookTimeModal: false });
    } catch (e) {
        updateLocalState({ ...localState, loading: false });
        toast.error('There has been an issue recording your time entry, please contact support');
    }
};

const summariseDescription = async (state, updateState) => {
    try {
        updateState({ ...state, loading: true });

        const { data: aiResponse } = await axios.post(`${process.env.REACT_APP_AQRU_AI_API}/ai/query`, {
            messages: [
                {
                    role: 'assistant',
                    content: `
                    Please summarise the following description of the work done so that I can complete a timesheet for the accountant.
                    Description: 
                    "${state.description}"
                    Please keep it succinct summarising only the work done.
                    Make it past tense. Don't prefix response with Summary:
                    `,
                },
            ],
        });

        updateState({
            ...state,
            loading: false,
            description: aiResponse.data?.choices[0]?.message?.content,
        });
    } catch (e) {
        updateState({ ...state, loading: false });
        toast('There has been an error summarising your activity', { type: 'error' });
    }
};

const BookTime = ({ state, updateState, organisation }) => {
    const [localState, updateLocalState] = useState({
        date: moment().format('YYYY-MM-DD'),
        loading: false,
        activity_type: null,
        defaultIncrements: organisation?.default_visualisation?.timesheets?.defaultIncrements || 15,
        billable_amount: organisation?.user?.additional_data?.billable_hourly_amount || '',
    });

    const timeRecordingOptions = buildIncrementOptions(localState.defaultIncrements || 15).slice(1);

    const queryParams = new URLSearchParams(window.location.search);
    const idParam = queryParams.get('id');

    useEffect(() => {
        (async () => {
            try {
                if (window.location.href?.includes('clients')) {
                    updateLocalState({
                        ...localState,
                        loading: true,
                    });
                    const { data } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/clients/${idParam}`);
                    updateLocalState({
                        ...localState,
                        loading: false,
                        client: {
                            value: data.raw_id,
                            uuid: data.id,
                            services: data.client_data?.new_client_services_offered || [],
                            label: data.name,
                            fullData: data,
                        },
                    });
                }
                if (window.location.href?.includes('tasks')) {
                    updateLocalState({
                        ...localState,
                        loading: true,
                    });
                    const { data } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/tasks/${idParam}`);
                    updateLocalState({
                        ...localState,
                        loading: false,
                        task: {
                            value: data.raw_id,
                            uuid: data.id,
                            service: data.additional_data?.service,
                            label: data.title,
                            fullData: data,
                        },
                        service: data.additional_data?.service,
                        client: {
                            value: data.client?.raw_id,
                            uuid: data.client?.uuid,
                            services: data.client?.services || [],
                            label: data.client?.name,
                        },
                    });
                }
            } catch (e) {
                updateLocalState({
                    ...localState,
                    loading: false,
                });
            }
        })();
    }, []);

    return (
        <Flex sx={{ flexDirection: 'column', ml: 15, mt: 15 }}>
            {localState.loading ? <Spinner /> : null}
            <Paragraph sx={{ mt: 10 }}>Book some time</Paragraph>
            <Divider width="95%" />
            <Flex>
                <Flex sx={{ flexDirection: 'column' }}>
                    <Label sx={{}} id="date-label">
                        Date
                    </Label>
                    <Input
                        onChange={(e) => updateLocalState({ ...localState, date: e.target.value, errors: {} })}
                        value={localState.date}
                        sx={{ mt: 10, width: 200, border: localState.errors?.date ? '1px solid red' : 'auto' }}
                        type="date"
                    />
                </Flex>
                <Flex sx={{ flexDirection: 'column', ml: 20 }}>
                    <Label sx={{ mb: 10 }} id="date-label">
                        Time spent
                    </Label>
                    <ReactSelect
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '150px',
                                minHeight: '40px',
                                marginTop: '0px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                fontSize: '14px',
                                border: localState.errors?.amountOfTime ? '1px solid red' : 'auto',
                            }),
                        }}
                        onChange={(c) => {
                            updateLocalState({
                                ...localState,
                                amountOfTime: c,
                                errors: {},
                            });
                        }}
                        placeholder="Select..."
                        value={localState.amountOfTime}
                        options={timeRecordingOptions}
                    />
                </Flex>
            </Flex>
            <Label sx={{ mt: 10 }} id="activity-type-label">
                Type of activity
            </Label>
            <ReactSelect
                aria-labelledby="activity-type-label"
                styles={{
                    control: (provided) => ({
                        ...provided,
                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                        width: '280px',
                        minHeight: '40px',
                        marginTop: '10px',
                        borderRadius: 10,
                        marginBottom: '10px',
                        fontSize: '14px',
                        border: localState.errors?.activity_type ? '1px solid red' : 'auto',
                    }),
                }}
                onChange={async (c) => {
                    updateLocalState({
                        ...localState,
                        activity_type: c,
                        errors: {},
                    });
                }}
                placeholder="Select..."
                value={localState.activity_type}
                options={activityTypes}
            />
            <Label sx={{ mt: 0 }} id="client-label">
                Client
            </Label>
            <Flex>
                <AsyncSelect
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                            width: '400px',
                            minHeight: '40px',
                            marginTop: '10px',
                            borderRadius: 10,
                            marginBottom: '10px',
                            fontSize: '14px',
                            border: localState.errors?.client ? '1px solid red' : 'auto',
                        }),
                    }}
                    aria-labelledby="client-label"
                    onChange={async (c) => {
                        updateLocalState({
                            ...localState,
                            client: c,
                            errors: {},
                        });
                    }}
                    placeholder="Select client"
                    value={localState.client}
                    loadOptions={loadClientsAsync(localState, updateLocalState)}
                />
                {localState.client ? (
                    <a href={`/clients?id=${localState.client.uuid}`} target="_blank" rel="noreferrer">
                        <Button sx={{ ml: 10, mt: 10 }}>
                            <i
                                style={{
                                    marginRight: '7px',
                                }}
                                className={`fas fa-external-link-alt`}
                            />
                            Open
                        </Button>
                    </a>
                ) : null}
            </Flex>
            <Label sx={{ mt: 10 }} id="task-label">
                Task (Optional)
            </Label>
            <Flex>
                <AsyncSelect
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                            width: '400px',
                            minHeight: '40px',
                            marginTop: '10px',
                            borderRadius: 10,
                            marginBottom: '10px',
                            fontSize: '14px',
                            border: localState.errors?.task ? '1px solid red' : 'auto',
                        }),
                    }}
                    aria-labelledby="task-label"
                    onChange={async (c) => {
                        updateLocalState({
                            ...localState,
                            service: c.service,
                            task: c,
                            errors: {},
                        });
                    }}
                    placeholder="Select task"
                    value={localState.task}
                    defaultOptions={localState.defaultTasksForDropDown || []}
                    loadOptions={loadTasksAsync(
                        localState,
                        updateLocalState,
                        localState.client?.value,
                        'order_by_created_at'
                    )}
                />
                {localState.task ? (
                    <a href={`/tasks?id=${localState.task.uuid}`} target="_blank" rel="noreferrer">
                        <Button sx={{ ml: 10, mt: 10 }}>
                            <i
                                style={{
                                    marginRight: '7px',
                                }}
                                className={`fas fa-external-link-alt`}
                            />
                            Open
                        </Button>
                    </a>
                ) : null}
            </Flex>
            <Label sx={{ mt: 10 }} id="client-label">
                Service
            </Label>
            <ReactSelect
                aria-labelledby="service-label"
                styles={{
                    control: (provided) => ({
                        ...provided,
                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                        width: '280px',
                        minHeight: '40px',
                        marginTop: '10px',
                        borderRadius: 10,
                        marginBottom: '10px',
                        border: localState.errors?.service ? '1px solid red' : 'auto',
                        fontSize: '14px',
                    }),
                }}
                onChange={async (c) => {
                    updateLocalState({
                        ...localState,
                        service: c.value,
                        errors: {},
                    });
                }}
                placeholder="Select..."
                value={
                    localState.service
                        ? {
                              value: localState.service,
                              label: Object.entries(organisation?.workflow_definition?.services || {})?.find(
                                  ([key, value]) => key === localState.service
                              )?.[1]?.label,
                          }
                        : null
                }
                options={[
                    ...(Object.entries(organisation?.workflow_definition?.services || {})?.map(([key, value]) => ({
                        value: key,
                        label: value.label,
                    })) || []),
                ]}
            />
            <Flex>
                <Flex sx={{ flexDirection: 'column', mt: 10 }}>
                    <Flex sx={{ alignItems: 'center', mb: 10 }}>
                        <Label sx={{ width: 80 }} id="description-label">
                            Description
                        </Label>
                        {localState.description ? (
                            <Button
                                sx={{ ml: 20 }}
                                onClick={async () => {
                                    await summariseDescription(localState, updateLocalState);
                                }}
                            >
                                <i
                                    style={{
                                        fontSize: '14px',
                                        cursor: 'pointer',
                                        marginRight: '5px',
                                    }}
                                    className="fas fa-sync"
                                    aria-hidden="true"
                                />
                                Improve with AI
                            </Button>
                        ) : null}
                    </Flex>
                    <Textarea
                        aria-labelledby="description-label"
                        rows={8}
                        sx={{
                            width: 400,
                            border: localState.errors?.description ? '1px solid red' : 'auto',
                        }}
                        value={localState.description}
                        onChange={(e) => {
                            updateLocalState({
                                ...localState,
                                description: e.target.value,
                                errors: {},
                            });
                        }}
                    />
                </Flex>
                <Flex sx={{ flexDirection: 'column', ml: 20 }}>
                    <Label sx={{ mt: 0, width: 100 }} id="billable_per_hr">
                        Billable (/hr)
                    </Label>

                    <Switch
                        aria-labelledby="billable_per_hr"
                        sx={{ mt: 10, ml: 0 }}
                        checked={localState.billable}
                        onClick={() => {
                            updateLocalState({
                                ...localState,
                                billable: !localState.billable,
                            });
                        }}
                    />

                    {localState.billable && (
                        <Input
                            type="number"
                            value={localState.billable_amount}
                            placeholder="Amount billable / hr"
                            onChange={(e) => {
                                updateLocalState({
                                    ...localState,
                                    billable_amount: e.target.value,
                                    errors: {},
                                });
                            }}
                            sx={{
                                width: 150,
                                mt: 20,
                                border: localState.errors?.billable_amount ? '1px solid red' : 'auto',
                            }}
                        />
                    )}
                </Flex>
            </Flex>
            <Button
                onClick={async () => {
                    await quickBook(localState, updateLocalState, state, updateState);
                }}
                sx={{ mt: 20, width: 100 }}
            >
                <i className="fa fa-save" style={{ marginRight: '7px' }} />
                Save
            </Button>
        </Flex>
    );
};

export default BookTime;
