/** @jsxImportSource theme-ui */
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Flex, Box, Image, Paragraph } from 'theme-ui';
import { useNavigate } from 'react-router-dom';
import { actions as menuActions } from '../store/reducers/menu';
import { useEffect, useState } from 'react';

const returnMenuItems = (
    currentPage: any,
    navigate: any,
    closeAllMenus: any,
    accountSummary: any,
    user: any,
    copy: any,
    settingsJourney: any,
    updateSettingsJourney: any,
    enableAll = false,
    modules: any
) => {
    const items = [
        {
            name: 'Home',
            icon: 'fal fa-home',
            url: '/home',
            testId: 'sidebar-home',
        },
        {
            name: 'Clients',
            icon: 'fal fa-building',
            url: '/clients',
            testId: 'sidebar-dashboard',
        },
        {
            name: 'Tasks',
            icon: 'fal fa-list',
            url: '/tasks',
            testId: 'sidebar-tasks',
        },
        {
            name: 'Email',
            icon: 'fal fa-inbox',
            url: '/inbox',
            testId: 'sidebar-chat',
        },
        {
            name: 'Post',
            icon: 'fal fa-mail-bulk',
            url: '/inbound-emails',
        },
        {
            name: 'Questionnaires',
            icon: 'fal fa-question',
            type: 'questionnaires',
            url: '/questionnaires',
            testId: 'sidebar-questionnaires',
            fontSize: '9px',
        },
        {
            name: 'Proposals',
            icon: 'fal fa-handshake',
            type: 'proposals',
            url: '/proposals',
            testId: 'sidebar-proposals',
            fontSize: '9px',
        },

        {
            name: 'Timesheets',
            icon: 'fal fa-clock',
            url: '/timesheets',
            testId: 'sidebar-timesheets',
        },
        {
            name: 'Settings',
            icon: 'fal fa-cog',
            type: 'settings',
            url: '/settings',
            testId: 'sidebar-settings',
        },

        {
            name: 'Sign out',
            icon: 'fal fa-sign-out-alt',
            url: '/',
            logout: true,
            testId: 'sidebar-sign-out',
        },
    ];

    const menuItems = items
        // @ts-ignore
        .filter((item) => enableAll || !item.moduleKey || modules?.[item.moduleKey])
        .map((item, i) => {
            const component = (
                <Flex
                    sx={{
                        width: '190px',
                        height: '60px',
                        backgroundColor: currentPage === item.name ? 'primaryDarker' : 'white',
                        color: currentPage === item.name ? 'white' : 'text',
                        '&:hover': {
                            backgroundColor: 'primaryDarker',
                            color: 'white',
                            fontWeight: '800',
                        },
                        borderTopRightRadius: '6px',
                        borderBottomRightRadius: '6px',
                        cursor: 'pointer',
                        alignItems: 'center',
                        mb: '5px',
                    }}
                    key={`${item.name}`}
                    data-testid={item.testId}
                    onClick={async () => {
                        if (item.logout) {
                            await Auth.signOut();
                            window.location.assign('/');
                        }
                        // @ts-ignore
                        if (item.type === 'chat' && window.toggleChatMini) {
                            // @ts-ignore
                            window.toggleChatMini();
                        }
                    }}
                >
                    <Box
                        sx={{
                            fontSize: '23px',
                            ml: 20,
                            width: 30,
                            fontWeight: 300,
                        }}
                        className={`${item.icon}`}
                    />
                    <Paragraph
                        sx={{
                            fontSize: '12px',
                            textAlign: 'center',
                            ml: 10,
                            mt: '0px',
                            fontWeight: 300,
                        }}
                    >
                        {item.name}
                    </Paragraph>
                </Flex>
            );

            if (item.logout || item.type === 'chat') {
                return component;
            }

            return (
                // @ts-ignore
                <a style={{ textDecoration: 'none' }} key={`${item.name}`} href={item.url}>
                    {component}
                </a>
            );
        });

    return (
        <Flex
            sx={{
                flexDirection: 'column',
                ml: 20,
                mt: 0,
                padding: 15,
                border: '1px solid #E0E0E0',
                borderRadius: 20,
                background: '#FFF',
                width: '220px',
                position: 'fixed',
            }}
        >
            {menuItems}
        </Flex>
    );
};

const SidebarComponent = ({
    currentPage,
    showSideMenu,
    logout,
    showTopMenu,
    toggleSideMenu,
    closeAllMenus,
    accountSummary,
    user,
    copy,
    settingsJourney,
    organisation,
    updateSettingsJourney,
}: any) => {
    const navigate = useNavigate();

    const allowAll = organisation === null;

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        // Listen to window resize events
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Flex data-testid="sidebar" sx={{ position: 'relative' }}>
            <Flex
                sx={{
                    // height: '100%',
                    // position: 'fixed',
                    // top: 0,
                    // left: 0,
                    flexDirection: 'column',
                    zIndex: 9999999,
                }}
            >
                {returnMenuItems(
                    currentPage,
                    navigate,
                    closeAllMenus,
                    accountSummary,
                    user,
                    copy,
                    settingsJourney,
                    updateSettingsJourney,
                    allowAll,
                    organisation?.modules
                )}
                {/* 
                {windowHeight > 930 ? (
                    <Box
                        sx={{
                            height: '60px',
                            mt: '53px',
                            ml: 'auto',
                            mr: 'auto',
                            mb: '10px',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            position: 'absolute',
                            bottom: '10px',
                            left: '25px',
                        }}
                        onClick={() => navigate('/home')}
                    >
                        <Image
                            src={`https://cdn.accountingflow.io/af/icon-light.png`}
                            width="60px"
                            height="auto"
                            alt="Home"
                            data-testid="login-logo"
                        />
                    </Box>
                ) : null} */}
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    toggleSideMenu: () => dispatch({ type: menuActions.TOGGLE_SIDE_MENU }),
    closeAllMenus: () => dispatch({ type: menuActions.CLOSE_ALL_MENUS }),
});

const mapStateToProps = (state: any) => ({
    showSideMenu: state?.menu?.showSideMenu,
    showTopMenu: state?.menu?.showTopMenu,
    organisation: state?.account?.organisation,
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarComponent);
