/* eslint-disable no-empty */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { Auth } from 'aws-amplify';
import axios from 'axios';
import WebSocket from 'isomorphic-ws';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Paragraph, Box, Image } from 'theme-ui';
import { ChatBot, ChatHistory, FileSelect, FreshChat, Initial, Title } from '../components/chat/chatbot';
import { loadChats, loadDocuments } from '../components/documents/load-docs';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import { actions as menuActions } from '../store/reducers/menu';
import { actions as userActions } from '../store/reducers/users';
import Overlay from '../components/overlay/overlay';

export const downloadCsvOfChatHistory = (chatHistory) => {
    const headers = 'Type,Text,Timestamp';

    const hiddenElement = document.createElement('a');
    const href = encodeURI(
        `${headers}\n${chatHistory
            .map((row) => `"${row.type.toUpperCase()}","${row.text}","${row.timestamp}"`)
            .join('\n')}`
    );

    hiddenElement.href = `data:text/csv;charset=utf-8,${href}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'ChatHistory.csv';

    return hiddenElement.click();
};

export const downloadTranscript = async (state, updateState, id) => {
    updateState({ ...state, loading: true });

    const {
        data: { url },
    } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/documents/${id}/transcript`, {});

    const { data } = await axios.get(url);

    updateState({ ...state, loading: false });

    const encoded = 'data:text/plain;charset=utf-8,' + encodeURIComponent(data);

    const a = document.createElement('a');
    a.href = encoded;
    a.download = 'Transcript.txt';
    a.click();
};

let ws;

const queryDocument = async (state, updateState, stateRef) => {
    const newState = {
        ...stateRef?.current,
        loading: true,
        chatBotAnswer: '',
        chatBotError: '',
        documentToQuery: {
            ...stateRef?.current.documentToQuery,
            history: [
                ...stateRef.current.documentToQuery.history,
                {
                    type: 'question',
                    text: stateRef?.current.documentQuery,
                    timestamp: moment.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]'),
                },
            ],
        },
    };
    const TheChatQuery = {
        document_ids: stateRef?.current?.selectedDocs || [],
        chat_id: stateRef?.current.documentToQuery.id,
        question: stateRef?.current.documentQuery,
    };

    console.log('chat query:', TheChatQuery);
    ws.send(JSON.stringify(TheChatQuery));

    updateState({ ...newState, documentQuery: '', error: null });
};

const defaultState = {
    loading: true,
    forceUploadDocs: false,
    initialLoad: true,
    partialLoading: false,
    error: null,
    mode: 'initial', // new_chat || existing_chat || initial
    sidebar: 'files', // files || chats
    selectedDocs: [],
    docOffset: 0,
    docLimit: 6,
    docTotal: 0,
    documents: [],
    selectLabels: [],
    chats: [],
    totalChats: 0,
    chatHistory: [],
    documentToQuery: {},
    documentQuery: '',
    newChatLabel: '',
    statuses: 'PROCESSED',
    chatsOffset: 0,
    chatsLimit: 10,
};

const ChatContainer = ({ userData, accountSummary, organisation }) => {
    const [state, updateState] = useState(defaultState);
    const [user, updateUser] = useState(userData);

    const navigate = useNavigate();
    const stateRef = useRef();
    stateRef.current = state;

    useEffect(() => {
        (async () => {
            try {
                const { attributes } = await Auth.currentAuthenticatedUser();

                const session = await Auth.currentSession();
                updateUser(attributes);
                const [{ documents, labels: selectLabels, total: docTotal }, { chats, total: totalChats }] =
                    await Promise.all([
                        loadDocuments(state.docOffset, state.docLimit, null, null, null, 'PROCESSED'),
                        loadChats({
                            offset: state.chatsOffset,
                            limit: state.chatsLimit,
                        }),
                    ]);

                let selectedDocs = [];
                const amountOfChats = chats?.length || 0;
                let mode = amountOfChats < 1 ? 'new_chat' : 'existing_chat';

                if (amountOfChats > 0) {
                    const history = chats[0]?.history || [];
                    if (history?.length > 0) {
                        selectedDocs = history[history?.length - 1]?.document_ids || [];
                    }
                }

                console.log(chats[0]);

                updateState({
                    ...state,
                    loading: false,
                    forceUploadDocs: documents?.length < 1,
                    initialLoad: false,
                    mode,
                    selectedDocs,
                    documentToQuery: chats?.length < 1 ? {} : chats[0],
                    documents,
                    selectLabels,
                    docTotal,
                    chats,
                    totalChats,
                });

                ws = new WebSocket(`${process.env.REACT_APP_WS_URL}?token=${session.getAccessToken().getJwtToken()}`);
                ws.onopen = function open() {
                    console.log('connected');
                };
                ws.onclose = function close() {
                    console.log('disconnected');
                };
                ws.onmessage = function incoming({ data }) {
                    console.log(data);
                    if (typeof data === 'string') {
                        const { search_result } = JSON.parse(data);

                        console.log('ref : ', stateRef.current);
                        updateState({
                            ...stateRef.current,
                            loading: false,
                            chatBotError: '',
                            chatBotAnswer: search_result,
                            documentToQuery: {
                                ...stateRef.current.documentToQuery,
                                history: [
                                    ...stateRef.current.documentToQuery.history,
                                    {
                                        type: 'answer',
                                        text: search_result,
                                        timestamp: moment.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]'),
                                    },
                                ],
                            },
                        });
                    }
                };
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            (async () => {
                try {
                    if (state.mode !== 'new_chat' || state?.initialLoad) {
                        return;
                    }
                    const {
                        documents,
                        labels: selectLabels,
                        total: docTotal,
                    } = await loadDocuments(state.docOffset, state.docLimit, null, null, null, 'PROCESSED');

                    return updateState({ ...state, loading: false, documents, selectLabels, docTotal });
                } catch (e) {
                    console.log(e);
                }
            })();
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [state]);

    useEffect(() => {
        (async () => {
            try {
                if (!state?.initialLoad) {
                    const {
                        documents,
                        labels: selectLabels,
                        total: docTotal,
                    } = await loadDocuments(state.docOffset, state.docLimit, null, null, null, 'PROCESSED');

                    return updateState({ ...state, loading: false, documents, selectLabels, docTotal });
                }
            } catch (e) {}
        })();
    }, [state.docOffset]);

    useEffect(() => {
        (async () => {
            try {
                if (!state?.initialLoad) {
                    const { chats, total: totalChats } = await loadChats({
                        offset: state.chatsOffset,
                        limit: state.chatsLimit,
                    });

                    return updateState({ ...state, loading: false, chats, totalChats });
                }
            } catch (e) {}
        })();
    }, [state.chatsOffset]);

    return (
        <>
            <Flex
                sx={{
                    height: '100%',
                    width: '100vw',
                    flexDirection: 'column',
                    backgroundColor: 'primary',
                    position: 'relative',
                }}
                data-testid="chat-screen"
            >
                {state.showDeleteConfirmOverlay && (
                    <Overlay
                        size="small"
                        updateOverlay={() => updateState({ ...state, showDeleteConfirmOverlay: false })}
                        embeddedComponent={
                            <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
                                <Paragraph
                                    sx={{ fontSize: 16, fontWeight: 400, mt: 70, textAlign: 'center', ml: 20, mr: 20 }}
                                >
                                    Are you sure you wish to delete this chat and its history?
                                </Paragraph>
                                <Flex sx={{ width: 150, mt: 50, justifyContent: 'space-between' }}>
                                    <Button
                                        sx={{
                                            backgroundColor: 'white',
                                            color: '#000',
                                            border: '1px solid #CCC',
                                            width: 70,
                                        }}
                                        onClick={() => updateState({ ...state, showDeleteConfirmOverlay: false })}
                                    >
                                        No
                                    </Button>
                                    <Button
                                        sx={{ backgroundColor: 'primary', width: 70 }}
                                        onClick={() => state.deleteCallback()}
                                    >
                                        Yes
                                    </Button>
                                </Flex>
                            </Flex>
                        }
                    />
                )}

                <Flex
                    sx={{
                        width: '100vw',
                        flexDirection: 'column',
                    }}
                >
                    <Sidebar currentPage="Chats" overlay={null} accountSummary={accountSummary} user={user} copy={{}} />
                    {state.loading && <Spinner />}(
                    <>
                        <Flex
                            sx={{
                                width: 'calc(100% - 110px)',
                                height: 'calc(100vh - 50px)',
                                border: '0px solid lightgrey',
                                backgroundColor: '#ffffff',
                                borderTopLeftRadius: '25px',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                ml: '120px',
                            }}
                        >
                            <Flex sx={{ alignItems: 'center', ml: 30 }}>
                                <Image
                                    sx={{ mt: 40, width: 300, mb: 0, ml: 0 }}
                                    src={'https://cdn.accountingflow.io/af/logo-dark.png'}
                                    alt=""
                                />
                                <i
                                    style={{
                                        marginLeft: 10,
                                        marginRight: 20,
                                        marginTop: 40,
                                        fontSize: '23px',
                                        cursor: 'pointer',
                                    }}
                                    className="fal fa-handshake"
                                    aria-hidden="true"
                                />
                                <Image
                                    sx={{ mt: 50, height: 20, width: 300, mb: 10, ml: 0 }}
                                    src={organisation?.default_visualisation?.logo}
                                    alt=""
                                />
                            </Flex>

                            <Title state={state} updateState={updateState} />
                            {state?.loading && <Spinner />}

                            {state?.forceUploadDocs && (
                                <Box sx={{ mt: '0px', ml: '30px' }}>
                                    <Paragraph sx={{ mt: '50px' }}>
                                        You need to upload some files before you can do this.
                                    </Paragraph>
                                    <Button
                                        variant="primary"
                                        sx={{
                                            width: '180px',
                                            mt: '30px',
                                            backgroundColor: 'primary',
                                        }}
                                        data-testid="submit"
                                        onClick={async () => navigate('/')}
                                    >
                                        Upload Files
                                        <i
                                            style={{
                                                marginTop: '0px',
                                                fontSize: '13px',
                                                marginLeft: '9px',
                                            }}
                                            className={`fas fa-plus-circle`}
                                        />
                                    </Button>
                                </Box>
                            )}

                            {!state?.forceUploadDocs && (
                                <Flex sx={{ alignItems: 'flex-start' }}>
                                    {/* 
                                    1 MAIN - the chat 
                                */}
                                    {state?.mode === 'existing_chat' && (
                                        <Flex sx={{ ml: 30 }}>
                                            <ChatHistory
                                                state={state}
                                                updateState={updateState}
                                                queryDocument={queryDocument}
                                            />
                                            <Flex
                                                sx={{ width: '15px', pl: '25px', borderRight: '1px solid lightgray' }}
                                            />
                                            {/* <Flex sx={{ width: '10px', pl: '15px' }} /> */}
                                            <ChatBot
                                                state={state}
                                                updateState={updateState}
                                                queryDocument={queryDocument}
                                                stateRef={stateRef}
                                                downloadCsvOfChatHistory={downloadCsvOfChatHistory}
                                                downloadTranscript={downloadTranscript}
                                                user={user}
                                            />
                                            <Flex
                                                sx={{ width: '0px', pl: '0px', borderRight: '1px solid lightgray' }}
                                            />
                                        </Flex>
                                    )}

                                    {state?.mode === 'new_chat' && (
                                        <FreshChat state={state} updateState={updateState} />
                                    )}

                                    {state?.mode === 'initial' && <Initial state={state} updateState={updateState} />}

                                    {/* 
                                    2 SIDE BAR - chats | files 
                                */}
                                    {!state.initialLoad && (
                                        <Flex
                                            sx={{
                                                flexDirection: 'column',
                                                width: '400px',
                                                ml: '15px',
                                                pl: '4px',
                                            }}
                                        >
                                            {state?.sidebar === 'files' && (
                                                <FileSelect state={state} updateState={updateState} />
                                            )}
                                        </Flex>
                                    )}
                                </Flex>
                            )}

                            {state.error && (
                                <Paragraph
                                    sx={{
                                        color: 'red',
                                        minHeight: '20px',
                                        fontWeight: '600',
                                        mt: '30px',
                                        ml: '-180px',
                                        mb: 20,
                                        width: '100%',
                                        textAlign: 'center',
                                        border: '0px solid red',
                                    }}
                                >
                                    {state.error}
                                </Paragraph>
                            )}
                        </Flex>
                    </>
                    )
                </Flex>
            </Flex>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
    logout: () => dispatch({ type: 'LOGOUT' }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
        isMobile: account.isMobile,
        accountSummary: account.accountSummary,
        organisation: account.organisation,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatContainer);
