/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-undef */
import axios from 'axios';
import moment from 'moment';
import { PDFDocument } from 'pdf-lib';
import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import ReactSelect from 'react-select';
import { Box, Button, Flex, Input, Label, Paragraph, Switch, Textarea } from 'theme-ui';
import Divider from '../divider';
import Overlay from '../overlay/overlay';
import { deepClone } from '../settings/manage-workflow';
import Spinner from '../spinner';
import CompanySignature from './company-signature';
import { getContactsFilteredByThoseUsingClientPortal } from './contact-utils';
import DocView from './doc-view';
import PdfFrame from './pdf-frame';
import SelectedClientUser from './selected-client-user';
import ConfirmDialog from '../tasks/confirm-dialog';
import { toast, ToastContainer } from 'react-toastify';
import { loadTasksAsync } from '../tasks/filters';
import AsyncSelect from 'react-select/async';
import theme from '../../theme';

const signatureComponentColors = ['yellow', '#ADD8E6', 'pink', 'green', 'red'];

const signatureSizes = [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
];

const SignatureOrderRow = ({ user, state, updateState, idx }) => {
    const [collected, drag, dragPreview] = useDrag(
        () => ({
            type: 'user_sig',
            item: { user, idx },
            end: (item, monitor) => {
                if (state.idxSigMovingTo === state.idxOfSigItemMoving) return;

                const newUsers = deepClone([...(state.users || [])]);

                const itemToMove = deepClone(newUsers[state.idxOfSigItemMoving]);
                const existingAtLocation = deepClone(newUsers[state.idxSigMovingTo]);

                newUsers[state.idxSigMovingTo] = itemToMove;
                newUsers[state.idxOfSigItemMoving] = existingAtLocation;

                updateState({
                    ...state,
                    users: newUsers,
                    idxSigMovingTo: -1,
                    idxOfSigItemMoving: -1,
                });
            },
        }),
        [state.idxSigMovingTo, state.idxOfSigItemMoving, state.users, user]
    );

    const [, drop] = useDrop(
        () => ({
            accept: 'user_sig',
            hover(item) {
                updateState({
                    ...state,
                    idxOfSigItemMoving: idx,
                    idxSigMovingTo: item.idx,
                });
            },
        }),
        [state.idxSigMovingTo, state.idxOfSigItemMoving]
    );

    return collected.isDragging ? (
        <div ref={dragPreview} />
    ) : (
        <div ref={(node) => drag(drop(node))} {...collected}>
            <Flex key={`user_${user.value}`} sx={{ padding: 10, paddingBottom: 0, flexDirection: 'column' }}>
                <Paragraph>{user.label}</Paragraph>
                <Divider />
            </Flex>
        </div>
    );
};

const DefineSignatureOrderModal = ({ okHandler, state, updateState }) => {
    let usersSelected;
    if (Array.isArray(state.sequence) && state.sequence?.length) {
        usersSelected = state.sequence;
    } else {
        usersSelected = state.pdfs?.map((pdf) => pdf.clientUsersSelected || []).flat();
        const uniques = {};
        usersSelected.forEach((item) => {
            uniques[item.value] = item;
        });
        usersSelected = Object.values(uniques).map((item, idx) => ({ ...item, idx }));
    }

    const [sigOrderState, updateSigOrderState] = useState({ users: [...usersSelected] });

    return (
        <Flex sx={{ mt: 20, ml: 20, mr: 20, flexDirection: 'column' }}>
            <Paragraph sx={{ fontWeight: 600 }}>Drag to reorder</Paragraph>
            <Divider />

            {!sigOrderState.users?.length && <Paragraph>Please add some signatures to your documents first</Paragraph>}

            {sigOrderState.users?.map((user, userIdx) => (
                <SignatureOrderRow
                    key={`user_${user.value}`}
                    idx={userIdx}
                    user={user}
                    state={sigOrderState}
                    updateState={updateSigOrderState}
                />
            ))}
            <Button
                sx={{ width: 100, height: 40, mt: 20 }}
                onClick={() => {
                    okHandler(sigOrderState.users);
                }}
            >
                OK
            </Button>
        </Flex>
    );
};

const ArchiveReasonDialog = ({ okHandler, state, updateState }) => {
    const [localState, updateLocalState] = useState({
        reason: '',
    });
    return (
        <Flex sx={{ ml: 20, flexDirection: 'column', mt: 25, width: 400 }}>
            <Paragraph>Please specify a reason for archiving this document</Paragraph>
            <Textarea
                value={localState.reason}
                onChange={(e) => updateLocalState({ ...localState, reason: e.target.value })}
                sx={{ mt: 10, mb: 10, width: 350 }}
            />
            <Flex>
                <Button
                    variant="light"
                    onClick={() => {
                        updateState({ ...state, showArchiveConfirmation: false });
                    }}
                    sx={{ width: 80, height: 40, mt: 20, mr: 10 }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        if (!localState.reason) {
                            return;
                        }
                        okHandler && okHandler(localState.reason);
                    }}
                    sx={{ width: 80, height: 40, mt: 20 }}
                >
                    OK
                </Button>
            </Flex>
        </Flex>
    );
};

const TextInputLabelModal = ({ okHandler, state, updateState }) => {
    const [localState, updateLocalState] = useState({
        label: '',
        typeOfTextInput: {
            value: 'date_with_slashes',
            label: 'Date (DD/MM/YYYY)',
        },
    });
    return (
        <Flex sx={{ ml: 20, flexDirection: 'column', mt: 25, width: 400 }}>
            <Paragraph>Enter a label for in the input</Paragraph>
            <Input
                value={localState.label}
                onChange={(e) => updateLocalState({ ...localState, label: e.target.value })}
                sx={{ mt: 10, mb: 10, width: 350 }}
            />
            <Paragraph>Select the type of input</Paragraph>
            <ReactSelect
                styles={{
                    control: (provided) => ({
                        ...provided,
                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                        width: '350px',
                        minHeight: '40px',
                        marginTop: '10px',
                        borderRadius: 10,
                        border: `1px solid #a3a69b`,
                        fontSize: '14px',
                    }),
                }}
                onChange={async (c) => {
                    updateLocalState({
                        ...localState,
                        typeOfTextInput: c,
                    });
                }}
                placeholder="Select type of input"
                value={
                    localState?.typeOfTextInput || {
                        value: 'date_with_slashes',
                        label: 'Date (DD/MM/YYYY)',
                    }
                }
                options={[
                    {
                        value: 'text',
                        label: 'Text',
                    },
                    {
                        value: 'date',
                        label: 'Date (DD-MM-YYYY)',
                    },
                    {
                        value: 'date_with_slashes',
                        label: 'Date (DD/MM/YYYY)',
                    },
                ]}
            />
            <Flex>
                <Button
                    variant="light"
                    onClick={() => {
                        updateState({ ...state, showTextInputLabelModal: false });
                    }}
                    sx={{ width: 80, height: 40, mt: 20, mr: 10 }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        if (!localState.label) {
                            return;
                        }
                        updateState({ ...state, showTextInputLabelModal: false });
                        okHandler && okHandler('textInput', localState.label, localState.typeOfTextInput.value);
                    }}
                    sx={{ width: 80, height: 40, mt: 20 }}
                >
                    OK
                </Button>
            </Flex>
        </Flex>
    );
};

export const SMALL_SIGNATURE_SCALE_FACTOR = 0.6;
export const LARGE_SIGNATURE_SCALE_FACTOR = 1.2;

const svg = (idx, text, size) => {
    let width = 200;
    let height = 45;
    let fontSize = 13;

    return `<svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg">
    <!-- Transparent yellow box -->
    <rect width="100%" height="100%" fill="${signatureComponentColors[idx] || 'yellow'}" fill-opacity="0.5" />
    
    <text x="10%" y="50%" dominant-baseline="middle" text-anchor="left" fill="black" font-size="${fontSize}" font-family="Arial">
        ${text}
    </text>
</svg>`;
};

export const textInputSvg = (id, label = 'Text input') => textInputSvgWithoutBorder(id, label);

export const textInputSvgWithoutBorder = (id, label = 'Text input') => {
    return `<svg width="300" height="35" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
            <rect 
                x="0" y="0" width="98%" 
                height="98%" rx="5" ry="5" 
                fill="white" stroke-width="1"
            />
            <text 
                id="${id}"
                x="5" y="15" 
                fill="black" 
                width="98%"
                height="98%"
                font-size="16px" 
                text-anchor="start" dominant-baseline="middle" 
                style="font-family: Arial, sans-serif;">
                ${label}
            </text>
        </svg>
    `;
};

const renderExistingSignatures = (localState) => {
    localState.pdfs[localState.pdfIndex].signatureLocations
        ?.filter((x) => x.page === localState.page)
        ?.forEach((item) => {
            const signedObject = localState.pdfs?.[localState.pdfIndex]?.signature_locations?.[item.clientUserId]?.find(
                (x) => {
                    return x.clientUserId === item.clientUserId && x.page === localState.page && x.type === item.type;
                }
            );

            if (signedObject) {
                drawImageOnCanvas(
                    signedObject.type === 'signature'
                        ? localState.pdfs?.[localState.pdfIndex]?.signature_locations?.[
                              `${item.clientUserId}_last_signature`
                          ]
                        : signedObject.svgText,
                    signedObject.x,
                    signedObject.y,
                    `canvas-wrapper-${localState.pdfIndex}`,
                    {
                        isCompanySignature: signedObject.isCompanySignature,
                        newFontSize: signedObject.newFontSize,
                        font: signedObject.font,
                        uniqueId: signedObject.uuid,
                        type: signedObject.type,
                        signedObject: true,
                    }
                );
            } else {
                drawImageOnCanvas(item.svgText, item.x, item.y, `canvas-wrapper-${localState.pdfIndex}`, {
                    isCompanySignature: item.isCompanySignature,
                    newFontSize: item.newFontSize,
                    font: item.font,
                    uniqueId: item.uuid,
                    type: item.type,
                });
            }
        });
};

const itemLength = (item, axis = 'x') => {
    if (axis === 'x') {
        if (item.isCompanySignature) {
            return 300;
        }
        if (item.type === 'textInput') {
            return 250;
        }
        return 250;
    }
    return 60;
};

const renderPage = async (doc, pageNo, localState, updateState) => {
    const page = await doc.getPage(pageNo || 0);

    var scale = 2;
    var viewport = page.getViewport({ scale: scale });

    const canvas = document.getElementById(`pdf-canvas-${localState.pdfIndex}`);
    const context = canvas.getContext('2d');

    // context.imageSmoothingEnabled = true;
    // context.imageSmoothingQuality = 'high';

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    await page.render({
        canvasContext: context,
        viewport: viewport,
    }).promise;

    if (canvas) {
        renderExistingSignatures(localState, updateState);
    }
};

function drawImageOnCanvas(svg, x, y, canvasId = 'signature-canvas', additionalParams = {}) {
    const svgContainer = document.getElementById(canvasId);

    if (!svgContainer) return;

    const resizeWrapper = document.createElement('div');
    if (additionalParams?.signedObject) {
        resizeWrapper.classList.add('completed-signature');
    } else {
        resizeWrapper.classList.add('resize-handle');
    }
    resizeWrapper.style.left = `${x}px`;
    resizeWrapper.style.top = `${y}px`;
    resizeWrapper.style.position = 'absolute';
    resizeWrapper.id = `resize_${additionalParams?.uniqueId}`;
    resizeWrapper.dataset.type = additionalParams.type;

    let wrapper;
    if (svg.includes('data:image')) {
        wrapper = document.createElement('img');
        wrapper.src = svg;
        wrapper.draggable = false;
        if (additionalParams.newFontSize) {
            wrapper.style.width = `${(additionalParams.newFontSize / 20) * 200}px`;
        }
    } else {
        wrapper = document.createElement('div');
        wrapper.classList.add('draggable-svg');
        wrapper.innerHTML = svg;
        wrapper.id = additionalParams?.uniqueId;
    }

    if (additionalParams?.isCompanySignature) {
        wrapper.style.fontSize = additionalParams.newFontSize ? `${additionalParams.newFontSize}px` : '20px';
        wrapper.style.fontFamily = additionalParams?.font;
        wrapper.style.color = 'black';
        wrapper.style.display = 'inline-block';
        wrapper.style.width = '100%';
    }

    resizeWrapper.appendChild(wrapper);
    svgContainer.appendChild(resizeWrapper);

    if (additionalParams.type === 'textInput' && additionalParams.newFontSize) {
        const svgTextElement = document.getElementById(`${additionalParams?.uniqueId}_text`);
        if (svgTextElement) {
            svgTextElement.setAttribute('font-size', additionalParams.newFontSize + 'px');
            const textHeight = svgTextElement.getBBox().height;
            svgTextElement.setAttribute('y', textHeight / 2);
        }
    }

    const closeButton = document.createElement('i');
    closeButton.style.left = resizeWrapper.offsetWidth + 'px';
    closeButton.style.top = '-10px';
    closeButton.style.position = 'absolute';
    closeButton.style.fontSize = '12px';
    closeButton.style.color = 'red';
    closeButton.style.cursor = 'pointer';
    closeButton.classList.add('fas');
    closeButton.classList.add('fa-times-circle');
    closeButton.id = `delete_button_${additionalParams?.uniqueId}`;
    resizeWrapper.appendChild(closeButton);
}

const archiveDocument = (localState, updateLocalState, state, updateState, docId) => async (reason) => {
    try {
        updateLocalState({
            ...localState,
            loading: true,
        });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView.raw_id}/docsign/${docId}`,
            method: 'PUT',
            data: {
                archive_document: true,
                reason,
            },
        });

        const {
            data: { documents },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView.raw_id}/docsign`,
        });

        updateLocalState({
            ...localState,
            loading: false,
            documents,
            showArchiveConfirmation: false,
        });

        toast('Document has been archived', {
            type: 'success',
            autoClose: 5000,
            position: 'top-right',
        });
    } catch (e) {
        console.log(e);
        updateLocalState({
            ...localState,
            loading: false,
        });
        toast(e.response?.data?.message || 'We have been unable to archive this document, please try again later', {
            type: 'error',
            autoClose: 5000,
            position: 'top-right',
        });
    }
};

const deleteDocument = async (state, updateState, localState, updateLocalState, docId) => {
    try {
        updateLocalState({
            ...localState,
            loading: true,
            error: null,
        });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView.raw_id}/docsign/${docId}`,
            method: 'DELETE',
        });

        const {
            data: { documents },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView.raw_id}/docsign`,
        });

        updateLocalState({
            ...localState,
            error: null,
            loading: false,
            documents,
            showDeleteDocConfirmation: false,
        });
    } catch (e) {
        console.log(e);
        updateLocalState({
            ...localState,
            loading: false,
            error: 'There has been an error deleting your document',
        });
    }
};

const downloadDocument = async (document, localState, updateLocalState) => {
    updateLocalState({ ...localState, loading: true });

    try {
        const {
            data: { url },
        } = await axios.get(
            `${process.env.REACT_APP_AQRU_AI_API}/documents/${
                document.finalised_document_uuid || document.finalisedDocUuid
            }`,
            {}
        );

        updateLocalState({ ...localState, loading: false });

        const a = window.document.createElement('a');

        a.href = url;
        a.target = '_blank';
        a.download = document.name;

        return a.click();
    } catch (e) {
        console.log(e);
        updateLocalState({ ...localState, loading: false });
    }
};

const ReferenceDocs = ({ localState }) => (
    <Flex sx={{ alignSelf: 'flex-start', mt: 30, flexDirection: 'column' }}>
        <Paragraph sx={{ textAlign: 'left', fontWeight: 600, mt: 20, fontSize: 20 }}>Reference documents</Paragraph>
        <Divider />
        {localState.reference_docs?.map((doc) => (
            <Flex sx={{ mt: 10, flexDirection: 'column' }} key={doc.uuid}>
                <Flex sx={{ alignItems: 'center' }}>
                    <Paragraph sx={{ width: 400 }}>{doc.fileName}</Paragraph>
                    <Button
                        onClick={async () => {
                            const {
                                data: { url },
                            } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/documents/${doc.uuid}`, {});

                            const a = window.document.createElement('a');

                            a.href = url;
                            a.target = '_blank';
                            a.download = document.fileName;

                            return a.click();
                        }}
                        sx={{ height: 40, ml: 20 }}
                    >
                        View
                    </Button>
                </Flex>
                <Divider />
            </Flex>
        ))}
    </Flex>
);

const loadDocs = async (state, updateState, localState, updateLocalState) => {
    try {
        updateLocalState({
            ...localState,
            loading: true,
            error: null,
        });
        try {
            await document.fonts.load('10pt "Great Vibes"');
            await document.fonts.load('10pt "Pacifico"');
        } catch (e) {
            console.log('Force load failed', e);
        }

        const {
            data: { documents },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView.raw_id}/docsign`,
        });

        const tasks = await loadTasksAsync(localState, updateLocalState, state.clientInView?.raw_id)();

        const queryParams = new URLSearchParams(window.location.search);

        const doc_to_open = queryParams.get('doc_to_open');
        const proposal_id = queryParams.get('proposal_id');

        if (doc_to_open) {
            const {
                data: { name, description },
            } = await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/proposals/${proposal_id}`,
            });

            const newLocalState = {
                ...localState,
                view: 'edit',
                archive_information: [],
                pdfs: [...defaultLocalState().pdfs],
                loading: false,
                error: null,
                documents,
                defaultTasksForDropDown: tasks,
            };

            newLocalState.pdfs[0].document.name = name;
            newLocalState.pdfs[0].document.description = description;

            updateLocalState(newLocalState);

            await loadPdf(newLocalState, updateLocalState, doc_to_open, 'application/pdf');
        } else {
            updateLocalState({
                ...localState,
                loading: false,
                error: null,
                documents,
                defaultTasksForDropDown: tasks,
            });
        }
    } catch (e) {
        console.log(e);
        updateLocalState({
            ...localState,
            loading: false,
            error: 'There has been an error loading your documents',
        });
    }
};

const loadPdf = async (localState, updateLocalState, document_uuid, type) => {
    updateLocalState({ ...localState, loading: true });

    const {
        data: { url },
    } = await axios({
        url: `${process.env.REACT_APP_AQRU_AI_API}/documents/${document_uuid}`,
    });

    const { data } = await axios.get(url, {
        headers: { 'Content-Type': type },
        responseType: 'arraybuffer',
    });

    const pdfDoc = await PDFDocument.load(data);

    const blob = new Blob([data], { type: 'application/pdf' });

    const fileUrl = URL.createObjectURL(blob);

    const pdfLib = window.pdfjsLib;
    pdfLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

    const doc = await pdfLib.getDocument(fileUrl).promise;

    const newLocalState = {
        ...localState,
        loading: false,
        page: 1,
    };

    newLocalState.pdfs[localState.pdfIndex] = {
        ...newLocalState.pdfs[localState.pdfIndex],
        pdfDoc,
        pdfPageWidth: pdfDoc.getPage(0).getWidth(),
        pdfPageHeight: pdfDoc.getPage(0).getHeight(),
        pdfData: data,
        pdfDocument: doc,
        document_uuid,
    };

    await renderPage(doc, localState.page, newLocalState, updateLocalState);

    updateLocalState(newLocalState);
};

const uploadReferenceFiles = async (e, localState, updateLocalState) => {
    try {
        updateLocalState({ ...localState, loading: true });

        const filesToUpload = [...e.target.files];

        const reference_docs = [...(localState.reference_docs || [])];

        for (let index = 0; index < filesToUpload.length; index++) {
            const newFile = filesToUpload[index];

            const {
                data: { document_uuid, presigned_url },
            } = await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/documents`,
                method: 'POST',
                data: {
                    content_type: newFile.type,
                    file_name: newFile.name,
                },
            });

            await axios.put(presigned_url, newFile, {
                headers: { 'Content-Type': newFile.type },
            });

            reference_docs.push({ uuid: document_uuid, fileName: newFile.name });
        }

        updateLocalState({
            ...localState,
            loading: false,
            reference_docs,
        });

        document.getElementById('ref_file_input').value = '';
    } catch (e) {
        console.log(e);
        updateLocalState({ ...localState, loading: false, error: 'We are currently unable to upload your file' });
    }
};

export const handleFileUpload = async (e, localState, updateLocalState) => {
    try {
        const newFile = [...e.target.files]?.[0];

        updateLocalState({ ...localState, loading: true });

        const {
            data: { document_uuid, presigned_url },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/documents`,
            method: 'POST',
            data: {
                content_type: newFile.type,
                file_name: newFile.name,
            },
        });

        await axios.put(presigned_url, newFile, {
            headers: { 'Content-Type': newFile.type },
        });

        await loadPdf(localState, updateLocalState, document_uuid, newFile.type);
    } catch (e) {
        console.log(e);
        updateLocalState({ ...localState, loading: false, error: 'We are currently unable to upload your file' });
    }
};

const defaultLocalState = () => ({
    documents: [],
    view: 'list',
    loading: false,
    page: 1,
    pdfs: [
        {
            locationToMove: null,
            signatureLocations: [],
            document: {},
        },
    ],
    pdfIndex: 0,
});

const clearDraggableView = (localState) => {
    document.querySelectorAll('div.draggable-svg').forEach((div) => {
        div.remove();
    });
    document.querySelectorAll('div.resize-handle').forEach((div) => {
        div.remove();
    });
    document.querySelectorAll('div.completed-signature').forEach((div) => {
        div.remove();
    });
};

const saveDocusign = async (localState, updateLocalState, state, updateState, status) => {
    try {
        const pdf = localState.pdfs[0];

        if (!pdf.document?.name) {
            return toast('Please enter a name for the document', {
                type: 'error',
                autoClose: 5000,
            });
        }

        for (let index = 0; index < localState.pdfs?.length; index++) {
            const element = localState.pdfs[index];
            if (!element.document_uuid) {
                return toast(`Please upload a pdf for Document ${index + 1} before saving`, {
                    type: 'error',
                    autoClose: 5000,
                });
            }
        }

        updateLocalState({ ...localState, loading: true });

        const { data } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView?.raw_id}/docsign${
                pdf.document.id ? `/${pdf.document.id}` : ''
            }`,
            method: pdf.document.id ? 'PUT' : 'POST',
            data: {
                task_id: localState.task?.id,
                name: pdf.document?.name,
                description: pdf.document?.description,
                status,
                doc_uuid: pdf.document_uuid,
                signature_locations: {},
                signature_placeholder_locations: {
                    pdfPageWidth: pdf.pdfPageWidth,
                    pdfPageHeight: pdf.pdfPageHeight,
                    clientUsersSelected: pdf.clientUsersSelected,
                    signatureLocations: pdf.signatureLocations,
                    signatureSizes: pdf.signatureSizes,
                },
                additional_data: {
                    archive_information: localState.archive_information || [],
                    signatures_in_sequence: localState.signaturesInSequence,
                    sequence: localState.signaturesInSequence ? localState.sequence : [],
                    reference_docs: localState.reference_docs,
                    reminders: localState.reminders,
                    pdfs:
                        localState.pdfs?.slice(1)?.map((item) => ({
                            name: pdf.name,
                            description: pdf.description,
                            status,
                            doc_uuid: item.document_uuid,
                            signature_locations: {},
                            signature_placeholder_locations: {
                                pdfPageWidth: item.pdfPageWidth,
                                pdfPageHeight: item.pdfPageHeight,
                                clientUsersSelected: item.clientUsersSelected,
                                signatureLocations: item.signatureLocations,
                                signatureSizes: item.signatureSizes,
                            },
                            relations: item.clientUsersSelected?.map((x) => ({ client_user_uuid: x.value })),
                        })) || [],
                },
                relations: pdf.clientUsersSelected?.map((x) => ({ client_user_uuid: x.value })),
            },
        });

        const id = pdf.document.id || data.id;

        const {
            data: { documents },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView?.raw_id}/docsign`,
        });

        localState.pdfs[0].document.id = id;
        localState.pdfs[0].document.status = status;

        updateLocalState({
            ...localState,
            loading: false,
            documents,
        });

        toast(status === 'sent' ? 'Document sent' : 'Document saved', {
            type: 'success',
            autoClose: 5000,
            position: 'top-right',
        });
    } catch (e) {
        console.log(e);
        updateLocalState({
            ...localState,
            loading: false,
        });
        toast('We have been unable to save this document, please try again later', {
            type: 'error',
            autoClose: 5000,
            position: 'top-right',
        });
    }
};

const statusOptions = [
    { value: 'pending', label: 'Pending' },
    { value: 'sent', label: 'Sent for signature' },
    { value: 'finalised', label: 'Finalised' },
];

const DocSign = ({ updateState, state }) => {
    const [localState, updateLocalState] = useState(defaultLocalState());

    const canvasRef = useRef(null);

    useEffect(() => {
        loadDocs(state, updateState, defaultLocalState(), updateLocalState);
    }, []);

    useEffect(() => {
        clearDraggableView();

        (async () => {
            if (localState.pdfs[localState.pdfIndex].pdfDocument) {
                await renderPage(
                    localState.pdfs[localState.pdfIndex].pdfDocument,
                    localState.page,
                    localState,
                    updateLocalState
                );
            }
        })();
    }, [localState.page]);

    useEffect(() => {
        if (localState.view !== 'edit') return;

        ['Great Vibes', 'Pacifico'].forEach((x, idx) => {
            const canvas = document.getElementById(`canvas-signature-${idx + 1}`);

            if (!canvas) return;

            const ctx = canvas.getContext('2d');
            ctx.font = `20px ${x}`;
            ctx.fillStyle = 'black';
            ctx.fillText(state.organisation.name, 30, 30);
        });
    }, [localState.view, localState.pdfs[localState.pdfIndex].pdfData]);

    useEffect(() => {
        clearDraggableView();
        (async () => {
            const document = localState.pdfs?.[localState.pdfIndex]?.document;

            if (window.document.getElementById('file_input')) {
                window.document.getElementById('file_input').value = null;
            }

            let documentUuid =
                document?.document_for_signing_uuid ||
                localState.pdfs?.[localState.pdfIndex]?.document_uuid ||
                localState.pdfs?.[localState.pdfIndex]?.doc_uuid;

            if (documentUuid) {
                try {
                    if (document.status === 'finalised') {
                        documentUuid =
                            localState.pdfs?.[localState.pdfIndex]?.finalised_document_uuid ||
                            localState.pdfs?.[localState.pdfIndex]?.finalisedDocUuid;
                    }

                    await loadPdf(localState, updateLocalState, documentUuid, 'application/pdf');
                } catch (e) {
                    console.log(e);

                    updateLocalState({
                        ...defaultLocalState(),
                        documents: localState.documents,
                    });

                    toast('There has been an error loading your pdf', {
                        type: 'error',
                        autoClose: 5000,
                        position: 'top-right',
                    });
                }
            }
        })();
    }, [localState.pdfs?.[localState.pdfIndex]?.document?.document_for_signing_uuid, localState.pdfIndex]);

    useEffect(() => {
        window.addEventListener('scroll', function () {
            const tableHeader = document.getElementById('doc_table_head');
            if (!tableHeader) return;

            const stickyPosition = tableHeader.getBoundingClientRect().top;

            if (stickyPosition <= 101) {
                tableHeader.style.top = '101px';
            } else {
                tableHeader.style.top = 'unset';
            }
        });
    }, []);

    if (localState.view === 'edit' && localState.pdfs?.[localState.pdfIndex]?.document?.status === 'finalised') {
        return (
            <Flex sx={{ flexDirection: 'column', mt: 20, ml: 20, mb: 30 }}>
                {localState.loading && <Spinner />}
                <Flex sx={{ mb: 30, flexDirection: 'column' }}>
                    <Flex
                        sx={{
                            position: 'fixed',
                            height: 80,
                            background: 'white',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            alignItems: 'center',
                            borderTop: '2px solid #EFEFEF',
                            width: '100%',
                            zIndex: 998,
                        }}
                    >
                        <Button
                            sx={{ width: 100, ml: 20 }}
                            onClick={() =>
                                updateLocalState({ ...defaultLocalState(), documents: localState.documents })
                            }
                            variant="light"
                        >
                            <i
                                style={{
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    marginRight: '5px',
                                }}
                                className="fas fa-chevron-left"
                                aria-hidden="true"
                            />
                            Back
                        </Button>
                        {localState.pdfs?.[localState.pdfIndex]?.document?.status === 'finalised' && (
                            <Button
                                onClick={async () => {
                                    const files = [];

                                    files.push(localState.fullRecord?.finalised_document_uuid);

                                    localState.fullRecord?.additional_data?.pdfs?.forEach((pdf) => {
                                        files.push(pdf.finalisedDocUuid);
                                    });

                                    const response = await axios({
                                        url: `${process.env.REACT_APP_AQRU_AI_API}/documents/download-multiple`,
                                        method: 'POST',
                                        data: { files },
                                        responseType: 'blob',
                                    });

                                    const fileBlob = new Blob([response.data], {
                                        type: `application/${files.length > 1 ? 'zip' : 'pdf'}`,
                                    });

                                    const fileURL = URL.createObjectURL(fileBlob);

                                    const link = document.createElement('a');
                                    link.href = fileURL;
                                    link.download = `${localState.fullRecord?.name}-Finalised.${
                                        files.length > 1 ? 'zip' : 'pdf'
                                    }`;
                                    document.body.appendChild(link);
                                    link.click();

                                    document.body.removeChild(link);
                                    URL.revokeObjectURL(fileURL);

                                    return;
                                }}
                                sx={{ ml: 10 }}
                            >
                                <i
                                    style={{
                                        fontSize: '12px',
                                        cursor: 'pointer',
                                        marginRight: '5px',
                                    }}
                                    className="fas fa-download"
                                    aria-hidden="true"
                                />
                                Download
                            </Button>
                        )}
                    </Flex>
                    <Flex>
                        <Flex sx={{ flexDirection: 'column' }}>
                            <Paragraph sx={{ mt: 0, fontWeight: 600, fontSize: 18 }}>
                                {localState.pdfs?.[localState.pdfIndex]?.document.name}
                            </Paragraph>
                            <Divider />

                            <Flex sx={{ borderBottom: '1px solid lightgray' }}>
                                {localState.pdfs?.map((x, pdfIndex) => (
                                    <Flex
                                        key={`tab_${pdfIndex}`}
                                        sx={{
                                            mb: '0',
                                            pb: '0',
                                            height: '60px',
                                            borderRadius: '10px',
                                            border: '1px solid lightgray',
                                            borderBottom: '0px solid lightgray',
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: 0,
                                            ml: '5px',
                                            mt: '5px',
                                            mr: '5px',
                                            width: 200,
                                        }}
                                    >
                                        <Flex
                                            sx={{
                                                flex: 1,
                                                textAlign: 'center',
                                                alignSelf: 'center',
                                                cursor: 'pointer',
                                                height: 60,
                                                width: 200,
                                            }}
                                            onClick={() => updateLocalState({ ...localState, pdfIndex: pdfIndex })}
                                        >
                                            <Paragraph
                                                sx={{
                                                    textAlign: 'center',
                                                    width: '100%',
                                                    fontSize: '15px',
                                                    alignSelf: 'center',
                                                    ml: 10,
                                                    fontWeight: localState.pdfIndex === pdfIndex ? '600' : '400',
                                                }}
                                            >
                                                Document {pdfIndex + 1}
                                            </Paragraph>
                                        </Flex>
                                    </Flex>
                                ))}
                            </Flex>

                            <Box
                                sx={{
                                    padding: 10,
                                    border: '1px solid lightGrey',
                                    position: 'relative',
                                    width: localState.pdfs?.[localState.pdfIndex]?.pdfPageWidth + 2 || '500px',
                                    height: localState.pdfs?.[localState.pdfIndex]?.pdfPageHeight + 2 || '1px',
                                    mt: 20,
                                    cursor: 'grab',
                                }}
                            >
                                <canvas
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: localState.pdfs?.[localState.pdfIndex]?.pdfPageWidth || '500px',
                                        height: localState.pdfs?.[localState.pdfIndex]?.pdfPageHeight || '1px',
                                    }}
                                    id={`pdf-canvas-${localState.pdfIndex}`}
                                    ref={canvasRef}
                                />
                            </Box>
                            <DocumentPager localState={localState} updateLocalState={updateLocalState} />

                            {localState.reference_docs?.length ? <ReferenceDocs localState={localState} /> : null}
                        </Flex>
                        <Flex sx={{ flexDirection: 'column', ml: 40, mt: 40 }}>
                            <Paragraph sx={{ mb: 0, fontWeight: 600, fontSize: 18 }}>Audit trail</Paragraph>
                            <Divider />
                            {[...(localState.pdfs?.[0]?.document?.audit_metadata?.events || [])]
                                .reverse()
                                ?.map((x, idx) => (
                                    <Flex key={`ev_${idx}`} sx={{ flexDirection: 'column' }}>
                                        <Flex sx={{ justifyContent: 'space-between' }}>
                                            <Flex sx={{ flexDirection: 'column', mb: 20 }}>
                                                <Paragraph>{x.event}</Paragraph>
                                                <Paragraph sx={{ mt: '5px' }}>IP: {x.ip}</Paragraph>
                                            </Flex>
                                            <Paragraph sx={{ ml: 20 }}>
                                                {moment(x.timestamp).format('HH:mm Do MMM YYYY')}
                                            </Paragraph>
                                        </Flex>
                                        <Divider mt={0} />
                                    </Flex>
                                ))}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        );
    }

    if (localState.view === 'edit') {
        return (
            <Flex sx={{ flexDirection: 'column', mt: 20, ml: 20, mb: 30 }}>
                {localState.loading && <Spinner />}

                {localState.showDeleteDocConfirmation && (
                    <ConfirmDialog
                        updateState={updateLocalState}
                        state={localState}
                        stateKey="showDeleteDocConfirmation"
                        text="Are you sure you wish to delete this document?"
                    />
                )}

                {state.showTextInputLabelModal && (
                    <Overlay
                        overlay={{ hideNavigation: true }}
                        copy={{}}
                        maxWidth={450}
                        maxHeight={350}
                        embeddedComponent={
                            <TextInputLabelModal
                                okHandler={localState.okHandler}
                                state={state}
                                updateState={updateState}
                            />
                        }
                        updateOverlay={() => updateState({ ...state, showTextInputLabelModal: false })}
                    />
                )}

                {localState.showDefineSignatureOrderModal && (
                    <Overlay
                        copy={{}}
                        maxWidth={450}
                        maxHeight={'80%'}
                        embeddedComponent={
                            <DefineSignatureOrderModal
                                okHandler={localState.okHandler}
                                state={localState}
                                updateState={updateLocalState}
                            />
                        }
                        updateOverlay={() => updateLocalState({ ...localState, showDefineSignatureOrderModal: false })}
                    />
                )}

                <Paragraph sx={{ fontWeight: 600, fontSize: 20, color: 'text' }}>
                    Create new document for signing (
                    {statusOptions?.find((x) => x.value === localState.pdfs?.[localState.pdfIndex]?.document?.status)
                        ?.label || 'Draft'}
                    )
                </Paragraph>

                <Flex>
                    <Flex sx={{ flexDirection: 'column' }}>
                        <Label sx={{ mt: 20 }}>Name</Label>
                        <Input
                            value={localState.pdfs?.[localState.pdfIndex]?.document?.name}
                            onChange={(e) => {
                                localState.pdfs[0].document.name = e.target.value;
                                updateLocalState({
                                    ...localState,
                                });
                            }}
                            sx={{ mt: 10, width: 300, height: 40 }}
                        />

                        <Label sx={{ mt: 30 }}>Description</Label>
                        <Textarea
                            value={localState.pdfs?.[localState.pdfIndex]?.document?.description}
                            rows={7}
                            onChange={(e) => {
                                localState.pdfs[0].document.description = e.target.value;
                                updateLocalState({
                                    ...localState,
                                });
                            }}
                            sx={{ mt: 10, width: 400, borderRadius: 10 }}
                        />

                        <Label sx={{ mt: 30 }}>Linked Task (optional)</Label>
                        <AsyncSelect
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '400px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            aria-labelledby="task-label"
                            onChange={async (c) => {
                                updateLocalState({
                                    ...localState,
                                    task: {
                                        id: c.value,
                                        uuid: c.uuid,
                                        service: c.service,
                                        title: c.label,
                                    },
                                });
                            }}
                            placeholder="Select task"
                            value={
                                localState.task
                                    ? {
                                          value: localState.task.id,
                                          label: localState.task.title,
                                      }
                                    : null
                            }
                            defaultOptions={localState.defaultTasksForDropDown || []}
                            loadOptions={loadTasksAsync(localState, updateLocalState, state.clientInView?.raw_id)}
                        />
                    </Flex>
                    <Flex sx={{ flexDirection: 'column', ml: 80 }}>
                        <Flex sx={{ flexDirection: 'column', width: 400, mt: 20, mb: 0 }}>
                            <Label id="reminders-label" sx={{ mr: 30, fontSize: 14 }}>
                                Reminders
                            </Label>
                            <ReactSelect
                                aria-labelledby="reminders-label"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                        width: '370px',
                                        minHeight: '40px',
                                        marginTop: '10px',
                                        borderRadius: 10,
                                        border: `1px solid #a3a69b`,
                                        fontSize: '14px',
                                    }),
                                }}
                                isMulti
                                onChange={async (c) => {
                                    updateLocalState({
                                        ...localState,
                                        reminders: c,
                                    });
                                }}
                                placeholder="Select..."
                                value={localState?.reminders || []}
                                options={[
                                    {
                                        value: 1,
                                        label: '1 Day after',
                                    },
                                    {
                                        value: 2,
                                        label: '2 Days after',
                                    },
                                    {
                                        value: 3,
                                        label: '3 Days after',
                                    },
                                    {
                                        value: 4,
                                        label: '4 Days after',
                                    },
                                    {
                                        value: 5,
                                        label: '5 Days after',
                                    },
                                    {
                                        value: 6,
                                        label: '6 Days after',
                                    },
                                    {
                                        value: 7,
                                        label: '1 week after',
                                    },
                                    {
                                        value: 14,
                                        label: '2 weeks after',
                                    },
                                ]}
                            />
                        </Flex>
                        <Flex sx={{ mt: 30, alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Label sx={{ width: 420, mr: 20 }}>Signatures must be in sequence</Label>
                            <Switch
                                onClick={() =>
                                    updateLocalState({
                                        ...localState,
                                        signaturesInSequence: !localState.signaturesInSequence,
                                    })
                                }
                                defaultChecked={localState.signaturesInSequence}
                            />
                            {localState.signaturesInSequence && (
                                <Button
                                    onClick={() =>
                                        updateLocalState({
                                            ...localState,
                                            showDefineSignatureOrderModal: true,
                                            okHandler: (sequence) => {
                                                updateLocalState({ ...localState, sequence });
                                            },
                                        })
                                    }
                                    sx={{ ml: -220, width: 250, height: 30, fontSize: 13 }}
                                >
                                    Define sequence
                                </Button>
                            )}
                        </Flex>
                    </Flex>
                </Flex>

                <Flex>
                    <Flex
                        sx={{
                            minHeight: '100px',
                            width: '100%',
                            mb: '30px',
                            mt: '30px',
                            mr: '10px',
                            flexDirection: 'column',
                        }}
                    >
                        <Button
                            onClick={() =>
                                updateLocalState({
                                    ...localState,
                                    pdfs: [...localState.pdfs, { ...defaultLocalState().pdfs[0] }],
                                    pdfIndex: localState.pdfs.length,
                                })
                            }
                            variant="light"
                            sx={{ height: 40, mb: 20, width: 160 }}
                        >
                            <i
                                style={{
                                    marginTop: '-5px',
                                    marginRight: '5px',
                                    color: '#444',
                                }}
                                className={`far fa-plus-circle`}
                            />
                            Add Document
                        </Button>
                        <Flex sx={{ borderBottom: '1px solid lightgray' }}>
                            {localState.pdfs?.map((x, pdfIndex) => (
                                <Flex
                                    key={`tab_${pdfIndex}`}
                                    sx={{
                                        mb: '0',
                                        pb: '0',
                                        height: '60px',
                                        borderRadius: '10px',
                                        border: '1px solid lightgray',
                                        borderBottom: '0px solid lightgray',
                                        borderBottomLeftRadius: 0,
                                        borderBottomRightRadius: 0,
                                        ml: '5px',
                                        mt: '5px',
                                        mr: '5px',
                                        width: 200,
                                    }}
                                >
                                    <Flex
                                        sx={{
                                            flex: 1,
                                            textAlign: 'center',
                                            alignSelf: 'center',
                                            cursor: 'pointer',
                                            height: 60,
                                            width: 200,
                                            borderBottom:
                                                localState.pdfIndex === pdfIndex ? '5px solid #e8b923' : '0px',
                                        }}
                                        onClick={() => updateLocalState({ ...localState, pdfIndex: pdfIndex, page: 1 })}
                                    >
                                        <Paragraph
                                            sx={{
                                                textAlign: 'center',
                                                width: '100%',
                                                fontSize: '15px',
                                                alignSelf: 'center',
                                                ml: 10,
                                                fontWeight: localState.pdfIndex === pdfIndex ? '600' : '400',
                                            }}
                                        >
                                            Document {pdfIndex + 1}
                                        </Paragraph>
                                        {localState.pdfs?.length > 1 && (
                                            <i
                                                style={{
                                                    marginTop: 10,
                                                    marginRight: 10,
                                                    fontSize: '15px',
                                                    cursor: 'pointer',
                                                    alignSelf: 'flex-start',
                                                    color: 'red',
                                                }}
                                                className="fas fa-times-circle"
                                                aria-hidden="true"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    updateLocalState({
                                                        ...localState,
                                                        showDeleteDocConfirmation: true,
                                                        confirmCallback: () => {
                                                            localState.pdfs.splice(pdfIndex, 1);

                                                            const archive_information =
                                                                localState.archive_information.filter((item) => {
                                                                    if (item.pdfIndex === pdfIndex) {
                                                                        return false;
                                                                    }
                                                                    return true;
                                                                });

                                                            updateLocalState({
                                                                ...localState,
                                                                pdfIndex: Math.max(0, pdfIndex - 1),
                                                                archive_information,
                                                                page: 1,
                                                            });
                                                        },
                                                    });
                                                }}
                                            />
                                        )}
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                        {localState.showDeleteDocConfirmation && (
                            <ConfirmDialog
                                updateState={updateLocalState}
                                state={localState}
                                stateKey="showDeleteDocConfirmation"
                                text="Are you sure you wish to delete this document?"
                            />
                        )}

                        <Flex sx={{ flexDirection: 'column' }}>
                            <DocView
                                localState={localState}
                                updateLocalState={updateLocalState}
                                canvasRef={canvasRef}
                            />

                            <Flex>
                                <Flex sx={{ flexDirection: 'column' }}>
                                    {localState.pdfs[localState.pdfIndex]?.pdfDocument && (
                                        <Flex sx={{ flexDirection: 'column', ml: 0, mt: 20 }}>
                                            <Paragraph>Select your contacts</Paragraph>

                                            <Flex sx={{ width: 470, flexDirection: 'column' }}>
                                                <ReactSelect
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                            width: '400px',
                                                            minHeight: '40px',
                                                            marginTop: '10px',
                                                            borderRadius: 10,
                                                            marginBottom: '10px',
                                                            border: '1px solid #a3a69b',
                                                            fontSize: '14px',
                                                        }),
                                                        container: (provided) => ({
                                                            ...provided,
                                                            zIndex: 10000,
                                                        }),
                                                    }}
                                                    onChange={(value) => {
                                                        localState.pdfs[localState.pdfIndex].clientUsersSelected =
                                                            value;
                                                        updateLocalState({ ...localState });
                                                    }}
                                                    isMulti
                                                    placeholder={'Select the users to sign the document'}
                                                    value={
                                                        localState?.pdfs?.[localState.pdfIndex]?.clientUsersSelected ||
                                                        []
                                                    }
                                                    options={
                                                        getContactsFilteredByThoseUsingClientPortal(
                                                            state.clientInView,
                                                            state.clientUsers
                                                        )?.map((x) => ({
                                                            value: x.user_id,
                                                            label: `${x.first_name} ${x.last_name}`,
                                                        })) || []
                                                    }
                                                />

                                                <Divider />

                                                {localState?.pdfs?.[localState.pdfIndex]?.clientUsersSelected
                                                    ?.length ? (
                                                    <>
                                                        <Paragraph sx={{ mt: 10, mb: 20 }}>
                                                            Now drag the components to the correct place
                                                        </Paragraph>
                                                    </>
                                                ) : null}

                                                {localState?.pdfs?.[localState.pdfIndex]?.clientUsersSelected?.map(
                                                    (x, idx) => (
                                                        <SelectedClientUser
                                                            key={`selec_${idx}`}
                                                            svg={svg}
                                                            idx={idx}
                                                            x={x}
                                                            localState={localState}
                                                            updateLocalState={updateLocalState}
                                                            signatureSizes={signatureSizes}
                                                            textInputSvg={textInputSvg}
                                                            itemLength={itemLength}
                                                        />
                                                    )
                                                )}

                                                <CompanySignature
                                                    state={state}
                                                    updateLocalState={updateLocalState}
                                                    localState={localState}
                                                    clearDraggableView={clearDraggableView}
                                                />
                                            </Flex>
                                        </Flex>
                                    )}
                                    <Flex sx={{ alignSelf: 'flex-start', mt: 20, flexDirection: 'column' }}>
                                        <Label
                                            sx={{
                                                display: 'inline-block',
                                                padding: '10px 20px',
                                                color: '#fff',
                                                backgroundColor: 'primary',
                                                borderRadius: '15px',
                                                cursor: 'pointer',
                                                fontSize: '16px',
                                                transition: 'background-color 0.3s',
                                                width: 400,
                                                '&:hover': {
                                                    backgroundColor: '#EFEFEF',
                                                    color: '#000',
                                                },
                                            }}
                                        >
                                            <i
                                                style={{
                                                    fontSize: '18px',
                                                    cursor: 'pointer',
                                                    marginRight: '10px',
                                                }}
                                                className="fas fa-file"
                                                aria-hidden="true"
                                            />
                                            Attach reference documents
                                            <Input
                                                id="ref_file_input"
                                                sx={{
                                                    display: 'none',
                                                }}
                                                accept=".docx, .pdf"
                                                multiple
                                                type="file"
                                                onChange={async (e) => {
                                                    await uploadReferenceFiles(e, localState, updateLocalState);
                                                }}
                                            />
                                        </Label>

                                        <Box sx={{ height: 20 }} />
                                        {localState.reference_docs?.map((doc, idx) => (
                                            <ReferenceDoc
                                                key={`ref_doc_${doc.uuid}`}
                                                doc={doc}
                                                idx={idx}
                                                localState={localState}
                                                updateLocalState={updateLocalState}
                                            />
                                        ))}
                                    </Flex>
                                </Flex>
                                <Flex
                                    sx={{
                                        flexDirection: 'column',
                                        ml: 50,
                                        height: localState.pdfs[localState.pdfIndex]?.pdfDocument ? 'unset' : '0',
                                    }}
                                >
                                    <PdfFrame
                                        localState={localState}
                                        canvasRef={canvasRef}
                                        itemLength={itemLength}
                                        updateLocalState={updateLocalState}
                                        clearDraggableView={clearDraggableView}
                                        drawImageOnCanvas={drawImageOnCanvas}
                                        textInputSvg={textInputSvg}
                                        updateState={updateState}
                                        state={state}
                                    />
                                    {localState.pdfs[localState.pdfIndex]?.pdfDocument ? (
                                        <Flex sx={{ alignSelf: 'center' }}>
                                            <DocumentPager
                                                localState={localState}
                                                updateLocalState={updateLocalState}
                                            />
                                        </Flex>
                                    ) : null}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <Flex
                    sx={{
                        position: 'fixed',
                        height: 80,
                        background: 'white',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        alignItems: 'center',
                        borderTop: '2px solid #EFEFEF',
                        width: '100%',
                        zIndex: 998,
                    }}
                >
                    <Button
                        onClick={() => {
                            window.location.assign(`/clients?id=${state.clientInView.id}&tab=doc_sign`);
                        }}
                        variant="light"
                        sx={{ height: 35, ml: 20, width: 120, fontSize: 14 }}
                    >
                        <i
                            style={{
                                marginTop: '-5px',
                                marginRight: '5px',
                                color: '#444',
                            }}
                            className={`far fa-chevron-left`}
                        />
                        Back
                    </Button>
                    <Button
                        onClick={async () => {
                            await saveDocusign(
                                localState,
                                updateLocalState,
                                state,
                                updateState,
                                localState.pdfs?.[localState.pdfIndex]?.document?.status || 'pending'
                            );
                        }}
                        variant="light"
                        sx={{ height: 35, ml: 10, width: 180, fontSize: 14 }}
                    >
                        <i
                            style={{
                                marginTop: '-5px',
                                marginRight: '5px',
                                color: '#444',
                            }}
                            className={`far fa-save`}
                        />
                        Save as draft
                    </Button>
                    <Button
                        onClick={async () => {
                            await saveDocusign(localState, updateLocalState, state, updateState, 'sent');
                            window.location.assign(`/clients?id=${state.clientInView.id}&tab=doc_sign`);
                        }}
                        sx={{ height: 35, ml: 10, width: 180, fontSize: 14 }}
                    >
                        <i
                            style={{
                                marginTop: '-5px',
                                marginRight: '5px',
                                color: '#fff',
                            }}
                            className={`far fa-paper-plane`}
                        />
                        Save and send
                    </Button>
                    <Paragraph sx={{ color: 'green', ml: 20 }}>
                        {statusOptions.find(
                            (option) => option.value === localState.pdfs?.[localState.pdfIndex]?.document?.status
                        )?.label || 'Draft'}
                    </Paragraph>
                    {localState.error && <Paragraph sx={{ color: 'red', ml: 20 }}>{localState.error}</Paragraph>}
                </Flex>
            </Flex>
        );
    }

    return (
        <Flex sx={{ flexDirection: 'column', mt: 20, ml: 20, mb: 30 }}>
            {localState.loading && <Spinner />}
            <Paragraph sx={{ fontWeight: 600, fontSize: 20, color: 'text' }}>Documents for signing</Paragraph>

            <Divider width="1300px" />

            {localState.showDeleteDocConfirmation && (
                <ConfirmDialog
                    updateState={updateLocalState}
                    state={localState}
                    stateKey="showDeleteDocConfirmation"
                    text="Are you sure you wish to delete this document?"
                />
            )}

            {localState.showArchiveConfirmation && (
                <Overlay
                    overlay={{ hideNavigation: true }}
                    copy={{}}
                    maxWidth={450}
                    maxHeight={300}
                    embeddedComponent={
                        <ArchiveReasonDialog
                            okHandler={localState.okHandler}
                            state={localState}
                            updateState={updateLocalState}
                        />
                    }
                    updateOverlay={() => updateState({ ...state, showArchiveConfirmation: false })}
                />
            )}

            <Button
                onClick={() =>
                    updateLocalState({
                        ...localState,
                        view: 'edit',
                        archive_information: [],
                        pdfs: [...defaultLocalState().pdfs],
                    })
                }
                sx={{ width: 110, height: 40, mt: 0, mb: 20 }}
            >
                <i
                    style={{
                        marginTop: '-5px',
                        marginRight: '5px',
                        color: '#fff',
                    }}
                    className={`far fa-plus-circle`}
                />
                New
            </Button>

            <table style={{ fontSize: 14, width: 1200, borderCollapse: 'collapse' }}>
                <thead
                    id="doc_table_head"
                    style={{ backgroundColor: theme.colors.primary, color: 'white', position: 'sticky' }}
                >
                    <th style={{ padding: 20, wordWrap: 'break-word', textAlign: 'left' }}>
                        <Paragraph>Name</Paragraph>
                    </th>
                    <th style={{ padding: 20, textAlign: 'left' }}>
                        <Paragraph>Description</Paragraph>
                    </th>
                    <th style={{ padding: 20, textAlign: 'left' }}>
                        <Paragraph>Status</Paragraph>
                    </th>
                    <th style={{ padding: 20, textAlign: 'left' }}>
                        <Paragraph>Sent to</Paragraph>
                    </th>
                    <th style={{ padding: 20, textAlign: 'left' }}>
                        <Paragraph>Sent at</Paragraph>
                    </th>
                    <th style={{ width: 170 }}></th>
                </thead>
                {localState.documents?.map((x, idx) => {
                    const isArchived =
                        x?.additional_data?.archive_information?.length === (x.additional_data?.pdfs || [])?.length + 1;
                    return (
                        <tr
                            key={x.email_address}
                            style={{
                                borderBottom: '1px solid #efefef',
                                ml: 20,
                            }}
                        >
                            <td
                                style={{
                                    padding: 20,
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                }}
                            >
                                <Paragraph sx={{ textAlign: 'left', fontSize: 14 }}>{x.name}</Paragraph>
                            </td>
                            <td
                                style={{
                                    padding: 20,
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                }}
                            >
                                <Paragraph sx={{ textAlign: 'left', fontSize: 14 }}>{x.description}</Paragraph>
                            </td>
                            <td
                                style={{
                                    padding: 20,
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                }}
                            >
                                <Paragraph sx={{ textAlign: 'left', fontSize: 14 }}>
                                    {isArchived
                                        ? 'Archived'
                                        : statusOptions.find((item) => item.value === x.status)?.label || 'Pending'}
                                </Paragraph>
                            </td>
                            <td
                                style={{
                                    padding: 20,
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                }}
                            >
                                <Paragraph sx={{ textAlign: 'left', fontSize: 14 }}>
                                    {x.relations_formatted?.map((user) => user.name)?.join(', ')}
                                </Paragraph>
                            </td>
                            <td
                                style={{
                                    padding: 20,
                                    wordWrap: 'break-word',
                                    textAlign: 'left',
                                }}
                            >
                                <Paragraph sx={{ textAlign: 'left', fontSize: 14 }}>
                                    {x.sent_at ? moment(x.sent_at).format('HH:mm DD-MM-YYYY') : ''}
                                </Paragraph>
                            </td>
                            <td
                                style={{
                                    padding: 20,
                                    wordWrap: 'break-word',
                                }}
                            >
                                <Flex sx={{ flexDirection: 'column' }}>
                                    {x.status === 'finalised' ? (
                                        <Button
                                            variant="light"
                                            onClick={async () => {
                                                if (x.status === 'finalised') {
                                                    const files = [];

                                                    files.push(x.finalised_document_uuid);

                                                    x.additional_data?.pdfs?.forEach((pdf) => {
                                                        files.push(pdf.finalisedDocUuid);
                                                    });

                                                    const response = await axios({
                                                        url: `${process.env.REACT_APP_AQRU_AI_API}/documents/download-multiple`,
                                                        method: 'POST',
                                                        data: { files },
                                                        responseType: 'blob',
                                                    });

                                                    const fileBlob = new Blob([response.data], {
                                                        type: `application/${files.length > 1 ? 'zip' : 'pdf'}`,
                                                    });

                                                    const fileURL = URL.createObjectURL(fileBlob);

                                                    const link = document.createElement('a');
                                                    link.href = fileURL;
                                                    link.download = `${x.name}-Finalised.${
                                                        files.length > 1 ? 'zip' : 'pdf'
                                                    }`;
                                                    document.body.appendChild(link);
                                                    link.click();

                                                    document.body.removeChild(link);
                                                    URL.revokeObjectURL(fileURL);

                                                    return;
                                                }
                                            }}
                                        >
                                            <i
                                                style={{
                                                    fontSize: '12px',
                                                    cursor: x.status === 'finalised' ? 'pointer' : 'unset',
                                                    color: x.status === 'finalised' ? 'green' : '#EFEFEF',
                                                }}
                                                className="fas fa-download"
                                                aria-hidden="true"
                                            />{' '}
                                            Download
                                        </Button>
                                    ) : null}
                                    <Button
                                        sx={{ mt: 10 }}
                                        variant="light"
                                        onClick={() => {
                                            updateLocalState({
                                                ...defaultLocalState(),
                                                documents: localState.documents,
                                                task: {
                                                    ...(x.task || {}),
                                                },
                                                reference_docs: x.additional_data?.reference_docs || [],
                                                reminders: x.additional_data?.reminders || [],
                                                signaturesInSequence:
                                                    x.additional_data?.signatures_in_sequence || false,
                                                archive_information: x.additional_data?.archive_information || [],
                                                sequence: x.additional_data?.sequence || [],
                                                pdfs: [
                                                    {
                                                        ...x,
                                                        ...x.signature_placeholder_locations,
                                                        document: {
                                                            ...x,
                                                        },
                                                    },
                                                    ...(x.additional_data?.pdfs || [])?.map((y) => ({
                                                        ...y,
                                                        ...y.signature_placeholder_locations,
                                                        document: {
                                                            ...y,
                                                        },
                                                    })),
                                                ],
                                                view: 'edit',
                                                index: idx,
                                                fullRecord: x,
                                            });
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                        }}
                                    >
                                        <i
                                            style={{
                                                fontSize: '12px',
                                                cursor: 'pointer',
                                            }}
                                            className={`fas fa-${x.status === 'finalised' ? 'eye' : 'pencil'}`}
                                            aria-hidden="true"
                                        />{' '}
                                        {x.status === 'finalised' ? 'View' : 'Edit'}
                                    </Button>
                                    {x?.additional_data?.archive_information?.length !==
                                    x.additional_data?.pdfs?.length + 1 ? (
                                        <Button
                                            sx={{ mt: 10 }}
                                            onClick={async () => {
                                                updateLocalState({
                                                    ...localState,
                                                    showArchiveConfirmation: true,
                                                    okHandler: archiveDocument(
                                                        localState,
                                                        updateLocalState,
                                                        state,
                                                        updateState,
                                                        x.id
                                                    ),
                                                });
                                            }}
                                            variant="light"
                                        >
                                            <i
                                                style={{
                                                    fontSize: '12px',
                                                    cursor: 'pointer',
                                                }}
                                                className="fas fa-archive"
                                                aria-hidden="true"
                                            />{' '}
                                            Archive
                                        </Button>
                                    ) : null}
                                    {!isArchived && x.status === 'sent' ? (
                                        <Button
                                            sx={{ mt: 10 }}
                                            onClick={async () => {
                                                try {
                                                    updateState({ ...state, loading: true });
                                                    await axios({
                                                        url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.clientInView?.raw_id}/docsign/${x.id}/resend`,
                                                        method: 'PUT',
                                                    });
                                                    updateState({ ...state, loading: false });
                                                } catch (e) {
                                                    toast.error('Failed to resend email');
                                                }
                                            }}
                                            variant="light"
                                        >
                                            <i
                                                style={{
                                                    fontSize: '12px',
                                                    cursor: 'pointer',
                                                }}
                                                className="fas fa-plane"
                                                aria-hidden="true"
                                            />{' '}
                                            Resend
                                        </Button>
                                    ) : null}
                                    <Button
                                        sx={{ mt: 10 }}
                                        onClick={async () => {
                                            updateLocalState({
                                                ...localState,
                                                showDeleteDocConfirmation: true,
                                                confirmCallback: async () => {
                                                    await deleteDocument(
                                                        state,
                                                        updateState,
                                                        localState,
                                                        updateLocalState,
                                                        x.id
                                                    );
                                                },
                                            });
                                        }}
                                        variant="light"
                                    >
                                        <i
                                            style={{
                                                fontSize: '12px',
                                                cursor: 'pointer',
                                                color: 'red',
                                            }}
                                            className="fas fa-trash"
                                            aria-hidden="true"
                                        />{' '}
                                        Delete
                                    </Button>
                                </Flex>
                            </td>
                        </tr>
                    );
                })}
            </table>
        </Flex>
    );
};

const ReferenceDoc = ({ localState, updateLocalState, doc, idx }) => {
    const [collected, drag, dragPreview] = useDrag(
        () => ({
            type: 'reference_doc',
            item: { doc, idx },
            end: (item, monitor) => {
                console.log(localState.idxMovingTo, localState.idxOfItemMoving);
                if (localState.idxMovingTo === localState.idxOfItemMoving) {
                    return updateLocalState({
                        ...localState,
                        hoveringOver: null,
                    });
                }

                const newDocs = deepClone([...(localState.reference_docs || [])]);
                const itemToMove = deepClone(localState.reference_docs[localState.idxOfItemMoving]);
                const existingAtLocation = deepClone(newDocs[localState.idxMovingTo]);
                newDocs[localState.idxMovingTo] = itemToMove;
                newDocs[localState.idxOfItemMoving] = existingAtLocation;

                updateLocalState({
                    ...localState,
                    reference_docs: newDocs,
                    idxMovingTo: -1,
                    idxOfItemMoving: -1,
                    hoveringOver: null,
                });
            },
        }),
        [localState.reference_docs, localState.idxMovingTo, localState.idxOfItemMoving, localState.pdfs?.[0]?.document]
    );

    const [, drop] = useDrop(
        () => ({
            accept: 'reference_doc',
            hover(item) {
                updateLocalState({
                    ...localState,
                    idxOfItemMoving: idx,
                    idxMovingTo: item.idx,
                    hoveringOver: doc,
                });
            },
        }),
        [localState.reference_docs, localState.idxMovingTo, localState.idxOfItemMoving, localState.pdfs?.[0]?.document]
    );

    return collected.isDragging ? (
        <div key={doc.uuid} ref={dragPreview} />
    ) : (
        <div key={doc.uuid} ref={(node) => drag(drop(node))} {...collected}>
            <Flex sx={{ ml: 10, mt: 10, flexDirection: 'column' }}>
                <Flex sx={{ alignItems: 'center' }}>
                    <Paragraph sx={{ width: 400 }}>{doc.fileName}</Paragraph>
                    <Flex>
                        <Button
                            onClick={async () => {
                                const {
                                    data: { url },
                                } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/documents/${doc.uuid}`, {});

                                const a = window.document.createElement('a');

                                a.href = url;
                                a.target = '_blank';
                                a.download = doc.fileName;

                                return a.click();
                            }}
                            sx={{ height: 40 }}
                        >
                            View
                        </Button>
                        <Button
                            onClick={() =>
                                updateLocalState({
                                    ...localState,
                                    reference_docs: localState.reference_docs.filter((item) => item.uuid !== doc.uuid),
                                })
                            }
                            sx={{ height: 40, ml: 10 }}
                        >
                            Delete
                        </Button>
                    </Flex>
                </Flex>
                <Divider />
            </Flex>
        </div>
    );
};

const DocumentPager = ({ localState, updateLocalState }) => (
    <Flex sx={{ flexDirection: 'column', justifyContent: 'center' }}>
        <Flex sx={{ mt: 20, alignSelf: 'center' }}>
            <Button
                disabled={localState.page === 1}
                onClick={async () => {
                    updateLocalState({ ...localState, page: 1 });
                }}
                sx={{ width: 60, opacity: localState.page === 1 ? 0.5 : 1 }}
            >
                <i
                    style={{
                        fontSize: '15px',
                        cursor: 'pointer',
                        color: 'white',
                    }}
                    className="fas fa-fast-backward"
                    aria-hidden="true"
                />
            </Button>
            <Button
                disabled={localState.page === 1}
                onClick={async () => {
                    const page = Math.max(localState.page - 1, 1);
                    updateLocalState({ ...localState, page });
                }}
                sx={{ width: 60, ml: 20, opacity: localState.page === 1 ? 0.5 : 1 }}
            >
                <i
                    style={{
                        fontSize: '15px',
                        cursor: 'pointer',
                        color: 'white',
                    }}
                    className="fas fa-step-backward"
                    aria-hidden="true"
                />
            </Button>
            <Button
                disabled={localState.page >= localState.pdfs[localState.pdfIndex].pdfDocument?.numPages}
                onClick={async () => {
                    const page = Math.min(
                        localState.page + 1,
                        localState.pdfs[localState.pdfIndex].pdfDocument?.numPages
                    );
                    updateLocalState({ ...localState, page });
                }}
                sx={{
                    width: 60,
                    ml: 20,
                    opacity: localState.page >= localState.pdfs[localState.pdfIndex].pdfDocument?.numPages ? 0.5 : 1,
                }}
            >
                <i
                    style={{
                        fontSize: '15px',
                        cursor: 'pointer',
                        color: 'white',
                    }}
                    className="fas fa-step-forward"
                    aria-hidden="true"
                />
            </Button>
            <Button
                disabled={localState.page >= localState.pdfs[localState.pdfIndex].pdfDocument?.numPages}
                onClick={async () => {
                    updateLocalState({
                        ...localState,
                        page: localState.pdfs[localState.pdfIndex].pdfDocument?.numPages,
                    });
                }}
                sx={{
                    width: 60,
                    ml: 20,
                    opacity: localState.page >= localState.pdfs[localState.pdfIndex].pdfDocument?.numPages ? 0.5 : 1,
                }}
            >
                <i
                    style={{
                        fontSize: '15px',
                        cursor: 'pointer',
                        color: 'white',
                    }}
                    className="fas fa-fast-forward"
                    aria-hidden="true"
                />
            </Button>
        </Flex>
        {localState.page && localState.pdfs[localState.pdfIndex].pdfDocument?.numPages && (
            <Flex sx={{ mt: 20, alignSelf: 'center' }}>
                <Paragraph sx={{ textAlign: 'center', mr: 10 }}>
                    Page {localState.page} of {localState.pdfs[localState.pdfIndex].pdfDocument?.numPages}
                </Paragraph>
            </Flex>
        )}
    </Flex>
);

export default DocSign;
