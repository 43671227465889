import { Button, Flex, Paragraph } from 'theme-ui';

const Modal = ({ message, onClose }) => (
    <Flex
        sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10000000000,
        }}
    >
        <Flex
            sx={{
                width: '300px',
                background: '#fff',
                flexDirection: 'column',
                padding: '60px',
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
            }}
        >
            <Paragraph sx={{ fontSize: 16, textAlign: 'center', mb: 20 }}>{message}</Paragraph>
            <Button
                sx={{ alignSelf: 'center', width: 100, background: '#007bff', color: '#fff', padding: '10px 20px' }}
                onClick={onClose}
            >
                OK
            </Button>
        </Flex>
    </Flex>
);

export default Modal;
